import { BrowserRouter, Routes, Route } from 'react-router-dom';
import BaseLayout from './layouts/BaseLayout/BaseLayout';
import AuthLayout from './layouts/AuthLayout/AuthLayout';
import Home from './Components/Home/Home'
import Login from './Components/Authentication/Login';
import Products from './Components/Products/Products';
import { Toaster } from 'react-hot-toast';
import ProductDetails from './Components/Products/ProductDetails';
import AddUpdateProduct from './Components/Products/AddUpdateProduct';
import { useDispatch, useSelector } from 'react-redux';
import { setLoaderView } from './redux/actions/settingActions';
import { adjustColor } from './helper/Color/colorFormatter';
import ShopDetails from './Components/Settings/ShopDetails/ShopDetails';
import UserManagement from './Components/Settings/UserManagement/UserManagement';
import ShopSignUp from './Components/Authentication/ShopSignUp';
import ShopSelection from './Components/Authentication/ShopSelection';
import QrBuilder from './Components/Settings/QrBuilder/QrBuilder';
import UploadBulkProductFile from './Components/Products/UploadBulkProductFile';
import EditBulkProducts from './Components/Products/EditBulkProducts';
import Orders from './Components/Orders/Orders';
import Banner from './Components/Appearance/Banner/Banner';
import Tools from './Components/Tools/Tools';
import Categories from './Components/Categories/Categories';
import Customers from './Components/Customers/Customers';
import OrderDetails from './Components/Orders/OrderDetails';
import { CheckPermission } from './helper/Permission/permissionChecker';
import CenterErrorMessage from './Components/Common/CenterErrorMessage';
import { useEffect } from 'react';
import ShopLogoColor from './Components/Appearance/ShopLogoColor/ShopLogoColor';

function App() {

    const shop = useSelector((state) => state?.shop);

    const dispatch = useDispatch();
    dispatch(setLoaderView(false));

    document.documentElement.style.setProperty('--custom-primary', process.env.REACT_APP_PRIMARY_COLOR);
    document.documentElement.style.setProperty('--custom-primary-dark', adjustColor(process.env.REACT_APP_PRIMARY_COLOR, -20));
    document.documentElement.style.setProperty('--custom-primary-border', adjustColor(process.env.REACT_APP_PRIMARY_COLOR, -40));
    document.documentElement.style.setProperty('--custom-primary-focus', process.env.REACT_APP_PRIMARY_COLOR + '26');

    document.documentElement.style.setProperty('--custom-secondary', process.env.REACT_APP_SECONDARY_COLOR);
    document.documentElement.style.setProperty('--custom-secondary-dark', adjustColor(process.env.REACT_APP_SECONDARY_COLOR, -20));
    document.documentElement.style.setProperty('--custom-secondary-border', adjustColor(process.env.REACT_APP_SECONDARY_COLOR, -40));
    document.documentElement.style.setProperty('--custom-secondary-focus', process.env.REACT_APP_SECONDARY_COLOR + '26');

    useEffect(() => {
        document.title = shop?.name ? (shop?.name + ' - Shoppers Hunt') : 'Shoppers Hunt';
        document.getElementById('fav_icon').setAttribute('href', shop?.fav_icon);
    }, [shop]);

    return (
        <BrowserRouter>
            <Toaster />
            <Routes>
                <Route element={<BaseLayout />} >
                    <Route path="/" element={<Home />} />
                    {CheckPermission("products") && <Route path="/products" element={<Products />} />}
                    {CheckPermission("settings") && <Route path="/shop-details" element={<ShopDetails />} />}
                    {CheckPermission("settings") && <Route path="/user-management" element={<UserManagement />} />}
                    {CheckPermission("settings") && <Route path="/qr-builder" element={<QrBuilder />} />}
                    {CheckPermission("products") && <Route path="/product/:productId" element={<ProductDetails />} />}
                    {CheckPermission("products") && <Route path="/upload-bulk-product-file" element={<UploadBulkProductFile />} />}
                    {CheckPermission("products") && <Route path="/edit-bulk-products" element={<EditBulkProducts />} />}
                    {CheckPermission("products") && <Route path="/add-product" element={<AddUpdateProduct />} />}
                    {CheckPermission("orders") && <Route path="/orders" element={<Orders />} />}
                    {CheckPermission("orders") && <Route path="/order/:orderId" element={<OrderDetails />} />}
                    {CheckPermission("customers") && <Route path="/customers" element={<Customers />} />}
                    {CheckPermission("categories") && <Route path="/categories" element={<Categories />} />}
                    {CheckPermission("appearance") && <Route path="/banner" element={<Banner />} />}
                    {CheckPermission("appearance") && <Route path="/shop-logo-color" element={<ShopLogoColor />} />}
                    {CheckPermission("tools") && <Route path="/tools" element={<Tools />} />}
                    {CheckPermission("products") && <Route path="/update-product/:productType/:productId" element={<AddUpdateProduct />} />}
                    <Route path="*" element={<CenterErrorMessage subTitle="OOPS! - PAGE NOT FOUND" description="We are sorry, but the page requested was not found!" buttonLabel="Back To Home" path="/" />} />
                </Route>
                <Route path="/auth" element={<AuthLayout />} >
                    <Route path="" element={<Login />} />
                    <Route path="login" element={<Login />} />
                    <Route path="shop-signup" element={<ShopSignUp />} />
                    <Route path="select-shop" element={<ShopSelection />} />
                    <Route path="*" element={<CenterErrorMessage subTitle="OOPS! - PAGE NOT FOUND" description="" buttonLabel="Back To Home" path="/" />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;