export const dateFormat = (data) => {
    if (data) {
        return new Date(data).toDateString();
    }
}

export const timeFormat = (data) => {
    if (data) {
        return new Date(data).toLocaleTimeString();
    }
}