import Input from "./Input";
import { useEffect, useState } from "react";
import MapPicker from 'react-google-map-picker'
import Geocode from "react-geocode";
import SearchDropDown from "./SearchDropDown";
import { fetchPinCodes, fetchCities, fetchCountries, fetchStates } from "../../api/settingsApi";
import { formatValueLabel } from "../../helper/SelectDropDown/valueLabelFormatter";
import { useSelector } from "react-redux";
import Label from "./Label";
//const DefaultLocation = { lat: 25.4283568, lng: 81.8206944 };
const DefaultZoom = 18;

const AddressPicker = ({ latitude, longitude }) => {

    const shop = useSelector((state) => state?.shop);

    useEffect(() => {
        setDefaultLocation({ lat: latitude, lng: longitude })
    }, [latitude, longitude]);

    //map states
    const [defaultLocation, setDefaultLocation] = useState({ lat: Number(latitude), lng: Number(longitude) });
    const [zoom] = useState(DefaultZoom);

    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);

    //address states
    const [shopNo, setShopNo] = useState(shop?.building_number || "");
    const [locality, setLocality] = useState(shop?.locality || "");

    const [city, setCity] = useState(null);
    const [state, setState] = useState(null);
    const [country, setCountry] = useState(null);
    const [pinCode, setPinCode] = useState(shop?.pincode_value || "");

    //const [completeAddress, setCompleteAddress] = useState(null);
    const [updateLocation, setUpdateLocation] = useState(false);

    const [cityId, setCityId] = useState(shop?.city_id);
    const [stateId, setStateId] = useState(shop?.state_id);
    const [countryId, setCountryId] = useState(shop?.country_id);
    const [pinCodeId, setPinCodeId] = useState(shop?.pincode);

    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    const [countries, setCountries] = useState([]);
    const [pinCodes, setPinCodes] = useState([]);

    const handleChangeLocation = (lat, lng) => {

        setUpdateLocation(false);

        Geocode.fromLatLng(lat, lng).then(async (response) => {
            //const address = response.results[0].formatted_address; //complete formatted address

            setDefaultLocation({ lat, lng });

            let premise, neighborhood, sublocality, city, state, country, pincode;

            for (let i = 0; i < response.results[0].address_components.length; i++) {
                for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                    switch (response.results[0].address_components[i].types[j]) {
                        case "locality":
                            city = response.results[0].address_components[i].long_name;
                            break;
                        case "administrative_area_level_1":
                            state = response.results[0].address_components[i].long_name;
                            break;
                        case "country":
                            country = response.results[0].address_components[i].long_name;
                            break;
                        case "postal_code":
                            pincode = response.results[0].address_components[i].long_name;
                            break;
                        case "sublocality":
                            sublocality = response.results[0].address_components[i].long_name;
                            break;
                        case "neighborhood":
                            neighborhood = response.results[0].address_components[i].long_name;
                            break;
                        case "premise":
                            premise = response.results[0].address_components[i].long_name;
                            break;
                        default:
                    }
                }
            }

            let localityArray = [];
            if (neighborhood) {
                localityArray.push(neighborhood.trim());
            }
            if (sublocality) {
                localityArray.push(sublocality.trim());
            }

            let locality = localityArray.join(', ')

            setShopNo(premise);
            setLocality(locality);
            // setCity(city);
            // setState(state);
            // setCountry(country);
            setPinCode(pincode);

            let res = await fetchPinCodes(pincode);
            setPinCodes(res.data);

            let filteredPinCode = res?.data?.find((pinCode) => {
                return pinCode?.name === pincode
            });

            setCountryId(filteredPinCode?.country_id_id);
            setStateId(filteredPinCode?.state_id_id);
            setCityId(filteredPinCode?.city_id_id);
            setUpdateLocation(true);
            getLatitudeLongitudeFromAddress();

            // generateCompleteAddress();

        }, (error) => {
            console.error(error);
        });
    }

    useEffect(() => {
        if (shopNo || locality || city || state || country || pinCode) {
            generateCompleteAddress();
        }
    }, [shopNo, locality, city, state, country, pinCode]);

    const generateCompleteAddress = () => {
        let addressArray = [];

        if (shopNo) {
            addressArray.push(shopNo);
        }
        if (locality) {
            addressArray.push(locality);
        }
        if (city) {
            addressArray.push(city);
        }
        if (state) {
            addressArray.push(state);
        }
        if (country) {
            addressArray.push(country);
        }
        if (pinCode) {
            addressArray.push(pinCode);
        }

        let finalAddress = addressArray.join(', ');
        //setCompleteAddress(finalAddress);
        getLatitudeLongitudeFromAddress(finalAddress)
    }

    const getLatitudeLongitudeFromAddress = (address) => {
        if (updateLocation) {
            Geocode.fromAddress(address).then((response) => {
                const { lat, lng } = response.results[0].geometry.location;
                setDefaultLocation({ lat, lng })
            }, (error) => {
                console.error(error);
            });
        }
    }

    useEffect(() => {
        fetchCountries().then(response => {
            if (response.status === 200) {
                setCountries(formatValueLabel('id', 'name', response.data));
            }
        });
    }, [])

    useEffect(() => {
        if (countryId) {
            fetchStates(countryId).then(response => {
                if (response.status === 200) {
                    setStates(formatValueLabel('id', 'name', response.data));
                }
            });
        }
    }, [countryId]);

    useEffect(() => {
        if (countryId && stateId) {
            fetchCities(countryId, stateId).then(response => {
                if (response.status === 200) {
                    setCities(formatValueLabel('id', 'name', response.data));
                }
            });
        }
    }, [countryId, stateId]);

    return (
        <div className="row">

            {(defaultLocation.lat && defaultLocation.lng) ? <div className="col-md-12 mb-3">
                <div className="card-body p-0">
                    <MapPicker defaultLocation={defaultLocation}
                        zoom={zoom}
                        mapTypeId="roadmap"
                        style={{ height: '250px' }}
                        onChangeLocation={handleChangeLocation}
                        // onChangeZoom={handleChangeZoom}
                        apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY} />
                </div>
            </div>
                :
                <div className="col-md-12 mb-3">
                    <div className="card-body p-0">
                        <Label label='Enable location to load the map and refresh the page.' labelClassName="text-danger" />
                    </div>
                </div>}
            <div className="col-md-4 mb-3">
                <Input label="Shop No. / Building No." value={shopNo} onChange={
                    (event) => {
                        setShopNo(event.target.value);
                        setUpdateLocation(true);
                        getLatitudeLongitudeFromAddress();
                    }
                } name="building_number" required={true} type="text" className="form-control" placeholder="Enter shop no. / building no." />
            </div>
            <div className="col-md-4 mb-3">
                <Input label="Locality" value={locality} onChange={
                    (event) => {
                        setLocality(event.target.value);
                        setUpdateLocation(true);
                        getLatitudeLongitudeFromAddress();
                    }
                } name="locality" required={true} type="text" className="form-control" placeholder="Enter locality" />
            </div>
            <div className="col-md-4 mb-3">
                <Label label="PIN Code" required={true} />
                <div>
                    <input type="text" name="pincode" className="form-control" value={pinCode} onChange={(event) => {
                        let inputVal = event.target.value;
                        setPinCode(inputVal);
                        let filteredPinCode = pinCodes?.find((pinCode) => {
                            return pinCode?.name === inputVal
                        });
                        setCountryId(filteredPinCode?.country_id_id);
                        setStateId(filteredPinCode?.state_id_id);
                        setCityId(filteredPinCode?.city_id_id);
                        setUpdateLocation(true);
                        getLatitudeLongitudeFromAddress();
                    }} onKeyUp={async (event) => {
                        let inputVal = event.target.value;
                        document.getElementById('search-dropdown').style.visibility = 'visible';
                        if (inputVal.length > 1) {
                            let res = await fetchPinCodes(inputVal);
                            setPinCodes(res.data);
                        } else {
                            setPinCodes([]);
                        }
                    }} placeholder="Search..." id="top-search" />
                    <div className="dropdown-menu dropdown-menu-animated dropdown-lg" id="search-dropdown">
                        {pinCodes.map((pinCode, index) => {
                            return <span key={index} className="dropdown-item notify-item cursor-pointer" onClick={() => {
                                document.getElementById('search-dropdown').style.visibility = 'hidden';
                                setPinCodeId(pinCode.id);
                                setPinCode(pinCode.name);
                                setCountryId(pinCode.country_id_id);
                                setStateId(pinCode.state_id_id);
                                setCityId(pinCode.city_id_id);
                                setUpdateLocation(true);
                                getLatitudeLongitudeFromAddress();
                            }}>
                                <span>{pinCode.name}</span>
                            </span>
                        })}
                        {/* {pinCodes.length === 0 && <span className="mb-3">No PIN Code</span>} */}
                    </div>
                </div>
            </div>
            <div className="col-md-4 mb-3">
                <SearchDropDown label="Country" disabled={false} options={countries} value={countryId || shop?.country_id} onChange={(event) => {
                    setCountry(event.label);
                    setCountryId(event.value);
                    setUpdateLocation(true);
                    getLatitudeLongitudeFromAddress();
                }} name="country_id" required={true} className="form-control" />
            </div>
            <div className="col-md-4 mb-3">
                <SearchDropDown label="State" disabled={false} options={states} value={stateId || shop?.state_id} onChange={(event) => {
                    setState(event.label);
                    setStateId(event.value);
                    setUpdateLocation(true);
                    getLatitudeLongitudeFromAddress();
                }} name="state_id" required={true} className="form-control" />
            </div>
            <div className="col-md-4 mb-3">
                <SearchDropDown label="City" disabled={false} options={cities} value={cityId || shop?.city_id} onChange={(event) => {
                    setCity(event.label);
                    setCityId(event.value);
                    setUpdateLocation(true);
                    getLatitudeLongitudeFromAddress();
                }} name="city_id" required={true} className="form-control" />
            </div>
            {/* <div className="row">
                    <div className="col-md-12">
                        <Input label="Complete Address" value={completeAddress} disabled={true} required={false} type="text" className="form-control" placeholder="Enter your shop address here" />
                    </div>
                </div> */}
            <Input type="hidden" name="latitude" value={defaultLocation.lat} />
            <Input type="hidden" name="longitude" value={defaultLocation.lng} />

            <Input type="hidden" name="city_id" value={cityId} />
            <Input type="hidden" name="state_id" value={stateId} />
            <Input type="hidden" name="country_id" value={countryId} />
            <Input type="hidden" name="pincode_id" value={pinCodeId} />

            <input type="hidden" name="update_address" value={true} />

        </div >
    )
}

export default AddressPicker;