import { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-hot-toast';
import { fetchUserShopRoleDetail, updateUser } from "../../api/settingsApi";
import Input from "../../Components/Common/Input";
import RequiredInput from "../../Components/Common/RequiredInput";
import Select from "../../Components/Common/Select";
import { GenderConfig } from "../../Configuration/GenderConfig";
import { setLoaderView, setMenu, setRoleDetail, setShowMyProfilePopup } from "../../redux/actions/settingActions";
import { setUser } from "../../redux/actions/userActions";
import { fetchDashboardData } from '../../api/dashboardApi';
import { setDashboardDetails } from "../../redux/actions/dashboardActions";

const UserProfile = () => {

    const dispatch = useDispatch();

    const user = useSelector((state) => state?.user);
    const role = useSelector((state) => state?.role);
    const roles = useSelector((state) => state?.roles);
    const shop = useSelector((state) => state?.shop);

    const profilePopup = useSelector((state) => state?.profilePopup);

    const [phone, setPhone] = useState(user?.phone);
    const [countryCode, setCountryCode] = useState(user?.country_code);
    const [phoneCountryCode, setPhoneCountryCode] = useState(`${user?.country_code}${user?.phone}`);
    const [gender] = useState(GenderConfig);
    const [selectedRole, setSelectedRole] = useState(role?.id);

    useEffect(() => {
        if (user) {
            setPhone(user?.phone);
            setCountryCode(user?.country_code);
            setPhoneCountryCode(`${user?.country_code}${user?.phone}`);
        }
        if (roles.length > 0 && role) {
            setSelectedRole(role?.id);
        }
    }, [user, role, roles]);

    const updateProfile = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        dispatch(setLoaderView(true));
        updateUser(user.id, shop?.uuid, formData).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                toast.success(response.message);
                dispatch(setShowMyProfilePopup(false));
                fetchUserShopRoleDetail(shop?.uuid).then((response) => {
                    if (response.status === 200) {
                        dispatch(setMenu(response.data.left_menu_data.menu_list));
                        dispatch(setRoleDetail(response.data.role_data));
                        dispatch(setUser(response.data.user_data));
                    }
                }).catch((error) => {
                    if (error.response.data.status === 400) {
                        toast.error(error.response.data.message);
                    }
                });
                fetchDashboardData(shop?.uuid).then(response => {
                    dispatch(setLoaderView(false));
                    if (response.status === 200) {
                        dispatch(setDashboardDetails(response.data));
                    }
                }).catch((error) => {
                    dispatch(setLoaderView(false));
                    if (error.response.data.status === 400) {
                        toast.error(error.response.data.message);
                    }
                });
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    return (
        <Modal size="lg" show={profilePopup} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered onHide={() => { dispatch(setShowMyProfilePopup(false)) }}>
            <Modal.Header closeButton>
                <Modal.Title className="m-0" id="contained-modal-title-vcenter">My Profile</Modal.Title>
            </Modal.Header>
            <form onSubmit={updateProfile}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <img src="/assets/images/users/user.png" className="rounded-circle avatar-lg img-thumbnail" alt="" />
                            <h4 className="mb-0 mt-2">{user?.full_name}</h4>
                            <p className="text-muted font-14">{role?.name}</p>
                        </div>
                        <div className="col-md-12 mb-3">
                            <Input label="Name" defaultValue={user?.full_name} name="full_name" required={true} type="text" className="form-control" placeholder="Enter user full name" />
                        </div>
                        <div className="col-md-5 mb-3">
                            <label>Phone <RequiredInput /></label>
                            <PhoneInput disabled={user ? true : false} onChange={(phoneNumber, countryCodeObj) => {
                                setPhone(phoneNumber?.substring(countryCodeObj?.dialCode?.length));
                                setCountryCode(`+${countryCodeObj?.dialCode}`);
                                setPhoneCountryCode(`+${phoneNumber}`);
                            }} country="in" onlyCountries={['in']} required={true} value={phoneCountryCode} className="phone-input" />
                            <input type="hidden" name="phone" value={phone} />
                            <input type="hidden" name="country_code" value={countryCode} />
                        </div>
                        <div className="col-md-7 mb-3">
                            <Input label="Email" defaultValue={user?.email} name="email" required={false} type="email" className="form-control" placeholder="Enter email id" />
                        </div>
                        <div className="col-md-4 mb-3">
                            <Input label="Date of Birth" defaultValue={user?.dob} name="dob" required={false} type="date" className="form-control" placeholder="Enter date of birth" />
                        </div>
                        <div className="col-md-4 mb-3">
                            <Select options={gender} selectLabel="label" selectId="value" label="Gender" defaultValue={user?.gender} name="gender" required={false} className="form-select" />
                        </div>
                        <div className="col-md-4">
                            <Select label="Role" required={true} onChange={(event) => setSelectedRole(event.target.value)} value={selectedRole} name="role_id" options={roles || []} className="form-select" />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={() => { dispatch(setShowMyProfilePopup(false)) }}>Close</button>
                    <button type="submit" className="btn btn-primary">Update</button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default UserProfile;