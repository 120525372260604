import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-hot-toast';
import { fetchCustomers } from "../../api/customerApi";
import { setLoaderView } from "../../redux/actions/settingActions";
import CurrencyView from "../Common/CurrencyView";
import GridView from "../Common/GridView";
import Header from "../Common/Header";
import { setPhoneNo } from "../../helper/GridFormatters/phoneNoFormatter";
import { useState } from "react";

const Customers = () => {

    const shop = useSelector((state) => state?.shop);

    const [customers, setCustomers] = useState([]);
    const [totalSize, setTotalSize] = useState(0);

    const dispatch = useDispatch();

    const loadCustomers = () => {
        dispatch(setLoaderView(true));
        fetchCustomers(shop?.uuid).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                setCustomers(response.data);
                setTotalSize(response.total_count);
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    useEffect(() => {
        loadCustomers(25, 1);
    }, []);

    const orderId = (cell, row) => {
        return <span className="cursor-pointer text-primary">{cell}</span>
    }

    const totalAmount = (cell, row) => {
        return <b className="float-end"><CurrencyView value={cell} /></b>
    }

    const customersColumns = [
        {
            dataField: 'full_name',
            text: 'Name',
            formatter: orderId
        }, {
            dataField: 'email',
            text: 'Email',
        }, {
            dataField: 'total_orders',
            text: 'Total Orders',
            headerAlign: 'center',
            classes: 'text-center',
            formatter: (orderCount) => orderCount ? orderCount : 0
        }, {
            dataField: 'phone',
            text: 'Phone No',
            formatter: setPhoneNo
        },
        // {
        //     dataField: 'created_on',
        //     text: 'Joining Date & Time',
        //     formatter: (cell) => `${dateFormat(cell)} - ${timeFormat(cell)}`
        // }, {
        //     dataField: 'last_login',
        //     text: 'Last Login Date & Time',
        //     formatter: (cell) => `${dateFormat(cell)} - ${timeFormat(cell)}`
        // }, 
        {
            dataField: 'total_revenue',
            text: 'Total Revenue',
            headerAlign: 'right',
            formatter: totalAmount
        }
    ];

    return (
        <>
            <Header title="Customers" />

            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 ">
                            <GridView
                                keyField="id"
                                data={customers}
                                fetchData={loadCustomers}
                                sizePerPage={25}
                                totalSize={totalSize}
                                hideSizePerPage={false}
                                columns={customersColumns} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Customers;