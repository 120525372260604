import { Link } from "react-router-dom";

const ToolCard = ({ data }) => {

    return <Link to={data?.path} className="col-sm-6 col-xl-4 mb-3">
        <div className="border cursor-pointer rounded">
            <div className="card-body">
                <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm">
                            <span className="avatar-title bg-primary-lighten text-primary rounded">
                                <i className={`uil ${data?.icon} font-24`}></i>
                            </span>
                        </div>
                    </div>
                    <div className="flex-grow-1">
                        <h5 className="mt-0 mb-1">{data?.title}</h5>
                        <p className="mb-0">{data?.description}</p>
                    </div>
                </div>
            </div>
        </div>
    </Link>
}

export default ToolCard;