import Header from "../../Common/Header";
import { useDispatch, useSelector } from "react-redux";
import QRCode from "react-qr-code";
import Input from "../../Common/Input";
import { useState } from "react";
import RadioButton from "../../Common/RadioButton";
import { useEffect } from "react";
import * as htmlToImage from 'html-to-image';
import { fetchQrBuilder, saveQrBuilder } from "../../../api/settingsApi";
import { setLoaderView } from "../../../redux/actions/settingActions";
import { toast } from 'react-hot-toast';

const QrBuilder = () => {

    const shop = useSelector((state) => state?.shop);
    const dispatch = useDispatch();

    const [qrFrontColor, setQrFrontColor] = useState("#000000");
    const [qrBackColor, setQrBackColor] = useState("#FFFFFF");
    const [cardBackgroundColor, setCardBackgroundColor] = useState("#FFFFFF");
    const [showLogo, setShowLogo] = useState(true);
    const [showBrandLogo, setShowBrandLogo] = useState(true);
    const [message, setMessage] = useState("Scan QR to open our shop!");
    const [title, setTitle] = useState("Add your title here!");
    const [messageColor, setMessageColor] = useState("#000000");
    const [qrId, setQrId] = useState(null);

    useEffect(() => {
        document.getElementById('qr-card').style.backgroundColor = cardBackgroundColor;
    }, [cardBackgroundColor]);

    useEffect(() => {
        document.getElementById('qr-back').style.backgroundColor = qrBackColor;
    }, [qrBackColor]);

    useEffect(() => {
        document.getElementById('qr-card').style.color = messageColor;
    }, [messageColor]);

    const resetCard = () => {
        setQrFrontColor("#000000");
        setQrBackColor("#FFFFFF");
        setCardBackgroundColor("#FFFFFF");
        setShowLogo(true);
        setShowBrandLogo(true);
        setMessage("Scan QR to open our shop!");
        setMessageColor("#000000");
        setTitle("Add your title here!");
    }

    const saveQR = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('shop_logo', showLogo);
        formData.append('shoppers_hunt_logo', showBrandLogo);
        dispatch(setLoaderView(true));
        saveQrBuilder(shop?.uuid, formData).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                toast.success(response.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    const downloadQR = async () => {
        let dataUrl = await htmlToImage.toJpeg(document.getElementById('qr-card'));
        let link = document.createElement('a');
        link.download = `${shop?.slug}-qr`;
        link.href = dataUrl;
        link.click();
    }

    useEffect(() => {
        dispatch(setLoaderView(true));
        fetchQrBuilder(shop?.uuid).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                setQrFrontColor(response?.data?.qr_front_color || "#000000");
                setQrBackColor(response?.data?.qr_back_color || "#FFFFFF");
                setCardBackgroundColor(response?.data?.background_color || "#FFFFFF");
                setShowLogo(response?.data?.shop_logo || true);
                setShowBrandLogo(response?.data?.shoppers_hunt_logo || true);
                setMessage(response?.data?.message || "Scan QR to open our shop!");
                setTitle(response?.data?.title || "Add your title here!");
                setMessageColor(response?.data?.font_color || "#000000");
                setQrId(response.data?.qr_code || null);
            }
        }).catch(() => {
            dispatch(setLoaderView(false));
        });
    }, [shop]);

    return (
        <>
            <Header title="QR Builder" />

            <div className="row mb-3">
                <div className="col-lg-4">
                    <div className="card mb-0">
                        <form onSubmit={saveQR} className="card-body">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <RadioButton label="Show Shop Logo?" label1="Yes" label2="No" id1="isShopLogoYes" id2="isShopLogoNo" isYes={showLogo} setIsYes={setShowLogo} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <RadioButton label="Show Shoppers Hunt Logo?" label1="Yes" label2="No" id1="isBrandLogoYes" id2="isBrandLogoNo" isYes={showBrandLogo} setIsYes={setShowBrandLogo} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 mb-3">
                                    <Input label="Background Color" name="background_color" value={cardBackgroundColor} onChange={(event => setCardBackgroundColor(event.target.value))} type="color" className="form-control" />
                                </div>
                                <div className="col-6 mb-3">
                                    <Input label="Font Color" name="font_color" value={messageColor} onChange={(event => setMessageColor(event.target.value))} type="color" className="form-control" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <Input label="Title" name="title" value={title} onChange={(event) => setTitle(event.target.value)} type="text" className="form-control" placeholder="Enter title here" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 mb-3">
                                    <Input label="QR Front Color" name="qr_front_color" value={qrFrontColor} onChange={(event => setQrFrontColor(event.target.value))} type="color" className="form-control" />
                                </div>
                                <div className="col-6 mb-3">
                                    <Input label="QR Back Color" name="qr_back_color" value={qrBackColor} onChange={(event => setQrBackColor(event.target.value))} type="color" className="form-control" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <Input label="Message" name="message" value={message} onChange={(event) => setMessage(event.target.value)} type="text" className="form-control" placeholder="Enter role name" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="d-flex justify-content-between">
                                    <button type="button" onClick={resetCard} className="btn btn-outline-secondary">Reset</button>
                                    <button type="submit" className="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="col-lg-8">
                    <div className="mt-3 mt-lg-0 d-flex justify-content-center">
                        <div id="qr-card" className="card border rounded p-4 qr-builder-container">
                            <div className="row justify-content-center">
                                <img className={`shop-logo-qr-builder m-2 ${showLogo ? 'visible' : 'invisible'}`} src={shop?.logo ? shop?.logo : "/assets/images/logo.svg"} alt="" />
                            </div>
                            <b className="text-center qr-text-height">{title}</b>
                            <div className="row justify-content-center mt-2">
                                <div id="qr-back" className="col-auto rounded border">
                                    <QRCode
                                        fgColor={qrFrontColor}
                                        bgColor={qrBackColor}
                                        className="m1-2 my-2 qr-code"
                                        value={`${process.env.REACT_APP_APP_URL}/${shop?.slug}?qr_code=${qrId}`} />
                                </div>
                            </div>
                            <small className="text-center mb-2">{`${process.env.REACT_APP_APP_DOMAIN}/${shop?.slug}`}</small>
                            <p className="text-center qr-text-height">{message}</p>
                            <div className={`row mt-2 justify-content-end ${showBrandLogo ? 'visible' : 'invisible'}`}>
                                <small className="text-end">Powered By</small>
                                <img className="logo-qr-builder" src="/assets/images/logo.svg" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-lg-8 d-flex justify-content-center ">
                            <button type="button" onClick={downloadQR} className="btn btn-primary qr-download-button">Download</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QrBuilder;