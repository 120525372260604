import { ActionTypes } from "../constants/actionTypes"

export const setOrder = (order) => {
    return {
        type: ActionTypes.SET_ORDER,
        payload: order
    }
}

export const setOrders = (orders) => {
    return {
        type: ActionTypes.SET_ORDERS,
        payload: orders
    }
}

export const setPendingOrdersList = (pendingOrdersList) => {
    return {
        type: ActionTypes.PENDING_ORDER_LIST,
        payload: pendingOrdersList
    }
}