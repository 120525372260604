import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-hot-toast';
import { fetchUserShopRoleDetail } from "../../api/settingsApi";
import { setMenu, setRoleDetail, setShopDetail } from "../../redux/actions/settingActions";
import { setUser } from "../../redux/actions/userActions";

const ShopSelection = () => {

    const userData = useSelector((state) => state?.user);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const goToDashboard = (shop) => {
        fetchUserShopRoleDetail(shop?.uuid).then((response) => {
            if (response.status === 200) {
                dispatch(setRoleDetail(response.data.role_data));
                dispatch(setShopDetail(response.data.shop_data));
                dispatch(setUser(response.data.user_data));
                dispatch(setMenu(response.data.left_menu_data.menu_list));
                navigate("/");
            }
        }).catch((error) => {
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    return (
        <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xxl-4 col-lg-5">
                        <div className="card">
                            <div className="card-header pt-3 pb-3 text-center">
                                <span><img src="/assets/images/logo.svg" alt="" height="72" /></span>
                            </div>
                            <div className="card-body p-4">
                                {userData?.shops_data?.map((shop) => {
                                    return <div onClick={() => goToDashboard(shop)} className="border border-light p-3 rounded mb-3 cursor-pointer">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0">
                                                <div className="avatar-sm rounded">
                                                    <span className="avatar-title bg-transparent text-danger rounded">
                                                        <img className="shop-logo" alt="" src={shop?.logo ? shop?.logo : "/assets/images/image_not_found.png"} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1 ms-2">
                                                <div className="h4 my-0 fw-semibold text-secondary">{shop?.name}</div>
                                            </div>
                                            <div className="avatar-xs">
                                                <span className="avatar-title bg-success rounded-circle h3 my-0">
                                                    <i className="mdi mdi-arrow-right-thin"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShopSelection;