import Label from './Label';

const SelectWithAddButton = ({ label, options, id, name, disabled, className, labelClassName, required, value, defaultValue, onChange, onClick }) => {
    return (
        <>
            <Label labelClassName={labelClassName} label={label} required={required} />
            <div className="input-group">
                <select className={className} disabled={disabled} id={id} name={name} value={value} onChange={onChange} defaultValue={defaultValue} required={required}>
                    <option value={0}>--Select--</option>
                    {options?.map((obj, index) => {
                        return <option key={index} value={obj?.id}>{obj?.name}</option>
                    })}
                </select>
                <span onClick={onClick} className="input-group-text cursor-pointer bg-primary text-white">
                    <i className="dripicons-plus"></i>
                </span>
            </div>
        </>
    )
}

export default SelectWithAddButton;