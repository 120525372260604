import Header from "../Common/Header";
import { convertCsvToJson } from "../../api/productsApi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-hot-toast';
import { setLoaderView } from "../../redux/actions/settingActions";
import { setBulkProducts, setCsvId } from "../../redux/actions/productActions";

const UploadBulkProductFile = () => {

    const shopId = useSelector((state) => state?.shop?.uuid);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const addUpdateProduct = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        dispatch(setLoaderView(true));
        convertCsvToJson(shopId, formData).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                toast.success(response.message);
                dispatch(setCsvId(response.uploaded_file_id));
                dispatch(setBulkProducts(response.data));
                navigate("/edit-bulk-products");
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    return (
        <>
            <Header back={true} title="Upload Bulk Product File"></Header>

            <div className="card">
                <div className="card-body">
                    <form onSubmit={addUpdateProduct}>
                        <div className="row">
                            <div className="col-md-12">
                                {/* <FileDragDrop name="product_master_file" multiple={false} /> */}
                                <input type="file" name="product_master_file" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <button type="submit" className="btn btn-primary">Upload</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default UploadBulkProductFile;