import { fetchTimePeriod } from "../../../api/settingsApi";
import { useDispatch, useSelector } from "react-redux";
import { setLoaderView } from "../../../redux/actions/settingActions";
import Button from "../../Common/Button";
import { toast } from 'react-hot-toast';
import { useState } from "react";
import Input from "../../Common/Input";
import Select from "../../Common/Select";
import { useEffect } from "react";
import CurrencyView from "../../Common/CurrencyView";

const DeliveryDetails = ({ submitShopDetails }) => {

    const dispatch = useDispatch();

    const shop = useSelector((state) => state?.shop);

    const [isDeliveryEnabled] = useState(shop?.is_delivery, false);
    const [isPersonalDeliveryEnabled] = useState(shop?.self_delivery, false);
    const [isPickUpEnabled] = useState(shop?.is_pickup, false);
    const [deliveryCharge, setDeliveryCharge] = useState(shop?.delivery_charges, 0);
    const [defaultDeliveryCharge, setDefaultDeliveryCharge] = useState(null);
    const [defaultDeliveryTiming, setDefaultDeliveryTiming] = useState(null);
    const [timings, setTimings] = useState([]);
    const [pickupTiming, setPickupTiming] = useState(shop?.pickup_time_id, null);
    const [deliveryTiming, setDeliveryTiming] = useState(shop?.delivery_time_id, null);

    useEffect(() => {
        dispatch(setLoaderView(true));
        fetchTimePeriod().then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                setTimings(response.data);
                setDefaultDeliveryCharge(response?.shoppershunt_delivery_charge);
                setDefaultDeliveryTiming(response?.shoppershunt_delivery_time_id);
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }, []);

    return (
        <>
            <form onSubmit={submitShopDetails}>

                <div className="d-flex">
                    <h4 className="header-title mb-3 me-1">Delivery</h4>
                    {/* <ToggleSwitch checked={isDeliveryEnabled} onChange={(event) => setIsDeliveryEnabled(event.target.checked)} /> */}
                    <input type="hidden" name="is_delivery" value={isDeliveryEnabled} />
                </div>

                {isDeliveryEnabled && <div className="row">
                    <div className="col-md-12 ">

                        {/* <div className="row">
                            <div className="col-md-12 mb-3">
                                <RadioButton label="Do you provide your own delivery service?" label1="Yes" label2="No" id1="isPersonalDeliveryYes" id2="isPersonalDeliveryNo" isYes={isPersonalDeliveryEnabled} setIsYes={setIsPersonalDeliveryEnabled} />
                                <input type="hidden" name="self_delivery" value={isPersonalDeliveryEnabled} />
                            </div>
                        </div> */}

                        {isPersonalDeliveryEnabled && <div className="row">
                            <div className="col-md-3 mb-3">
                                <Input name="delivery_charges" value={deliveryCharge} required={true} onChange={(event) => setDeliveryCharge(event.target.value)} label="Delivery Charges" className="form-control" placeholder="Enter delivery charge" />
                            </div>
                            <div className="col-md-3 mb-3">
                                <Select
                                    label="Delivery Time"
                                    name="delivery_time"
                                    value={deliveryTiming}
                                    onChange={(event) => setDeliveryTiming(event.target.value)}
                                    required={true}
                                    className="form-select"
                                    options={timings} />
                            </div>
                            {deliveryCharge > 0 && <div>
                                <small>
                                    <b>Delivery of products will be managed by Shoppers Hunt.</b><br />
                                    <b>Delivery charge of {<CurrencyView value={deliveryCharge} />} will be charged on each order by Shoppers Hunt.</b>
                                </small>
                            </div>}
                        </div>}

                        {!isPersonalDeliveryEnabled && <div className="row">
                            <div className="col-md-3 mb-3">
                                <Input readOnly={true} name="delivery_charges" required={true} value={defaultDeliveryCharge} label="Delivery Charges" className="form-control" placeholder="Enter delivery charge" />
                            </div>
                            <div className="col-md-3 mb-1">
                                <Input readOnly={true} required={true} value={(timings?.filter((time) => {
                                    return time.id === defaultDeliveryTiming
                                }))[0]?.name} label="Delivery Time" className="form-control" placeholder="Enter delivery charge" />
                            </div>
                            <input type="hidden" value={deliveryTiming} name="delivery_time" />
                            {defaultDeliveryCharge && <div>
                                <small>
                                    <b>Delivery of products will be managed by Shoppers Hunt.</b><br />
                                    <b>Delivery charge of {<CurrencyView value={deliveryCharge} />} will be charged on each order by Shoppers Hunt.</b>
                                </small>
                            </div>}
                        </div>}
                    </div>
                </div>}

                <hr />

                <div className="d-flex">
                    <h4 className="header-title mb-3 me-1">Pickup</h4>
                    {/* <ToggleSwitch checked={isPickUpEnabled} onChange={(event) => setIsPickUpEnabled(event.target.checked)} /> */}
                    <input type="hidden" name="is_pickup" value={isPickUpEnabled} />
                </div>

                <div className="row">
                    <div className="col-md-12 ">
                        {isPickUpEnabled && <div className="row">
                            <div className="col-md-3 mb-3">
                                <Select
                                    label="Pickup After"
                                    name="pickup_time"
                                    value={pickupTiming}
                                    onChange={(event) => setPickupTiming(event.target.value)}
                                    required={true}
                                    className="form-select"
                                    options={timings} />
                            </div>
                        </div>}
                        <div className="row">
                            <div className="col-md-12 text-end">
                                <Button type="submit" className="btn btn-primary" label="Save Details" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default DeliveryDetails;