import { useDispatch, useSelector } from "react-redux";
import Button from "../../Common/Button";
import { addPolicy, deletePolicy, fetchPolicyTypes, getPolicies, updatePolicy } from "../../../api/settingsApi";
import { useEffect, useState } from "react";
import { setLoaderView } from "../../../redux/actions/settingActions";
import { toast } from 'react-hot-toast';
import GridView from "../../Common/GridView";
import AddUpdatePolicyPopup from "../../Products/AddUpdatePolicyPopup";
import DeleteConfirmationPopup from "../../Common/DeleteConfirmationPopup";
//import parse from "html-react-parser";

const PolicyDetails = () => {

    const dispatch = useDispatch();

    const shop = useSelector((state) => state?.shop);

    const [policyTypes, setPolicyTypes] = useState([]);
    const [policies, setPolicies] = useState([]);
    const [policyData, setPolicyData] = useState(null);
    const [showAddUpdatePolicy, setShowAddUpdatePolicy] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [showDeletePopup, setShowDeletePopup] = useState(false);

    const loadPolicies = () => {
        dispatch(setLoaderView(true));
        getPolicies(shop?.uuid).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                setPolicies(response.data);
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    useEffect(() => {
        fetchPolicyTypes().then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                setPolicyTypes(response.data);
                loadPolicies();
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }, []);

    const usersAction = (id, row) => {
        return (<div className="dropdown card-widgets">
            <span className="dropdown-toggle arrow-none cursor-pointer card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="mdi mdi-dots-vertical"></i>
            </span>
            <div className="dropdown-menu dropdown-menu-end" >
                <span className="dropdown-item cursor-pointer" onClick={() => {
                    setPolicyData(row);
                    setShowAddUpdatePolicy(true);
                }}><i className="uil-file-edit-alt me-1"></i>Edit</span>
                <span className="dropdown-item cursor-pointer" onClick={() => {
                    setPolicyData(row);
                    setDeleteId(id);
                    setShowDeletePopup(true);
                }}><i className="uil uil-trash-alt me-1"></i>Delete</span>
            </div>
        </div>)
    }

    const columns = [{
        dataField: 'policy_type_id',
        text: 'Type',
        formatter: (cell) => {
            return policyTypes?.find((obj) => {
                return obj?.id === cell
            })?.name;
        }
    },
    //  {
    //     dataField: 'text',
    //     text: 'Description',
    //     formatter: (cell) => {
    //         return parse(cell || "") 
    //     }
    // },
     {
        dataField: 'id',
        text: '',
        formatter: usersAction
    }];

    const submitCreateBannerHandler = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        if (policyData) {
            dispatch(setLoaderView(true));
            updatePolicy(policyData?.id, shop?.uuid, formData).then(response => {
                if (response.status === 200) {
                    toast.success(response.message);
                    loadPolicies();
                    event.target.reset();
                    setShowAddUpdatePolicy(false);
                }
            }).catch(error => {
                dispatch(setLoaderView(false));
                if (error.response.data.status === 400) {
                    toast.error(error.response.data.message);
                }
            });
        } else {
            dispatch(setLoaderView(true));
            addPolicy(shop?.uuid, formData).then(response => {
                if (response.status === 200) {
                    toast.success(response.message);
                    loadPolicies();
                    event.target.reset();
                    setShowAddUpdatePolicy(false);
                }
            }).catch(error => {
                dispatch(setLoaderView(false));
                if (error.response.data.status === 400) {
                    toast.error(error.response.data.message);
                }
            });
        }
    }

    const canDelete = (yesOrNo) => {
        if (yesOrNo) {
            dispatch(setLoaderView(true));
            if (policyData) {
                deletePolicy(deleteId, shop?.uuid).then(response => {
                    dispatch(setLoaderView(false));
                    if (response.status === 200) {
                        toast.success(response.message);
                        setPolicyData(null);
                        setShowDeletePopup(false);
                        loadPolicies();
                    }
                }).catch((error) => {
                    setPolicyData(null);
                    dispatch(setLoaderView(false));
                    if (error.response.data.status === 400) {
                        toast.error(error.response.data.message);
                    }
                });
            }
        } else {
            setPolicyData(null);
            setShowDeletePopup(false);
        }
    }

    return (
        <>
            <div className="d-flex justify-content-between">
                <h4 className="header-title mb-3">Policies / T&C</h4>
                <Button label="Add Policy" className="btn btn-primary float-right mb-3" onClick={() => {
                    setShowAddUpdatePolicy(true);
                    setPolicyData(null);
                }} />
            </div>

            <div className="row">
                <div className="col-md-12 ">
                    <GridView
                        data={policies}
                        columns={columns}
                        totalSize={policies.length}
                        sizePerPage={25}
                        disablePagination={true}
                        keyField="id" />
                </div>
            </div>

            <AddUpdatePolicyPopup show={showAddUpdatePolicy} data={policyData} onHide={() => setShowAddUpdatePolicy(false)} submitForm={submitCreateBannerHandler} policyTypes={policyTypes} />
            <DeleteConfirmationPopup show={showDeletePopup} canDelete={canDelete} />
        </>
    )
}

export default PolicyDetails;