import { Modal } from "react-bootstrap";
import { SideMenuConfig } from "../../../Configuration/SideMenuConfig";
import Input from "../../Common/Input";
import ToggleSwitch from "../../Common/ToggleSwitch";
import TextArea from "../../Common/TextArea";

const AddUpdateRolePopup = ({ show, data, onHide, submitForm }) => {

    const isToggleOn = (toggle) => {
        return Object.keys(data ? data : []).find((obj) => {
            return (obj === toggle?.id && data[obj] === true)
        })
    }

    return (
        <Modal show={show} backdrop="static" size="lg" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
            <form onSubmit={submitForm}>
                <Modal.Header>
                    <Modal.Title className="m-0" id="contained-modal-title-vcenter">{data ? 'Update' : 'Add'} Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <Input label="Role Name" defaultValue={data?.name} name="name" required={true} type="text" className="form-control" placeholder="Enter role name" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <TextArea label="Role Descriprion" required={true} defaultValue={data?.description} name="description" className="form-control" rows={2} placeholder="Add role description" />
                        </div>
                    </div>
                    <div className="row">
                        {SideMenuConfig.map((category, index) => {
                            return (<div className="col-md-4 mb-2" key={index}>
                                <ToggleSwitch
                                    label={category.label}
                                    id={category.id}
                                    name={category.id}
                                    defaultChecked={isToggleOn(category) ? true : false}
                                />
                            </div>)
                        })}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={onHide} className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-primary">{data ? 'Update' : 'Add'} Role</button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default AddUpdateRolePopup;