import { useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from 'react-hot-toast';
import { uploadBulkProductImages } from "../../api/productsApi";
import Input from "../Common/Input";

const ProductCardEdit = ({ product }) => {

    const shopId = useSelector((state) => state?.shop?.uuid);

    const [price, setPrice] = useState(product.variant_price);
    const [discount, setDiscount] = useState(product.variant_discounted_percentage);
    const [discountedPrice, setDiscountedPrice] = useState(product.variant_discounted_price);
    const [files, setFiles] = useState([]);
    const fileRef = useRef(null);

    const onImageAdded = (event) => {
        const formData = new FormData();
        if ((files.length + event.target.files.length) > 5) {
            toast.error("Select maximum 5 images only!");
            return;
        }
        for (let i = 0; i < event.target.files.length; i++) {
            formData.append('product_images', event.target.files[i]);
        }
        uploadBulkProductImages(shopId, formData).then(response => {
            if (response.status === 200) {
                setFiles(files => [...files, ...response.data]);
            }
        }).catch(error => {
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    const removeFile = (index) => {
        let arr = [...files];
        arr.splice(index, 1);
        setFiles(arr);
    }

    const calculateDiscountedPrice = (value) => {
        setDiscount(value)
        setDiscountedPrice(price - ((value / 100) * price))
    }

    const onPriceChange = (value) => {
        setPrice(value)
        setDiscountedPrice(value - ((discount / 100) * value))
    }

    const calculateDiscount = (value) => {
        setDiscountedPrice(value)
        setDiscount(Math.round(((price - value) / price) * 100))
    }

    return (
        <div className="row">
            <div className="col-12 ">

                <>
                    <input type="hidden" name="name" value={product.name} />
                    <input type="hidden" name="category" value={product.category} />
                    <input type="hidden" name="sub_category" value={product.sub_category} />
                    <input type="hidden" name="product_unit" value={product.product_unit} />
                    <input type="hidden" name="is_variant" value={product.is_variant} />
                    <input type="hidden" name="variant_name" value={product.variant_name} />
                    <input type="hidden" name="variant_description" value={product.variant_description} />
                    <input type="hidden" name="variant_size" value={product.variant_size} />
                    <input type="hidden" name="variant_color" value={product.variant_color} />
                    <input type="hidden" name="product_images" value={JSON.stringify(files)} />
                </>

                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-between align-items-center">
                            <h4 className="card-title">{product.is_variant ? product.variant_name : product.name}</h4>
                            <i onClick={(event) => event.target.parentElement.parentElement.parentElement.parentElement.parentElement.remove()} className="mdi mdi-delete font-20 text-danger cursor-pointer"></i>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3 mb-2">
                        <Input label="Price" name="variant_price" required={true} defaultValue={product.variant_price} onChange={(event) => onPriceChange(event.target.value)} type="number" className="form-control" placeholder="Enter product price" />
                    </div>
                    <div className="col-md-3 mb-2">
                        <Input label="Discount %" name="variant_discounted_percentage" max={100} value={discount} type="number" onChange={(event) => calculateDiscountedPrice(event.target.value)} className="form-control" placeholder="Enter Discount %" />
                    </div>
                    <div className="col-md-3 mb-2">
                        <Input label="Discounted Price" name="variant_discounted_price" value={discountedPrice} onChange={(event) => calculateDiscount(event.target.value)} type="number" className="form-control" placeholder="Enter Discounted Price" />
                    </div>
                    <div className="col-md-3 mb-2">
                        <Input label="Inventory Stock" required={true} defaultValue={product.variant_stock} name="variant_stock" type="number" className="form-control" placeholder="Enter stock" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 mb-2 d-flex">
                        <div className="avatar-md border rounded cursor-pointer" onClick={() => { fileRef.current.click() }}>
                            <span className="avatar-title bg-white rounded">
                                <i className="h3 mdi mdi-cloud-upload text-primary"></i>
                            </span>
                            <input disabled={files.length >= 5} multiple={true} ref={fileRef} onChange={onImageAdded} type="file" className="d-none" />
                        </div>
                        {files?.map((file, index) => {
                            return <div className="ms-2 d-flex justify-content-end">
                                <i className="dripicons-cross position-absolute cursor-pointer" onClick={() => removeFile(index)} />
                                <img className="avatar-md border rounded object-fit-scale-down p-1" src={file.file_url} alt="" />
                            </div>
                        })}
                    </div>
                </div>

            </div>
            <hr />
        </div>
    )
}

export default ProductCardEdit;