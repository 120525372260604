import apiService from "./api";

//Banners
export const fetchBanners = async (shopId) => {
    const response = await apiService.get(`users/get_shop_banner?shop_id=${shopId}`);
    return response.data;
}

export const fetchBannerTypes = async (shopId) => {
    const response = await apiService.get(`master/get_shop_banner_types?shop_id=${shopId}`);
    return response?.data;
}

export const addBanner = async (shopId, formData) => {
    const response = await apiService.post(`users/add_shop_banner?shop_id=${shopId}`, formData);
    return response?.data;
}

export const updateBanner = async (bannerId, shopId, formData) => {
    const response = await apiService.put(`users/update_shop_banner/${bannerId}?shop_id=${shopId}`, formData);
    return response?.data;
}

export const deleteBanner = async (bannerId, shopId) => {
    const response = await apiService.delete(`users/delete_shop_banner/${bannerId}?shop_id=${shopId}`);
    return response?.data;
}