import { useDispatch, useSelector } from "react-redux";
import Header from "../Common/Header";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { setLoaderView } from "../../redux/actions/settingActions";
import { useState } from "react";
import { deleteProduct, deleteProductVariant, fetchProducts, getBulkCsvFile, updateProductVisibility } from "../../api/productsApi";
import { toast } from 'react-hot-toast';
import DeleteConfirmationPopup from "../Common/DeleteConfirmationPopup";
import CenterErrorMessage from "../Common/CenterErrorMessage";
import ProductCard from "./ProductCard";
import SelectCategoryPopup from "./SelectCategoryPopup";
import { setProducts } from "../../redux/actions/productActions";
import GridView from "../Common/GridView";
import { discountProductPrice } from "../../helper/Price/priceFormatter";
import ToggleSwitch from "../Common/ToggleSwitch";

const Products = () => {

    const shop = useSelector((state) => state?.shop);
    const products = useSelector((state) => state?.products);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [groupBy, setGroupBy] = useState('variants');
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [deleteId, setDeleteId] = useState(null)
    const [productData, setProductData] = useState(null);
    const [variantData, setVariantData] = useState(null);
    const [showNoProductMessage, setShowNoProductMessage] = useState(false);
    const [showSelectCategory, setShowSelectCategory] = useState(false);

    const loadProducts = () => {
        dispatch(setLoaderView(true));
        fetchProducts(shop?.uuid, groupBy).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                dispatch(setProducts(response.data));
                if (response.data.length === 0) {
                    setShowNoProductMessage(true);
                }
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    useEffect(() => {
        loadProducts();
    }, [groupBy]);

    const canDelete = (yesOrNo) => {
        if (yesOrNo) {
            if (deleteId) {
                dispatch(setLoaderView(true));
                if (variantData) {
                    deleteProductVariant(deleteId, shop?.uuid).then(response => {
                        dispatch(setLoaderView(false));
                        if (response.status === 200) {
                            toast.success(response.message);
                            setVariantData(null);
                            setDeleteId(null)
                            setShowDeletePopup(false);
                            loadProducts();
                        }
                    }).catch((error) => {
                        setVariantData(null);
                        setDeleteId(null);
                        dispatch(setLoaderView(false));
                        if (error.response.data.status === 400) {
                            toast.error(error.response.data.message);
                        }
                    });
                } else if (productData) {
                    deleteProduct(deleteId, shop?.uuid).then(response => {
                        dispatch(setLoaderView(false));
                        if (response.status === 200) {
                            toast.success(response.message);
                            setProductData(null);
                            setDeleteId(null)
                            setShowDeletePopup(false);
                            loadProducts();
                        }
                    }).catch((error) => {
                        setProductData(null);
                        setDeleteId(null);
                        dispatch(setLoaderView(false));
                        if (error.response.data.status === 400) {
                            toast.error(error.response.data.message);
                        }
                    });
                }
            }
        } else {
            setProductData(null);
            setDeleteId(null)
            setShowDeletePopup(false);
        }
    }

    const submitSelectCategory = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        dispatch(setLoaderView(true));
        getBulkCsvFile(formData).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                toast.success(response.message);
                setShowSelectCategory(false);

                var link = document.createElement("a");
                link.download = "test";
                link.href = response.data.csv_url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                navigate("/upload-bulk-product-file");
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    const action = (id, row) => {
        return (
            <div className="dropdown card-widgets">
                <span className="dropdown-toggle arrow-none cursor-pointer card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="mdi mdi-dots-vertical"></i>
                </span>
                <div className="dropdown-menu dropdown-menu-end" >
                </div>
            </div>
        )
    }

    const productImage = (cell, row) => {
        return <Link to={`/product/${row.uuid}`} >
            <img className="border rounded" width={50} height={50} src={row.file_url} />
        </Link>
    }

    const productPrice = (cell, row) => {
        return discountProductPrice(row)
    }

    const changeProductVisibility = (id) => {
        updateProductVisibility(id, shop?.uuid).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                toast.success(response.message)
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    const productActive = (cell, row) => {
        return <ToggleSwitch className="ms-1" defaultChecked={row.is_visible} onChange={() => changeProductVisibility(row.uuid)} />
    }

    const productName = (cell, row) => {
        return <Link to={`/product/${row.uuid}`} className="text-primary">{cell}</Link>

    }

    const ordersColumns = [
        {
            dataField: 'id',
            text: '',
            formatter: productImage
        }, {
            dataField: 'variant_name',
            text: 'Name',
            formatter: productName
        },
        // {
        //     dataField: 'brand',
        //     text: 'Brand',
        // },
        {
            dataField: 'category_name',
            text: 'Category'
        }, {
            dataField: 'sub_category_name',
            text: 'Sub-Category'
        }, {
            dataField: 'item_list',
            text: 'Price',
            formatter: productPrice
        }, {
            dataField: 'variant_stock',
            text: 'Stock',
        }, {
            dataField: 'total_amount',
            text: 'Active',
            formatter: productActive
        },
        // {
        //     dataField: 'allow_exchange',
        //     text: 'Exchangable',
        // },
        // {
        //     dataField: 'allow_return',
        //     text: 'Returnable',
        // },
        {
            dataField: 'uuid',
            text: '',
            formatter: action
        }
    ];

    return (
        <>
            <Header title="Products">
                <ul class="nav nav-pills bg-nav-pills nav-justified me-3" role="tablist">
                    <li class="nav-item" role="presentation" onClick={() => setGroupBy('variants')}>
                        <a data-bs-toggle="tab" class={`top-toggle-buttons nav-link cursor-pointer rounded-0 ${groupBy === 'products' ? '' : 'active'}`} role="tab">
                            <i class="mdi mdi-menu"></i>
                        </a>
                    </li>
                    <li class="nav-item" role="presentation" onClick={() => setGroupBy('products')}>
                        <a data-bs-toggle="tab" class={`top-toggle-buttons nav-link cursor-pointer rounded-0 ${groupBy === 'variants' ? '' : 'active'}`} role="tab">
                            <i class="mdi mdi-view-agenda"></i>
                        </a>
                    </li>
                </ul>

                <div className="btn-group">
                    <Link to="/add-product" className="btn min-width btn-primary">Add New Product</Link>
                    <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="visually-hidden">Toggle Dropdown</span>
                    </button>
                    <div className="dropdown-menu">
                        <Link to="/upload-bulk-product-file" className="dropdown-item cursor-pointer">Upload Bulk Products</Link>
                        <div className="dropdown-item cursor-pointer" onClick={() => setShowSelectCategory(true)}>Download Bulk Upload Template</div>
                        <Link to="/edit-bulk-products" className="dropdown-item cursor-pointer">Edit Bulk Products</Link>
                    </div>
                </div>
            </Header>

            {groupBy === 'variants' ?
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12 ">
                                <GridView
                                    keyField="id"
                                    rowClasses="rowClasses"
                                    data={products}
                                    // fetchData={loadOrders}
                                    sizePerPage={25}
                                    // totalSize={totalSize}
                                    hideSizePerPage={false}
                                    columns={ordersColumns} />
                            </div>
                        </div>
                    </div>
                </div>
                :
                <>
                    {products?.map((product, index) => <ProductCard key={index} product={product} index={index} setShowDeletePopup={setShowDeletePopup} setDeleteId={setDeleteId} setProductData={setProductData} setVariantData={setVariantData} />)}
                </>
            }
            <DeleteConfirmationPopup show={showDeletePopup} canDelete={canDelete} />
            <SelectCategoryPopup show={showSelectCategory} onHide={() => setShowSelectCategory(false)} submitForm={submitSelectCategory} />

            {showNoProductMessage && <CenterErrorMessage subTitle="No Product Found!" description="Add products to your online store in just few clicks!" buttonLabel="Add Products" path="/add-product" />}
        </>
    )
}

export default Products;