import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setLoaderView, setShowOrderDetailsPopupButtons } from "../../redux/actions/settingActions";
import { toast } from 'react-hot-toast';
import Header from "../Common/Header";
import { acceptOrder, fetchOrder, rejectOrder } from "../../api/ordersApi";
import OrderTrackingBar from "../Common/OrderTrackingBar";
import CurrencyView from "../Common/CurrencyView";
import { setPendingOrdersList } from "../../redux/actions/orderActions";

const OrderDetails = () => {

    const { orderId } = useParams();
    const dispatch = useDispatch();

    const shop = useSelector((state) => state?.shop);
    const orderDetailsPopupButtons = useSelector((state) => state?.orderDetailsPopupButtons);

    const [order, setOrder] = useState([]);

    const loadOrder = (orderId) => {
        dispatch(setLoaderView(true));
        fetchOrder(orderId).then(response => {
            dispatch(setShowOrderDetailsPopupButtons((response?.data?.order_accept_status === true || response.data.order_reject_status === true) ? false : true));
            setOrder(response.data);
            dispatch(setLoaderView(false));
        }).catch((error) => {
            dispatch(setLoaderView(false));
            toast.error(error.message)
        });
    }

    useEffect(() => {
        if (orderId) {
            loadOrder(orderId);
        }
    }, [orderId]);

    const acceptRejectOrder = (orderStatus) => {
        dispatch(setLoaderView(true));
        if (orderStatus) {
            acceptOrder(order.uuid, shop?.uuid).then((response) => {
                if (response.status === 200) {
                    dispatch(setShowOrderDetailsPopupButtons(false));
                    toast.success(response.message);
                    dispatch(setPendingOrdersList(JSON.parse(response.data)));
                    loadOrder(orderId);
                }
                dispatch(setLoaderView(false));
            }).catch((error) => {
                dispatch(setLoaderView(false));
                toast.error(error.message);
            });
        } else {
            rejectOrder(order.uuid, shop?.uuid).then((response) => {
                if (response.status === 200) {
                    dispatch(setShowOrderDetailsPopupButtons(false));
                    toast.success(response.message);
                    dispatch(setPendingOrdersList(JSON.parse(response.data)));
                    loadOrder(orderId);
                }
                dispatch(setLoaderView(false));
            }).catch((error) => {
                dispatch(setLoaderView(false));
                toast.error(error.message);
            });
        }
    }

    return (
        <>
            <Header title={order?.order_id} back={true} />

            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            {order?.order_accept_status ? <span className="badge w-auto bg-success">Accepted</span> : order?.order_reject_status ? <span className="badge w-auto bg-danger">Rejected</span> : <span className="badge w-auto bg-warning">Pending</span>}
                            {order?.order_status && <OrderTrackingBar orderStatus={order?.order_status} />}
                            <div className="row">
                                <div className="col-6 col-sm-6 col-md-3">
                                    <h5>Billing Address</h5>
                                    <div className="address-container">
                                        <p>{order?.address_details?.building_number}</p>
                                        <p>{order?.address_details?.locality}</p>
                                        <p>{order?.address_details?.city}</p>
                                        <p>{order?.address_details?.state}</p>
                                        <p>{order?.address_details?.country}</p>
                                        <p>{order?.address_details?.pincode}</p>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-6 col-md-3">
                                    <h5>Shipping Address</h5>
                                    <div className="address-container">
                                        <p>{order?.address_details?.building_number}</p>
                                        <p>{order?.address_details?.locality}</p>
                                        <p>{order?.address_details?.city}</p>
                                        <p>{order?.address_details?.state}</p>
                                        <p>{order?.address_details?.country}</p>
                                        <p>{order?.address_details?.pincode}</p>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-6 col-md-3  mt-3 m-md-0">
                                    <h5>Payment Mode</h5>
                                    <p className="m-0">{order?.payment_method}</p>
                                    {order?.is_paid ? <span className="badge bg-success">Paid</span> : <span className="badge bg-warning">Un-Paid</span>}
                                </div>
                                <div className="col-12 col-sm-6 col-md-3 mt-3 m-md-0">
                                    <h5>Order Summary</h5>
                                    <div className="order-summary-container">
                                        {/* <p>Gross Amount<span className="float-end text-decoration-line-through"><CurrencyView value={order?.gross_amount} /></span></p> */}
                                        <p>Offer Price<span className="float-end"><CurrencyView value={order?.gross_amount - order?.discount_amount} /></span></p>
                                        <p>Delivery Charges<span className="float-end"><CurrencyView value={order?.delivery_charges} /></span></p>
                                        <p>Net Amount<span className="float-end"><CurrencyView value={order?.net_amount} /></span></p>
                                        {order?.gst > 0 ? <p>GST<span className="float-end"><CurrencyView value={order?.gst} /></span></p> : null}
                                        <b><p>Total Amount<span className="float-end"><CurrencyView value={order?.total_amount} /></span></p></b>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th>Product Name</th>
                                                    <th>Price</th>
                                                    <th>Quantity</th>
                                                    <th className="text-end">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {order?.order_items?.map((orderItem, index) => {
                                                    let product = orderItem?.product_details;
                                                    return <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td><img src={product?.image_list[0]?.image_url} alt="contact-img" title="contact-img" className="rounded me-3" height="50" /></td>
                                                        <td>{product?.variant_name}</td>
                                                        <td><CurrencyView value={orderItem?.gross_amount - orderItem?.discount_amount || 0} /></td>
                                                        <td>{orderItem?.quantity}</td>
                                                        <td className="text-end"><CurrencyView value={orderItem?.unit_total_amount || 0} /></td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {orderDetailsPopupButtons && <div className="row mt-3">
                        <div className="col-md-12 text-end">
                            <button type="button" onClick={() => acceptRejectOrder(false)} className="btn btn-secondary me-2">Reject</button>
                            <button type="button" onClick={() => acceptRejectOrder(true)} className="btn btn-primary">Accept</button>
                        </div>
                    </div>}
                </div>
            </div>
        </>
    )
}
export default OrderDetails;