import { useState } from "react";
import { useSelector } from "react-redux";
import { deleteProductImage } from "../../api/productsApi";
import FilePicker from "../Common/FilePicker";
import Input from "../Common/Input";
import Label from "../Common/Label";
import RadioButton from "../Common/RadioButton";
import SunEditor from 'suneditor-react';

const ProductVariant = ({ productData, productName, index, hideCloseButton }) => {

    const shopId = useSelector((state) => state?.shop?.uuid);

    const [hasColor, setHasColor] = useState(productData?.variant_color ? true : false);
    const [hasDiscount, setHasDiscount] = useState(productData?.variant_discounted_percentage > 0 ? true : false);
    const [price, setPrice] = useState(productData?.variant_price || 1);
    const [discount, setDiscount] = useState(productData?.variant_discounted_percentage || 0);
    const [discountedPrice, setDiscountedPrice] = useState(productData?.variant_discounted_price || price);
    const [hasSize, setHasSize] = useState(productData?.variant_size ? true : false);

    const calculateDiscountedPrice = (value) => {
        setDiscount(value)
        setDiscountedPrice(price - ((value / 100) * price))
    }

    const onPriceChange = (value) => {
        setPrice(value)
        setDiscountedPrice(value - ((discount / 100) * value))
    }

    const calculateDiscount = (value) => {
        setDiscountedPrice(value)
        setDiscount(((price - value) / price) * 100)
    }

    const removeFile = (imageData) => {
        deleteProductImage(imageData.id, shopId)
    }

    return (
        <div className="border p-3 pb-0 mb-3 rounded   alert alert-dismissible">
            {!hideCloseButton && <button type="button" onClick={(event) => event.target.parentElement.remove()} className="btn-close" />}
            <div className="row">
                <div className="col-md-12 mb-3">
                    <Input label="Variant Name" required={true} defaultValue={productData?.variant_name || productName} name={`variant_name__${index + 1}`} className="form-control" placeholder="Enter variant name" />
                </div>
            </div>
            <div className="row">
                <div className="col-md-2 mb-3">
                    <Input label="MRP" required={true} name={`variant_price__${index + 1}`} value={price} onChange={(event) => onPriceChange(event.target.value)} type="number" className="form-control" placeholder="Enter product price" />
                </div>
                <div className="col-md-2 mb-3">
                    <RadioButton label="Add discount?" label1="Yes" label2="No" id1={`hasVariantDiscountYes__${index + 1}`} id2={`hasVariantDiscountNo__${index + 1}`} isYes={hasDiscount} setIsYes={setHasDiscount} />
                </div>
                {hasDiscount && <>
                    <div className="col-md-2 mb-3">
                        <Input label="Discount %" required={true} name={`variant_discounted_percentage__${index + 1}`} value={discount} type="number" onChange={(event) => calculateDiscountedPrice(event.target.value)} className="form-control" placeholder="Enter Discount %" />
                    </div>
                    <div className="col-md-2 mb-3">
                        <Input label="Discounted Price" required={true} name={`variant_discounted_price__${index + 1}`} value={discountedPrice} onChange={(event) => calculateDiscount(event.target.value)} type="number" className="form-control" placeholder="Enter Discounted Price" />
                    </div>
                    {price === discountedPrice || Number(discount) === 0 ? null :
                        <div className="col-md-4 mb-3">
                            <label className="hidden"></label>
                            <div className="form-control">
                                <del className="text-danger">₹</del>
                                <del className="text-danger">{price}</del>
                                <span className="text-success ml-2">₹</span>
                                <span className="text-success">{discountedPrice}</span>
                                <span className="badge badge-secondary-lighten ml-2">{discount}% OFF</span>
                            </div>
                        </div>
                    }
                </>}
            </div>
            <div className="row">
                <div className="col-md-12 mb-3">
                    <Label label="Description" />
                    <SunEditor height="200" name={`variant_description__${index + 1}`} setContents={productData?.variant_description} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 mb-3">
                    <Label required={true} label="Product Images" />
                    <FilePicker name={`product_images__${index + 1}`} />

                    <div className="row mt-3">
                        {productData?.image_list?.map((obj, index) => {
                            return <div key={`variant-image-${index}`} className="col-md-2">
                                <div className="card shadow-none border">
                                    <div className="p-2 txt-right">
                                        <span onClick={() => removeFile(obj)} className="btn btn-link p-0 btn btn-sm text-muted col-auto">
                                            <i className="dripicons-cross"></i>
                                        </span>
                                        <div className="row align-items-center">
                                            <img height={100} src={obj.image_url} alt={obj?.name} style={{ objectFit: "contain" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-2 mb-3">
                    <Input label="Inventory Stock" required={true} defaultValue={productData?.variant_stock} name={`variant_stock__${index + 1}`} type="number" className="form-control" placeholder="Enter stock" />
                </div>
                <div className="col-md-2 mb-3">
                    <RadioButton label="Have Size?" label1="Yes" label2="No" id1={`haveVariantSizeYes__${index + 1}`} id2={`haveVariantSizeNo__${index + 1}`} isYes={hasSize} setIsYes={setHasSize} />
                </div>
                {hasSize && <div className="col-md-2 mb-3">
                    <Input label="Size" required={true} defaultValue={productData?.variant_size} name={`variant_size__${index + 1}`} type="text" className="form-control" placeholder="Enter size" />
                </div>}
                <div className="col-md-2 mb-3">
                    <RadioButton label="Have color?" label1="Yes" label2="No" id1={`haveVariantColorYes__${index + 1}`} id2={`haveVariantColorNo__${index + 1}`} isYes={hasColor} setIsYes={setHasColor} />
                </div>
                {hasColor && <div className="col-md-1 mb-3">
                    <Input labelClassName="hidden" required={true} defaultValue={productData?.variant_color} name={`variant_color__${index + 1}`} type="color" className="form-control" />
                </div>}
            </div>
            {productData && <input type="hidden" name={`variant_uuid__${index + 1}`} value={productData.uuid} />}
        </div>
    )
}

export default ProductVariant;