
import Label from './Label';

const Input = ({ label, placeholder, id, icon, name, className, labelClassName, required, value, defaultValue }) => {
    return (
        <>
            <Label labelClassName={labelClassName} label={label} required={required} />
            <div className="input-group">
                <span className="input-group-text"><i className={icon}></i></span>
                <input type="text" value={value} name={name} defaultValue={defaultValue} className={className} id={id} placeholder={placeholder} />
            </div>

        </>
    )
}

export default Input;

