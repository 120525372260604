import Checkbox from "../../Common/Checkbox";
import Input from "../../Common/Input";
import { useSelector } from "react-redux";
import Button from "../../Common/Button";
import Label from "../../Common/Label";
import { useState } from "react";

const OpenCloseDetails = ({ submitShopDetails }) => {

    const shop = useSelector((state) => state?.shop);

    const [monday, setMonday] = useState(shop?.open_monday);
    const [tuesday, setTuesday] = useState(shop?.open_tuesday);
    const [wednesday, setWednesday] = useState(shop?.open_wednesday);
    const [thursday, setThursday] = useState(shop?.open_thursday);
    const [friday, setFriday] = useState(shop?.open_friday);
    const [saturday, setSaturday] = useState(shop?.open_saturday);
    const [sunday, setSunday] = useState(shop?.open_sunday);

    return (
        <>
            <h4 className="header-title mb-3">Shop Timings</h4>
            <div className="row">
                <div className="col-md-12 ">
                    <form onSubmit={submitShopDetails}>

                        <div className="row">
                            <div className="col-6 col-md-3 mb-3">
                                <Input label="Opening Hours" key={shop?.opening_hours} defaultValue={shop?.opening_hours} name="opening_hours" required={false} type="time" className="form-control" placeholder="Enter shop opening hours" />
                            </div>
                            <div className="col-6 col-md-3 mb-3">
                                <Input label="Closing Hours" key={shop?.closing_hours} defaultValue={shop?.closing_hours} name="closing_hours" required={false} type="time" className="form-control" placeholder="Enter shop closing hours" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3 mb-3">
                                <Label label="Opening Days" />
                                <Checkbox checked={monday} onChange={() => setMonday(!monday)} label="Monday" name="open_monday" id="monday" />
                                <Checkbox checked={tuesday} onChange={() => setTuesday(!tuesday)} label="Tuesday" name="open_tuesday" id="tuesday" />
                                <Checkbox checked={wednesday} onChange={() => setWednesday(!wednesday)} label="Wednesday" name="open_wednesday" id="wednesday" />
                                <Checkbox checked={thursday} onChange={() => setThursday(!thursday)} label="Thursday" name="open_thursday" id="thursday" />
                                <Checkbox checked={friday} onChange={() => setFriday(!friday)} label="Friday" name="open_friday" id="friday" />
                                <Checkbox checked={saturday} onChange={() => setSaturday(!saturday)} label="Saturday" name="open_saturday" id="saturday" />
                                <Checkbox checked={sunday} onChange={() => setSunday(!sunday)} label="Sunday" name="open_sunday" id="sunday" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 text-end">
                                <Button type="submit" className="btn btn-primary" label="Save Details" />
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}

export default OpenCloseDetails;