import { Link } from "react-router-dom";

const CenterErrorMessage = ({ title, subTitle, description, buttonLabel, path = "/" }) => {
    return (
        <div className="mt-5 mb-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="text-center">
                            <div className="row mt-5">
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-6">
                                    <div className="text-center">
                                        {title && <h1 className="text-uppercase">{title}</h1>}
                                        {subTitle && <h5 className="text-uppercase">{subTitle}</h5>}
                                        {description && <p className="text-muted">{description}</p>}
                                        {buttonLabel && <Link className="btn btn-sm btn-outline-primary" to={path}>{buttonLabel}</Link>}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CenterErrorMessage;