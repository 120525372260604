export const GenderConfig = [
    {
        label: 'Male',
        value: 'Male'
    }, {
        label: 'Female',
        value: 'Female'
    }, {
        label: 'Others',
        value: 'Others'
    }
]