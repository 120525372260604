import Header from "../Common/Header";
import Chart from 'react-apexcharts';
import ActionCard from "../Common/ActionCard";
import { useDispatch, useSelector } from "react-redux";
import QRCode from "react-qr-code";
import { toast } from 'react-hot-toast';
import { fetchDashboardData, fetchGstFilingDates } from '../../api/dashboardApi';
import { useEffect } from "react";
import { setLoaderView, setShowMyProfilePopup } from "../../redux/actions/settingActions";
import { setDashboardDetails, setGstDates } from "../../redux/actions/dashboardActions";
import CurrencyView from '../Common/CurrencyView';
import { Link } from "react-router-dom";
//import Flickity from "react-flickity-component";

const Home = () => {

    const dispatch = useDispatch();

    const shop = useSelector((state) => state?.shop);
    const dashboard = useSelector((state) => state?.dashboard);
    //const gstFilingDates = useSelector((state) => state?.gstFilingDates);

    const options = {
        chart: {
            id: 'apexchart-example'
        },
        xaxis: {
            categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
        },
        colors: [process.env.REACT_APP_PRIMARY_COLOR]
    }

    const series = [{
        name: 'series-1',
        data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
    }]

    useEffect(() => {
        if (!dashboard) {
            dispatch(setLoaderView(true));
        }
        fetchDashboardData(shop?.uuid).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                dispatch(setDashboardDetails(response.data));
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });

        fetchGstFilingDates().then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                dispatch(setGstDates(response.data));
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }, []);

    return (
        <>
            <Header title="Home" />

            {(dashboard?.update_shop_details_warning || dashboard?.add_product_warning || dashboard?.update_profile_data_warning) && <div className="card bg-warning">
                <div className="card-body">
                    <h4 className="header-title mb-3 text-dark">Important Actions</h4>
                    <div className="row">
                        {dashboard?.update_shop_details_warning && <ActionCard title="Update Shop Details" icon="uil-store" description={dashboard?.shop_missing_fields_list} path="/shop-details" />}
                        {dashboard?.add_product_warning && <ActionCard title="Add Product" icon="uil-box" description={dashboard?.profile_missing_fields_list} path="/add-product" />}
                        {dashboard?.update_profile_data_warning && <ActionCard onClick={() => {
                            dispatch(setShowMyProfilePopup(true));
                        }} title="Update Profile" icon="uil-user" description="Please update your profile!" path="" />}
                    </div>
                </div>
            </div>}

            <div className="row">
                <div className="col-md-6">

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-bg-img" style={{ backgroundImage: "url(/assets/images/bg-pattern.png)" }}>
                                <div className="card-body">
                                    <div className="row ">
                                        <div className="col-8">
                                            <h4>{shop?.name}</h4>
                                            <p className="text-dark-50 mt-3 mb-0">Online Store URL</p>
                                            <h5 className="my-0 cursor-pointer text-link" onClick={() => window.open(`${process.env.REACT_APP_APP_URL}/${shop?.slug}`, '_blank')}>{`${process.env.REACT_APP_APP_URL}/${shop?.slug}`}</h5>
                                        </div>
                                        <div className="col-4">
                                            <div className="rounded border width-fit-content p-1">
                                                <QRCode className="qr-code-home" value={`${process.env.REACT_APP_APP_URL}/${shop?.slug}?qr_code=${dashboard?.qr_code}`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6 col-md-6">
                            <Link to="/customers" className="card widget-flat">
                                <div className="card-body">
                                    <h5 className="text-muted fw-normal mt-0" title="Number of Customers">Customers</h5>
                                    <h3 className="">{dashboard?.total_users}</h3>
                                    {/* <p className="mb-0 text-muted">
                                        <span className="text-success me-2"><i className="mdi mdi-arrow-up-bold"></i> 5.27%</span>
                                        <span className="text-nowrap">Since last month</span>
                                    </p> */}
                                </div>
                            </Link>
                        </div>

                        <div className="col-6 col-md-6">
                            <Link to="/orders" className="card widget-flat">
                                <div className="card-body">
                                    <h5 className="text-muted fw-normal mt-0" title="Number of Orders">Orders</h5>
                                    <h3 className="">{dashboard?.total_orders}</h3>
                                    {/* <p className="mb-0 text-muted">
                                        <span className="text-danger me-2"><i className="mdi mdi-arrow-down-bold"></i> 1.08%</span>
                                        <span className="text-nowrap">Since last month</span>
                                    </p> */}
                                </div>
                            </Link>
                        </div>

                        <div className="col-6 col-md-6">
                            <div className="card widget-flat">
                                <div className="card-body">
                                    <h5 className="text-muted fw-normal mt-0" title="Average Revenue">Revenue</h5>
                                    <h3 className=""><CurrencyView value={dashboard?.total_revenue} /></h3>
                                    {/* <p className="mb-0 text-muted">
                                        <span className="text-danger me-2"><i className="mdi mdi-arrow-down-bold"></i> 7.00%</span>
                                        <span className="text-nowrap">Since last month</span>
                                    </p> */}
                                </div>
                            </div>
                        </div>

                        <div className="col-6 col-md-6">
                            <div className="card widget-flat">
                                <div className="card-body">
                                    <h5 className="text-muted fw-normal mt-0" title="Growth">QR Scans</h5>
                                    <h3 className="">{dashboard?.total_scans}</h3>
                                    {/* <p className="mb-0 text-muted">
                                        <span className="text-success me-2"><i className="mdi mdi-arrow-up-bold"></i> 4.87%</span>
                                        <span className="text-nowrap">Since last month</span>
                                    </p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body p-3">
                            <h5 className="mb-3">Sales</h5>
                            <Chart options={options} series={series} type="bar" height={320} />
                        </div>
                    </div>

                    {/* <div className="card">
                        <div className="card-body p-3">
                            <h5 className="mb-3">GST Filing Dates</h5>
                            <Flickity options={{ autoPlay: true, prevNextButtons: false }}>
                                {gstFilingDates?.map((gstFiling) => {
                                    return <div className="border rounded mx-1 p-2">
                                        <b>{gstFiling?.f}</b>
                                        <p className="mb-0">{gstFiling?.d}</p>
                                    </div>
                                })}
                            </Flickity>
                        </div>
                    </div> */}
                    
                </div>
                
            </div>
        </>
    )
}

export default Home;