import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-hot-toast';
import { acceptOrder, rejectOrder } from "../../api/ordersApi";
import CurrencyView from '../../Components/Common/CurrencyView';
import OrderTrackingBar from "../../Components/Common/OrderTrackingBar";
import { setOrder, setPendingOrdersList } from "../../redux/actions/orderActions";
import { setLoaderView, setShowOrderDetailsPopup, setShowOrderDetailsPopupButtons } from "../../redux/actions/settingActions";

const AcceptRejectOrder = () => {

    const order = useSelector((state) => state?.order);
    const orderDetailsPopup = useSelector((state) => state?.orderDetailsPopup);
    const orderDetailsPopupButtons = useSelector((state) => state?.orderDetailsPopupButtons);
    const shop = useSelector((state) => state?.shop);

    const dispatch = useDispatch();

    if (!order) {
        return null;
    }

    const acceptRejectOrder = (orderStatus) => {
        dispatch(setLoaderView(true));
        if (orderStatus) {
            acceptOrder(order.uuid, shop?.uuid).then((response) => {
                if (response.status === 200) {
                    dispatch(setShowOrderDetailsPopup(false));
                    dispatch(setShowOrderDetailsPopupButtons(false));
                    toast.success(response.message);
                    dispatch(setPendingOrdersList(JSON.parse(response.data)))
                    dispatch(setOrder(null));
                }
                dispatch(setLoaderView(false));
            }).catch((error) => {
                dispatch(setLoaderView(false));
                toast.error(error.message);
            });
        } else {
            rejectOrder(order.uuid, shop?.uuid).then((response) => {
                if (response.status === 200) {
                    dispatch(setShowOrderDetailsPopup(false));
                    dispatch(setShowOrderDetailsPopupButtons(false));
                    toast.success(response.message);
                    dispatch(setPendingOrdersList(JSON.parse(response.data)))
                    dispatch(setOrder(null));
                }
                dispatch(setLoaderView(false));
            }).catch((error) => {
                dispatch(setLoaderView(false));
                toast.error(error.message);
            });
        }
    }

    return (
        <Modal size="xl" show={orderDetailsPopup} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered onHide={() => {
            dispatch(setShowOrderDetailsPopup(false));
            dispatch(setShowOrderDetailsPopupButtons(false));
        }}>
            {/* <Modal.Header closeButton={!orderDetailsPopupButtons}> */}
            <Modal.Header closeButton>
                <Modal.Title className="m-0" id="contained-modal-title-vcenter">Accept/Reject Order {order?.order_accept_status ? <span className="badge bg-success">Accepted</span> : order?.order_reject_status ? <span className="badge bg-danger">Rejected</span> : <span className="badge bg-warning">Pending</span>}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">

                        <OrderTrackingBar orderStatus={order?.order_status} />

                        <div className="row">
                            <div className="col-sm-3">
                                <h5>Billing Address</h5>
                                <div className="address-container">
                                    <p>{order?.address_details?.building_number}</p>
                                    <p>{order?.address_details?.locality}</p>
                                    <p>{order?.address_details?.city}</p>
                                    <p>{order?.address_details?.state}</p>
                                    <p>{order?.address_details?.country}</p>
                                    <p>{order?.address_details?.pincode}</p>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <h5>Shipping Address</h5>
                                <div className="address-container">
                                    <p>{order?.address_details?.building_number}</p>
                                    <p>{order?.address_details?.locality}</p>
                                    <p>{order?.address_details?.city}</p>
                                    <p>{order?.address_details?.state}</p>
                                    <p>{order?.address_details?.country}</p>
                                    <p>{order?.address_details?.pincode}</p>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <h5>Payment Mode</h5>
                                <p className="m-0">{order?.payment_method}</p>
                                {order?.is_paid ? <span className="badge bg-success">Paid</span> : <span className="badge bg-warning">Un-Paid</span>}
                            </div>
                            <div className="col-sm-3">
                                <h5>Order Summary</h5>
                                <div className="order-summary-container">
                                    {/* <p>Gross Amount<span className="float-end text-decoration-line-through"><CurrencyView value={order?.gross_amount} /></span></p> */}
                                    <p>Offer Price<span className="float-end"><CurrencyView value={order?.gross_amount - order?.discount_amount} /></span></p>
                                    <p>Delivery Charges<span className="float-end"><CurrencyView value={order?.delivery_charges} /></span></p>
                                    <p>Net Amount<span className="float-end"><CurrencyView value={order?.net_amount} /></span></p>
                                    {order?.gst > 0 ? <p>GST<span className="float-end"><CurrencyView value={order?.gst} /></span></p> : null}
                                    <b><p>Total Amount<span className="float-end"><CurrencyView value={order?.total_amount} /></span></p></b>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-12">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                                <th>Product Name</th>
                                                <th>Price</th>
                                                <th>Quantity</th>
                                                <th className="text-end">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {order?.order_items?.map((orderItem, index) => {
                                                let product = orderItem?.product_details;
                                                return <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td><img src={product?.image_list[0]?.image_url} alt="contact-img" title="contact-img" className="rounded me-3" height="50" /></td>
                                                    <td>{product?.variant_name}</td>
                                                    <td><CurrencyView value={orderItem?.gross_amount - orderItem?.discount_amount || 0} /></td>
                                                    <td>{orderItem?.quantity}</td>
                                                    <td className="text-end"><CurrencyView value={orderItem?.unit_total_amount || 0} /></td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            {orderDetailsPopupButtons && <Modal.Footer>
                <button type="button" onClick={() => acceptRejectOrder(false)} className="btn btn-secondary">Reject</button>
                <button type="button" onClick={() => acceptRejectOrder(true)} className="btn btn-primary">Accept</button>
            </Modal.Footer>}
        </Modal>
    );
}

export default AcceptRejectOrder;