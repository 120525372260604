import { ActionTypes } from "../constants/actionTypes"

export const shopReducer = (state = [], { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_SHOP_DETAIL:
            return payload;
        case ActionTypes.LOGOUT_USER:
            return {};
        default:
            return state;
    }
}

export const roleReducer = (state = [], { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_ROLE:
            return payload;
        case ActionTypes.LOGOUT_USER:
            return {};
        default:
            return state;
    }
}

export const menuReducer = (state = [], { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_MENU:
            return payload;
        case ActionTypes.LOGOUT_USER:
            return [];
        default:
            return state;
    }
}

export const rolesReducer = (state = [], { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_ROLES:
            return payload;
        case ActionTypes.LOGOUT_USER:
            return [];
        default:
            return state;
    }
}

export const loaderReducer = (state = false, { type, payload }) => {
    switch (type) {
        case ActionTypes.SHOW_HIDE_LOADER:
            return payload;
        default:
            return state;
    }
}

export const orderDetailsPopupReducer = (state = false, { type, payload }) => {
    switch (type) {
        case ActionTypes.SHOW_HIDE_ORDER_DETAILS_POPUP:
            return payload;
        default:
            return state;
    }
}

export const orderDetailsPopupButtonsReducer = (state = false, { type, payload }) => {
    switch (type) {
        case ActionTypes.SHOW_HIDE_ORDER_DETAILS_POPUP_BUTTONS:
            return payload;
        default:
            return state;
    }
}

export const myProfilePopupReducer = (state = false, { type, payload }) => {
    switch (type) {
        case ActionTypes.SHOW_HIDE_MY_PROFILE_POPUP:
            return payload;
        default:
            return state;
    }
}