export const setPhoneNo = (phoneNumber, rowData) => {
    let phoneNo = [];
    if (rowData?.country_code) {
        phoneNo.push(rowData?.country_code);
    }
    phoneNo.push('-');
    if (phoneNumber) {
        phoneNo.push(phoneNumber);
    }
    return phoneNo;
}