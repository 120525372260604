import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-hot-toast';
import { updateProductVisibility } from "../../api/productsApi";
import { setLoaderView } from "../../redux/actions/settingActions";
import ToggleSwitch from "../Common/ToggleSwitch";

const ProductCard = ({ variant, image, setShowDeletePopup, setDeleteId, setVariantData }) => {

    const shopId = useSelector((state) => state?.shop?.uuid);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const changeProductVisibility = (id) => {
        updateProductVisibility(id, shopId).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                toast.success(response.message)
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    const discountProductPrice = (variant) => {
        return variant?.variant_discounted_price && variant?.variant_discounted_percentage ?
            <div>
                <strong>
                    <span className="text-success">₹</span>
                    <span className="text-success mr-2">{variant?.variant_discounted_price}</span>
                    <small><del className="text-danger">₹</del></small>
                    <small><del className="text-danger">{variant?.variant_price}</del></small>
                    <small><span className="badge badge-secondary-lighten ml-2">{variant?.variant_discounted_percentage}% OFF</span></small>
                </strong>
            </div>
            :
            <div>
                <strong>
                    <span className="text-dark">₹</span>
                    <span className="text-dark">{variant?.variant_price}</span>
                </strong>
            </div>
    }

    return (
        <>
            <hr />
            <div className="row">
                <div className="d-flex col-12">
                    <div className="d-flex align-items-center ms-3">
                        <img src={image} className="product-image me-1" alt="..." />
                    </div>

                    <div className="card-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="card-title">{variant.variant_name}</h4>
                                    <div className="d-flex align-items-center">
                                        <div className="dropdown ms-2">
                                            <span className="dropdown-toggle arrow-none cursor-pointer card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="mdi mdi-dots-vertical big-dots-vertical"></i>
                                            </span>
                                            <div className="dropdown-menu dropdown-menu-end" >
                                                <span className="dropdown-item cursor-pointer" onClick={() => {
                                                    navigate(`/update-product/p/${variant.uuid}`);
                                                }}><i className="uil-file-edit-alt me-1"></i>Edit</span>
                                                <span className="dropdown-item cursor-pointer" onClick={() => {
                                                    navigate(`/update-product/p/${variant.uuid}`);
                                                }}><i className="uil-file-plus-alt me-1"></i>Add Variant</span>
                                                <span className="dropdown-item cursor-pointer" onClick={() => {
                                                    setVariantData(variant);
                                                    setDeleteId(variant.uuid);
                                                    setShowDeletePopup(true);
                                                }}><i className="uil uil-trash-alt me-1"></i>Delete</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                {discountProductPrice(variant)}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 d-flex">
                                <h4><span className="badge badge-primary-lighten me-2">{variant.category_name}</span></h4>
                                <h4><span className="badge badge-primary-lighten">{variant.sub_category_name}</span></h4>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-2">
                                <span className="m-0">Stock : <strong className="text-primary">{variant.variant_stock}</strong></span>
                            </div>
                            <div className="col-md-2">
                                {variant.variant_size && <span className="m-0">Size : <strong className="text-primary">{variant.variant_size}</strong></span>}
                            </div>
                            <div className="col-md-2">
                                {variant.variant_color && <span className="d-flex m-0">Color : <div className="w-auto p-2 border rounded ms-1" style={{ backgroundColor: variant.variant_color }} /></span>}
                            </div>
                            <div className="col-md-2">
                                <span className="d-flex m-0">Available : <ToggleSwitch className="ms-1" defaultChecked={variant.is_visible} onChange={() => changeProductVisibility(variant.uuid)} /></span>
                            </div>
                            <div className="col-md-2" />
                            <div className="col-md-2" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductCard;