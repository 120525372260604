import { useState } from "react";

const FilePicker = ({ name }) => {

    const [files, setFiles] = useState([]);

    const onImageAdded = (event) => {
        setFiles(files => [...files, ...event.target.files]);
    }

    const removeFile = (index) => {
        let arr = [...files];
        arr.splice(index, 1);
        setFiles(arr);
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <input type="file" className="form-control" multiple={true} name={name} onChange={onImageAdded} />
                <div className="row mt-3">
                    {files?.map((obj, index) => {
                        return <div key={index} className="col-md-2">
                            <div className="card shadow-none border">
                                <div className="p-2 txt-right">
                                    <span onClick={() => removeFile(index)} className="btn btn-link p-0 btn btn-sm text-muted col-auto">
                                        <i className="dripicons-cross"></i>
                                    </span>
                                    <div className="row align-items-center">
                                        <img height={100} src={URL.createObjectURL(obj)} alt={obj?.name} style={{ objectFit: "contain" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default FilePicker;