import { useSelector } from "react-redux";

const FullScreenLoader = () => {

    const loader = useSelector((state) => state?.loader);

    return (
        <div id="preloader" style={{ display: loader ? 'block' : 'none' }}>
            <div id="status">
                <div className="bouncing-loader">
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        </div>
    )
}

export default FullScreenLoader;