import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-hot-toast';
import { setLoaderView } from "../../../redux/actions/settingActions";
import GridView from "../../Common/GridView";
import Header from "../../Common/Header";
import { addBanner, deleteBanner, fetchBanners, fetchBannerTypes, updateBanner } from "../../../api/bannerApi";
import ToggleSwitch from "../../Common/ToggleSwitch";
import AddUpdateBannerPopup from "./AddUpdateBannerPopup";
import DeleteConfirmationPopup from "../../Common/DeleteConfirmationPopup";
import Button from "../../Common/Button";
import { setBanners } from "../../../redux/actions/bannerActions";
import Slider from "./Slider";

const Banner = () => {

    const shop = useSelector((state) => state?.shop);
    const banners = useSelector((state) => state?.banners);

    const dispatch = useDispatch();

    const [filteredBanners, setFilteredBanners] = useState([]);
    const [bannerTypes, setBannerTypes] = useState([]);
    const [bannerData, setBannerData] = useState(null);
    const [showAddUpdateBanner, setShowAddUpdateBanner] = useState(false);
    const [deleteId, setDeleteId] = useState(null)
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [bannerImage, setBannerImage] = useState(null);

    useEffect(() => {
        if (banners) {
            setFilteredBanners(banners.filter((banner) => {
                return banner?.is_visible === true
            }))
        }
    }, [banners]);

    const loadBanners = () => {
        if (banners.length === 0) {
            dispatch(setLoaderView(true));
        }
        fetchBanners(shop?.uuid).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                dispatch(setBanners(response.data));
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    const loadBannerTypes = () => {
        fetchBannerTypes(shop?.uuid).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                setBannerTypes(response.data);
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    useEffect(() => {
        loadBanners();
        loadBannerTypes();
    }, []);

    const bannerImageView = (cell) => {
        return <img src={cell} width={300} height={100} />
    }

    const changeBannerVisibility = (isVisible, bannerId) => {
        const formData = new FormData();
        formData.append('is_visible', isVisible);
        updateBanner(bannerId, shop?.uuid, formData).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                //toast.success(response.message);
                loadBanners();
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    const activeButton = (cell, row) => {
        return <ToggleSwitch defaultChecked={cell} onChange={(event) => changeBannerVisibility(event.target.checked, row.id)} />
    }

    const usersAction = (id, row) => {
        return (<div className="dropdown card-widgets">
            <span className="dropdown-toggle arrow-none cursor-pointer card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="mdi mdi-dots-vertical"></i>
            </span>
            <div className="dropdown-menu dropdown-menu-end" >
                <span className="dropdown-item cursor-pointer" onClick={() => {
                    setBannerData(row);
                    setShowAddUpdateBanner(true);
                }}><i className="uil-file-edit-alt me-1"></i>Edit</span>
                <span className="dropdown-item cursor-pointer" onClick={() => {
                    setBannerData(row);
                    setDeleteId(id);
                    setShowDeletePopup(true);
                }}><i className="uil uil-trash-alt me-1"></i>Delete</span>
            </div>
        </div>
        )
    }

    const bannerColumns = [
        {
            dataField: 'title',
            text: 'Title',
        }, {
            dataField: 'description',
            text: 'Description',
        }, {
            dataField: 'file_url',
            text: 'Image',
            formatter: bannerImageView
        }, {
            dataField: 'is_visible',
            text: 'Show/Hide',
            formatter: activeButton
        }, {
            dataField: 'id',
            text: '',
            formatter: usersAction
        }
    ];

    const submitCreateBannerHandler = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        if (bannerData) {
            dispatch(setLoaderView(true));
            updateBanner(bannerData?.id, shop?.uuid, formData).then(response => {
                if (response.status === 200) {
                    toast.success(response.message);
                    loadBanners();
                    setBannerImage(null);
                    event.target.reset();
                    setShowAddUpdateBanner(false);
                }
            }).catch(error => {
                dispatch(setLoaderView(false));
                if (error.response.data.status === 400) {
                    toast.error(error.response.data.message);
                }
            });
        } else {
            dispatch(setLoaderView(true));
            addBanner(shop?.uuid, formData).then(response => {
                if (response.status === 200) {
                    toast.success(response.message);
                    loadBanners();
                    setBannerImage(null);
                    event.target.reset();
                    setShowAddUpdateBanner(false);
                }
            }).catch(error => {
                dispatch(setLoaderView(false));
                if (error.response.data.status === 400) {
                    toast.error(error.response.data.message);
                }
            });
        }
    }

    const canDelete = (yesOrNo) => {
        if (yesOrNo) {
            dispatch(setLoaderView(true));
            if (bannerData) {
                deleteBanner(deleteId, shop?.uuid).then(response => {
                    dispatch(setLoaderView(false));
                    if (response.status === 200) {
                        toast.success(response.message);
                        setBannerData(null);
                        setShowDeletePopup(false);
                        loadBanners();
                    }
                }).catch((error) => {
                    setBannerData(null);
                    dispatch(setLoaderView(false));
                    if (error.response.data.status === 400) {
                        toast.error(error.response.data.message);
                    }
                });
            }
        } else {
            setBannerData(null);
            setShowDeletePopup(false);
        }
    }

    return (
        <>
            <Header title="Banner">
                <Button label="Add Banner" className="btn btn-primary float-right mb-3" onClick={() => {
                    setShowAddUpdateBanner(true);
                    setBannerData(null);
                }} />
            </Header>

            {filteredBanners?.length > 0 && <div className="card">
                <div className="card-body">
                    <Slider banners={filteredBanners} />
                </div>
            </div>}

            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 ">
                            <GridView
                                className="remove-white-space"
                                data={banners}
                                columns={bannerColumns}
                                pagination={false}
                                disablePagination={true}
                                totalSize={banners?.length}
                                sizePerPage={25}
                                keyField="id" />
                        </div>
                    </div>
                </div>
            </div>

            <AddUpdateBannerPopup show={showAddUpdateBanner} bannerImage={bannerImage} setBannerImage={setBannerImage} data={bannerData} onHide={() => setShowAddUpdateBanner(false)} submitForm={submitCreateBannerHandler} bannerTypes={bannerTypes} />
            <DeleteConfirmationPopup show={showDeletePopup} canDelete={canDelete} />
        </>
    )
}

export default Banner;