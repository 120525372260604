import { ActionTypes } from "../constants/actionTypes"

export const setProducts = (products) => {
    return {
        type: ActionTypes.SET_PRODUCTS,
        payload: products
    }
}

export const setBulkProducts = (products) => {
    return {
        type: ActionTypes.SET_BULK_PRODUCTS,
        payload: products
    }
}

export const setCsvId = (csvId) => {
    return {
        type: ActionTypes.SET_CSV_ID,
        payload: csvId
    }
}