import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-hot-toast';
import { addCategory, addSubCategory, deleteCategory, deleteSubCategory, fetchCategories, fetchSubCategories, updateCategory, updateSubCategory } from "../../api/categoriesApi";
import { setLoaderView } from "../../redux/actions/settingActions";
import DeleteConfirmationPopup from "../Common/DeleteConfirmationPopup";
import GridView from "../Common/GridView";
import Header from "../Common/Header";
import AddCategoryPopup from "./AddCategoryPopup";
import AddSubCategoryPopup from "./AddSubCategoryPopup";

const Categories = () => {

    const shop = useSelector((state) => state?.shop);

    const dispatch = useDispatch();

    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [categoryData, setCategoryData] = useState(null);
    const [categoryName, setCategoryName] = useState("");
    const [subCategoryData, setSubCategoryData] = useState(null);
    const [showAddUpdateCategory, setShowAddUpdateCategory] = useState(false);
    const [showAddUpdateSubCategory, setShowAddUpdateSubCategory] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [showDeletePopup, setShowDeletePopup] = useState(false);

    const loadCategories = () => {
        dispatch(setLoaderView(true));
        fetchCategories(shop?.uuid).then(response => {
            //dispatch(setLoaderView(false));
            if (response.status === 200) {
                setCategories(response.data);
                setCategoryData(response.data[0]);
                setCategoryName(response?.data[0]?.name);
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    const loadSubCategories = () => {
        dispatch(setLoaderView(true));
        fetchSubCategories(shop?.uuid, categoryData?.id).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                setSubCategories(response.data);
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    useEffect(() => {
        loadCategories();
    }, []);

    useEffect(() => {
        if (categoryData) {
            loadSubCategories();
        }
    }, [categoryData])

    const categoriesAction = (id, row) => {
        return (<div className="dropdown card-widgets">
            <span className="dropdown-toggle arrow-none cursor-pointer card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="mdi mdi-dots-vertical"></i>
            </span>
            <div className="dropdown-menu dropdown-menu-end" >
                <span className="dropdown-item cursor-pointer" onClick={() => {
                    setCategoryData(row);
                    setShowAddUpdateCategory(true);
                }}><i className="uil-file-edit-alt me-1"></i>Edit</span>
                <span className="dropdown-item cursor-pointer" onClick={() => {
                    setCategoryData(row);
                    setDeleteId(id);
                    setShowDeletePopup(true);
                }}><i className="uil uil-trash-alt me-1"></i>Delete</span>
            </div>
        </div>
        )
    }

    const subCategoriesAction = (id, row) => {
        return (<div className="dropdown card-widgets">
            <span className="dropdown-toggle arrow-none cursor-pointer card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="mdi mdi-dots-vertical"></i>
            </span>
            <div className="dropdown-menu dropdown-menu-end" >
                <span className="dropdown-item cursor-pointer" onClick={() => {
                    setSubCategoryData(row);
                    setShowAddUpdateSubCategory(true);
                }}><i className="uil-file-edit-alt me-1"></i>Edit</span>
                <span className="dropdown-item cursor-pointer" onClick={() => {
                    setSubCategoryData(row);
                    setDeleteId(id);
                    setShowDeletePopup(true);
                }}><i className="uil uil-trash-alt me-1"></i>Delete</span>
            </div>
        </div>
        )
    }

    const formatCategoryName = (cell, row) => {
        return <span onClick={() => {
            setCategoryData(row);
            setCategoryName(cell);
        }} className="text-primary cursor-pointer d-block">{cell}</span>
    }

    const categoriesColumns = [
        {
            dataField: 'name',
            text: 'Name',
            formatter: formatCategoryName
        }, {
            dataField: 'id',
            text: '',
            formatter: categoriesAction
        }
    ];

    const subCategoriesColumns = [
        {
            dataField: 'name',
            text: 'Name',
        }, {
            dataField: 'id',
            text: '',
            formatter: subCategoriesAction
        }
    ];

    const submitAddUpdateCategory = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        if (categoryData) {
            dispatch(setLoaderView(true));
            updateCategory(categoryData?.id, shop?.uuid, formData).then(response => {
                if (response.status === 200) {
                    toast.success(response.message);
                    setShowAddUpdateCategory(false);
                    loadCategories();
                }
            }).catch(error => {
                dispatch(setLoaderView(false));
                if (error.response.data.status === 400) {
                    toast.error(error.response.data.message);
                }
            });
        } else {
            dispatch(setLoaderView(true));
            addCategory(shop?.uuid, formData).then(response => {
                if (response.status === 200) {
                    toast.success(response.message);
                    setShowAddUpdateCategory(false);
                    loadCategories();
                }
            }).catch(error => {
                dispatch(setLoaderView(false));
                if (error.response.data.status === 400) {
                    toast.error(error.response.data.message);
                }
            });
        }
    }

    const submitAddUpdateSubCategory = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        if (subCategoryData) {
            dispatch(setLoaderView(true));
            updateSubCategory(subCategoryData?.id, shop?.uuid, formData).then(response => {
                if (response.status === 200) {
                    toast.success(response.message);
                    setShowAddUpdateSubCategory(false);
                    loadSubCategories();
                }
            }).catch(error => {
                dispatch(setLoaderView(false));
                if (error.response.data.status === 400) {
                    toast.error(error.response.data.message);
                }
            });
        } else {
            dispatch(setLoaderView(true));
            addSubCategory(shop?.uuid, formData).then(response => {
                if (response.status === 200) {
                    toast.success(response.message);
                    setShowAddUpdateSubCategory(false);
                    loadSubCategories();
                }
            }).catch(error => {
                dispatch(setLoaderView(false));
                if (error.response.data.status === 400) {
                    toast.error(error.response.data.message);
                }
            });
        }
    }

    const canDelete = (yesOrNo) => {
        if (yesOrNo) {
            dispatch(setLoaderView(true));
            if (subCategoryData) {
                deleteSubCategory(deleteId, shop?.uuid).then(response => {
                    dispatch(setLoaderView(false));
                    if (response.status === 200) {
                        toast.success(response.message);
                        setSubCategoryData(null);
                        setShowDeletePopup(false);
                        loadSubCategories();
                    }
                }).catch((error) => {
                    setSubCategoryData(null);
                    dispatch(setLoaderView(false));
                    if (error.response.data.status === 400) {
                        toast.error(error.response.data.message);
                    }
                });
            } else {
                deleteCategory(deleteId, shop?.uuid).then(response => {
                    dispatch(setLoaderView(false));
                    if (response.status === 200) {
                        toast.success(response.message);
                        setShowDeletePopup(false);
                        loadCategories();
                    }
                }).catch((error) => {
                    dispatch(setLoaderView(false));
                    if (error.response.data.status === 400) {
                        toast.error(error.response.data.message);
                    }
                });
            }
        } else {
            setSubCategoryData(null);
            setShowDeletePopup(false);
        }
    }

    return (
        <>
            <Header title="Categories & Sub-Categories" />

            <div className="row">
                <div className="col-md-5">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between mb-2">
                                <h4 className="m-0">Categories</h4>
                                <button onClick={() => {
                                    setCategoryData(null);
                                    setShowAddUpdateCategory(true);
                                }} className="btn btn-outline-primary btn-sm">Add Category</button>
                            </div>
                            <GridView
                                data={categories}
                                columns={categoriesColumns}
                                totalSize={categories.length}
                                sizePerPage={25}
                                disablePagination={true}
                                keyField="id" />
                        </div>
                    </div>
                </div>

                <div className="col-md-7">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between mb-2">
                                <h4 className="m-0">Sub-Categories of {categoryName}</h4>
                                <button onClick={() => {
                                    setSubCategoryData(null);
                                    setShowAddUpdateSubCategory(true);
                                }} className="btn btn-outline-primary btn-sm">Add Sub-Category</button>
                            </div>
                            <GridView
                                data={subCategories}
                                columns={subCategoriesColumns}
                                totalSize={subCategories.length}
                                disablePagination={true}
                                sizePerPage={25}
                                keyField="id" />
                        </div>
                    </div>
                </div>
            </div>

            <AddCategoryPopup show={showAddUpdateCategory} data={categoryData} onHide={() => setShowAddUpdateCategory(false)} submitForm={submitAddUpdateCategory} />
            <AddSubCategoryPopup disabled={true} show={showAddUpdateSubCategory} data={subCategoryData} options={categories} onHide={() => setShowAddUpdateSubCategory(false)} selectedCategory={categoryData?.id} submitForm={submitAddUpdateSubCategory} />
            <DeleteConfirmationPopup show={showDeletePopup} canDelete={canDelete} />

        </>
    )
}

export default Categories;