const Slider = ({ banners }) => {

    return (
        <div className="bd-example slider-container my-6">
            <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                <ol className="carousel-indicators">
                    {banners?.map((banner, index) => {
                        return <li key={`indicator-${index}`} data-bs-target="#carouselExampleCaptions" data-bs-slide-to={index} className={`${index === 0 ? 'active' : ''}`}></li>
                    })}
                </ol>
                <div className="carousel-inner">
                    {banners?.map((banner, index) => {
                        return <div key={`banner-${index}`} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                            <img src={banner?.file_url} className="d-block w-100 " alt="..." />
                            <div className="carousel-caption d-none d-md-block">
                                <h3>{banner?.title}</h3>
                                <p>{banner?.description}</p>
                            </div>
                        </div>
                    })}
                </div>
                <a className="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleCaptions" role="button" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </a>
            </div>
        </div>
    )
}

export default Slider;