import Header from "../../Common/Header";
import { fetchShopDetail, updateShopDetails } from "../../../api/settingsApi";
import { useSelector, useDispatch } from "react-redux";
import { setLoaderView, setShopDetail } from "../../../redux/actions/settingActions";
import { useEffect, useState } from "react";
import { toast } from 'react-hot-toast';
import ShopBasicDetails from "./ShopBasicDetails";
import SocialMedia from "./SocialMedia";
import AddressDetails from "./AddressDetails";
import OpenCloseDetails from "./OpenCloseDetails";
import DeliveryDetails from "./DeliveryDetails";
import OrderDetails from "./OrderDetails";
import PolicyDetails from "./PolicyDetails";

const Settings = () => {

    const dispatch = useDispatch();
    const shop = useSelector((state) => state?.shop);

    const [selectedItem, setSelectedItem] = useState(1);

    useEffect(() => {
        dispatch(setLoaderView(true));
        fetchShopDetail(shop?.uuid).then((response) => {
            if (response.status === 200) {
                dispatch(setShopDetail(response.data));
            }
            dispatch(setLoaderView(false));
        }).catch((error) => {
            dispatch(setLoaderView(false));
            toast.error(error.message)
        })
    }, [shop?.uuid]);

    const submitShopDetailsHandler = (event) => {
        event.preventDefault();
        dispatch(setLoaderView(true));
        const formData = new FormData(event.target);
        updateShopDetails(shop?.uuid, formData).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                toast.success(response.message);
                dispatch(setShopDetail(response.data));
            } else if (response.status === 400) {
                toast.error(response.message);
            }
        }).catch((error) => {
            toast.error(error.message);
            dispatch(setLoaderView(false));
        });
    }

    const loadUI = () => {
        return ({
            1: <ShopBasicDetails submitShopDetails={submitShopDetailsHandler} />,
            2: <AddressDetails submitShopDetails={submitShopDetailsHandler} />,
            3: <SocialMedia submitShopDetails={submitShopDetailsHandler} />,
            4: <OpenCloseDetails submitShopDetails={submitShopDetailsHandler} />,
            5: <DeliveryDetails submitShopDetails={submitShopDetailsHandler} />,
            6: <OrderDetails submitShopDetails={submitShopDetailsHandler} />,
            7: <PolicyDetails />
        }
        )[selectedItem];
    }

    return (
        <>
            <Header title="Shop Settings" />

            <div className="row">
                <div className="col-lg-3">
                    <div className="card">
                        <ul className="list-group">
                            <li className={`list-group-item cursor-pointer ${selectedItem === 1 ? 'active' : ''}`} onClick={() => setSelectedItem(1)}>
                                <i className="uil-store me-1"></i>Shop Details
                            </li>
                            <li className={`list-group-item cursor-pointer ${selectedItem === 2 ? 'active' : ''}`} onClick={() => setSelectedItem(2)}>
                                <i className="uil-map-marker me-1"></i>Address
                            </li>
                            <li className={`list-group-item cursor-pointer ${selectedItem === 3 ? 'active' : ''}`} onClick={() => setSelectedItem(3)}>
                                <i className="uil-instagram me-1"></i>Social Media
                            </li>
                            <li className={`list-group-item cursor-pointer ${selectedItem === 4 ? 'active' : ''}`} onClick={() => setSelectedItem(4)}>
                                <i className="uil-clock-eight me-1"></i>Opening / Closing
                            </li>
                            <li className={`list-group-item cursor-pointer ${selectedItem === 5 ? 'active' : ''}`} onClick={() => setSelectedItem(5)}>
                                <i className="uil-truck me-1"></i>Pickup / Delivery
                            </li>
                            <li className={`list-group-item cursor-pointer ${selectedItem === 6 ? 'active' : ''}`} onClick={() => setSelectedItem(6)}>
                                <i className="uil-file-check-alt me-1"></i>Order Details
                            </li>
                            <li className={`list-group-item cursor-pointer ${selectedItem === 7 ? 'active' : ''}`} onClick={() => setSelectedItem(7)}>
                                <i className="uil-clipboard-alt me-1"></i>Policies / T&C
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="card">
                        <div className="card-body">
                            {loadUI()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Settings;