import Header from "../Common/Header";
import { useDispatch, useSelector } from "react-redux";
import ProductCardEdit from "./ProductCardEdit";
import { fetchProductCategories, fetchProductUnits, saveBulkUploadEdit } from "../../api/productsApi";
import { useEffect, useState } from "react";
import { setLoaderView } from "../../redux/actions/settingActions";
import { toast } from 'react-hot-toast';
import { setCsvId } from "../../redux/actions/productActions";

const EditBulkProducts = () => {

    const bulkProducts = useSelector((state) => state?.bulkProducts);
    const shopId = useSelector((state) => state?.shop?.uuid);
    const csvId = useSelector((state) => state?.csvId);

    const dispatch = useDispatch();

    const [productCategories, setProductCategories] = useState([]);
    const [productUnits, setProductUnits] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setLoaderView(true));
        
        fetchProductCategories(shopId).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                setProductCategories(response.data);
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });

        fetchProductUnits().then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                setProductUnits(response.data);
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }, []);


    const getFormJSON = (data) => {
        return Array.from(data.keys()).reduce((result, key) => {
            if (result[key]) {
                result[key] = data.getAll(key)
                return result
            }
            result[key] = data.getAll(key);
            return result;
        }, {});
    };

    const submitBulkEdit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        let aa = getFormJSON(formData);
        var keys = Object.keys(aa);
        var arr = [];
        for (let i = 0; i < aa[keys[0]].length; i++) {
            var obj = {};
            for (let j = 0; j < keys.length; j++) {
                obj[keys[j]] = aa[keys[j]][i];
            }
            arr.push(obj)
        }

        let finalJson = {
            "uploaded_file_id": csvId,
            "product_list": arr
        }

        dispatch(setLoaderView(true));
        saveBulkUploadEdit(shopId, finalJson).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                toast.success(response.message);
                dispatch(setCsvId(null));
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    return (
        <>
            <Header back={true} title="Edit Bulk Products"></Header>

            <div className="card">
                <div className="card-body">
                    <form onSubmit={submitBulkEdit}>
                        {bulkProducts?.map((product, index) => {
                            return <ProductCardEdit
                                key={index}
                                productCategories={productCategories}
                                product={product}
                                index={index}
                                units={productUnits} />
                        })}
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <button type="submit" className="btn btn-primary">Save Products</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default EditBulkProducts;