import Header from "../../Common/Header";
import { createRole, createUser, deleteRole, deleteUser, fetchRoles, fetchUsers, fetchUserShopRoleDetail, updateRole, updateUser } from "../../../api/settingsApi";
import { useSelector, useDispatch } from "react-redux";
import { setLoaderView, setMenu, setRoleDetail, setRoles } from "../../../redux/actions/settingActions";
import { useEffect, useState } from "react";
import Button from "../../Common/Button";
import { toast } from 'react-hot-toast';
import AddUpdateRolePopup from "./AddUpdateRolePopup";
import AddUpdateUserPopup from "./AddUpdateUserPopup";
import GridView from "../../Common/GridView";
import DeleteConfirmationPopup from "../../Common/DeleteConfirmationPopup";
import { setPhoneNo } from "../../../helper/GridFormatters/phoneNoFormatter";
import { Link } from "react-router-dom";

const UserManagement = () => {

    const dispatch = useDispatch();

    const shopId = useSelector((state) => state?.shop?.uuid);
    const roles = useSelector((state) => state?.roles);
    const userPhone = useSelector((state) => state?.user?.phone);

    const [users, setUsers] = useState([]);

    const [userData, setUserData] = useState(null);
    const [roleData, setRoleData] = useState(null);

    const [showAddUpdateUser, setShowAddUpdateUser] = useState(false);
    const [showAddUpdateRole, setShowAddUpdateRole] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);

    const [deleteId, setDeleteId] = useState(null)


    const editRole = (data) => {
        setRoleData(data)
        setShowAddUpdateRole(true);
    }

    const roleAction = (id, row) => {
        return (
            <div className="dropdown card-widgets">
                {row.shop_id_id &&
                    <>
                        <span className="dropdown-toggle arrow-none cursor-pointer card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="mdi mdi-dots-vertical"></i>
                        </span>
                        <div className="dropdown-menu dropdown-menu-end" >
                            <span className="dropdown-item cursor-pointer" onClick={() => editRole(row)}><i className="uil-file-edit-alt me-1"></i>Edit</span>
                            <span className="dropdown-item cursor-pointer" onClick={() => {
                                setRoleData(row);
                                setDeleteId(id);
                                setShowDeletePopup(true);
                            }}><i className="uil uil-trash-alt me-1"></i>Delete</span>
                        </div>
                    </>}
            </div>
        )
    }

    const usersAction = (id, row) => {
        return (<div className="dropdown card-widgets">
            {row.role_name !== "Shop Owner" &&
                <>
                    <span className="dropdown-toggle arrow-none cursor-pointer card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="mdi mdi-dots-vertical"></i>
                    </span>
                    <div className="dropdown-menu dropdown-menu-end" >
                        <span className="dropdown-item cursor-pointer" onClick={() => {
                            setUserData(row);
                            setShowAddUpdateUser(true);
                        }}><i className="uil-file-edit-alt me-1"></i>Edit</span>
                        {(row.phone !== userPhone && row.role_name !== "Shop Owner") &&
                            <span className="dropdown-item cursor-pointer" onClick={() => {
                                setUserData(row);
                                setDeleteId(id);
                                setShowDeletePopup(true);
                            }}><i className="uil uil-trash-alt me-1"></i>Delete</span>
                        }
                    </div>
                </>}
        </div>
        )
    }

    const usersColumns = [
        {
            dataField: 'full_name',
            text: 'User Name'
        }, {
            dataField: 'role_name',
            text: 'Assigned Role'
        }, {
            dataField: 'dob',
            text: 'DOB'
        }, {
            dataField: 'email',
            text: 'Email'
        }, {
            dataField: 'gender',
            text: 'Gender'
        }, {
            dataField: 'phone',
            text: 'Phone No',
            formatter: setPhoneNo
        }, {
            dataField: 'id',
            text: '',
            formatter: usersAction
        }
    ];

    const rolesColumns = [
        {
            dataField: 'name',
            text: 'Role Name'
        }, {
            dataField: 'description',
            text: 'Role Description'
        }, {
            dataField: 'id',
            text: '',
            formatter: roleAction
        }
    ];

    useEffect(() => {
        dispatch(setLoaderView(true));

        fetchRoles(shopId).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                dispatch(setRoles(response.data));
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });

        fetchUsers(shopId).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                setUsers(response.data);
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }, [shopId])


    const submitCreateUserHandler = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        //if (validateForm(formData)) {
        if (userData) {
            dispatch(setLoaderView(true));
            updateUser(userData.id, shopId, formData).then(response => {
                if (response.status === 200) {
                    toast.success(response.message);
                    fetchUsers(shopId).then(response => {
                        dispatch(setLoaderView(false));
                        if (response.status === 200) {
                            setShowAddUpdateUser(false);
                            setUserData(null);
                            setUsers(response.data);
                        }
                    }).catch(error => {
                        dispatch(setLoaderView(false));
                        if (error.response.data.status === 400) {
                            toast.error(error.response.data.message);
                        }
                    });
                }
            }).catch(error => {
                dispatch(setLoaderView(false));
                if (error.response.data.status === 400) {
                    toast.error(error.response.data.message);
                }
            });
        } else {
            dispatch(setLoaderView(true));
            createUser(shopId, formData).then(response => {
                if (response.status === 200) {
                    toast.success(response.message);
                    fetchUsers(shopId).then(response => {
                        dispatch(setLoaderView(false));
                        if (response.status === 200) {
                            setShowAddUpdateUser(false);
                            setUsers(response.data);
                        } else {
                            dispatch(setLoaderView(false));
                            toast.error(response.message);
                        }
                    }).catch(error => {
                        dispatch(setLoaderView(false));
                        if (error.response.data.status === 400) {
                            toast.error(error.response.data.message);
                        }
                    });
                }
            }).catch(error => {
                dispatch(setLoaderView(false));
                if (error.response.data.status === 400) {
                    toast.error(error.response.data.message);
                }
            });
        }
    }

    const submitCreateRoleHandler = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        dispatch(setLoaderView(true));
        if (roleData) {
            updateRole(roleData?.id, shopId, formData).then(response => {
                dispatch(setLoaderView(false));
                if (response.status === 200) {
                    toast.success(response.message);
                    fetchRoles(shopId).then(response => {
                        if (response.status === 200) {
                            setShowAddUpdateRole(false);
                            setRoleData(null);
                            dispatch(setRoles(response.data));
                        }
                    }).catch((error) => {
                        dispatch(setLoaderView(false));
                        if (error.response.data.status === 400) {
                            toast.error(error.response.data.message);
                        }
                    });

                    fetchUserShopRoleDetail(shopId).then((response) => {
                        if (response.status === 200) {
                            dispatch(setRoleDetail(response.data.role_data));
                            dispatch(setMenu(response.data.left_menu_data.menu_list));
                        }
                    }).catch((error) => {
                        if (error.response.data.status === 400) {
                            toast.error(error.response.data.message);
                        }
                    });
                }
            }).catch((error) => {
                dispatch(setLoaderView(false));
                if (error.response.data.status === 400) {
                    toast.error(error.response.data.message);
                }
            });
        } else {
            createRole(shopId, formData).then(response => {
                dispatch(setLoaderView(false));
                if (response.status === 200) {
                    toast.success(response.message);
                    fetchRoles(shopId).then(response => {
                        if (response.status === 200) {
                            setShowAddUpdateRole(false);
                            dispatch(setRoles(response.data));
                        }
                    }).catch((error) => {
                        dispatch(setLoaderView(false));
                        if (error.response.data.status === 400) {
                            toast.error(error.response.data.message);
                        }
                    });
                }
            }).catch((error) => {
                dispatch(setLoaderView(false));
                if (error.response.data.status === 400) {
                    toast.error(error.response.data.message);
                }
            });
        }
    }

    const canDelete = (yesOrNo) => {
        if (yesOrNo) {
            dispatch(setLoaderView(true));

            if (roleData) {
                deleteRole(deleteId, shopId).then(response => {
                    dispatch(setLoaderView(false));
                    if (response.status === 200) {
                        toast.success(response.message);
                        setRoleData(null);
                        setShowDeletePopup(false);
                        fetchRoles(shopId).then(response => {
                            if (response.status === 200) {
                                dispatch(setRoles(response.data));
                            }
                        }).catch((error) => {
                            dispatch(setLoaderView(false));
                            if (error.response.data.status === 400) {
                                toast.error(error.response.data.message);
                            }
                        });
                    }
                }).catch((error) => {
                    setRoleData(null);
                    dispatch(setLoaderView(false));
                    if (error.response.data.status === 400) {
                        toast.error(error.response.data.message);
                    }
                });
            } else if (userData) {
                deleteUser(deleteId, shopId).then(response => {
                    dispatch(setLoaderView(false));
                    if (response.status === 200) {
                        toast.success(response.message);
                        setUserData(null);
                        setShowDeletePopup(false);
                        fetchUsers(shopId).then(response => {
                            if (response.status === 200) {
                                setUsers(response.data);
                            }
                        }).catch((error) => {
                            dispatch(setLoaderView(false));
                            if (error.response.data.status === 400) {
                                toast.error(error.response.data.message);
                            }
                        });
                    }
                }).catch((error) => {
                    setUserData(null);
                    dispatch(setLoaderView(false));
                    if (error.response.data.status === 400) {
                        toast.error(error.response.data.message);
                    }
                });
            }
        } else {
            setUserData(null);
            setRoleData(null);
            setShowDeletePopup(false);
        }
    }

    return (
        <>
            <Header title="User Management" />

            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 ">
                            <ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
                                <li className="nav-item">
                                    <Link to="#users-tab" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0 active">
                                        <span>Users</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="#roles-tab" data-bs-toggle="tab" aria-expanded="true" className="nav-link rounded-0">
                                        <span>Roles</span>
                                    </Link>
                                </li>
                            </ul>

                            <div className="tab-content">
                                <div className="tab-pane show active" id="users-tab">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Button label="Create New User" className="btn btn-outline-primary  float-right mb-3" onClick={() => {
                                                setShowAddUpdateUser(true);
                                                setUserData(null);
                                            }} />
                                        </div>
                                    </div>
                                    <GridView
                                        data={users}
                                        columns={usersColumns}
                                        totalSize={roles.length}
                                        sizePerPage={25}
                                        disablePagination={true}
                                        keyField="id" />
                                </div>

                                <div className="tab-pane" id="roles-tab">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Button label="Create New Role" className="btn btn-outline-primary  float-right mb-3" onClick={() => {
                                                setShowAddUpdateRole(true);
                                                setRoleData(null);
                                            }} />
                                        </div>
                                    </div>
                                    <GridView
                                        data={roles}
                                        columns={rolesColumns}
                                        totalSize={roles.length}
                                        sizePerPage={25}
                                        disablePagination={true}
                                        keyField="id" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AddUpdateUserPopup show={showAddUpdateUser} data={userData} onHide={() => setShowAddUpdateUser(false)} submitForm={submitCreateUserHandler} roles={roles} />
            <AddUpdateRolePopup show={showAddUpdateRole} data={roleData} onHide={() => setShowAddUpdateRole(false)} submitForm={submitCreateRoleHandler} />
            <DeleteConfirmationPopup show={showDeletePopup} canDelete={canDelete} />
        </>
    )
}

export default UserManagement;