import { useState } from "react";
import AddCategoryPopup from "../Categories/AddCategoryPopup";
import Header from "../Common/Header";
import Input from "../Common/Input";
import RadioButton from "../Common/RadioButton";
import SelectWithAddButton from "../Common/SelectWithAddButton";
import ProductVariant from "./ProductVariant";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoaderView } from "../../redux/actions/settingActions";
import { addProduct, addProductCategory, addProductSubCategory, deleteProductImage, fetchProduct, fetchProductCategories, fetchProductSubCategories, fetchProductUnits, fetchProductWithVariants, updateProduct, updateProductVariant } from "../../api/productsApi";
import { toast } from 'react-hot-toast';
import AddSubCategoryPopup from "../Categories/AddSubCategoryPopup";
import Label from "../Common/Label";
import FilePicker from "../Common/FilePicker";
import { useNavigate, useParams } from "react-router-dom";
import Select from "../Common/Select";
import SunEditor from 'suneditor-react';

const AddUpdateProduct = () => {

    const shopId = useSelector((state) => state?.shop?.uuid);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { productId, productType } = useParams();

    const [showAddCategory, setShowAddCategory] = useState(false);
    const [showAddSubCategory, setShowAddSubCategory] = useState(false);
    const [price, setPrice] = useState(null);
    const [hasDiscount, setHasDiscount] = useState(false);
    const [hasVariant, setHasVariant] = useState(false);
    const [discount, setDiscount] = useState(0);
    const [discountedPrice, setDiscountedPrice] = useState(price);
    const [quantity, setQuantity] = useState(1);
    const [unit, setUnit] = useState(null);
    const [unitName, setUnitName] = useState(null);
    const [stock, setStock] = useState(null);
    const [color, setColor] = useState(null);
    const [size, setSize] = useState(null);
    const [gst, setGst] = useState(null);
    const [variantUI, setVariantUI] = useState([]);
    const [productCategories, setProductCategories] = useState([]);
    const [selectedProductCategory, setSelectedProductCategory] = useState(null);
    const [selectedProductSubCategory, setSelectedProductSubCategory] = useState(null);
    const [productSubCategories, setProductSubCategories] = useState([]);
    const [productUnits, setProductUnits] = useState([]);
    const [hasColor, setHasColor] = useState(false);
    const [hasSize, setHasSize] = useState(false);
    const [hasGst, setHasGst] = useState(true);
    const [description, setDescription] = useState("");
    const [productName, setProductName] = useState(null);
    const [brand, setBrand] = useState(null);
    const [productDetails, setProductDetails] = useState([]);
    const [isProduct] = useState(productType === 'p' ? true : false);
    const [variantList, setVariantList] = useState([]);
    const [isUnitEditable, setIsUnitEditable] = useState(false);

    const loadProductCategories = () => {
        fetchProductCategories(shopId).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                setProductCategories(response.data);
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    const loadProductSubCategories = () => {
        fetchProductSubCategories(shopId, selectedProductCategory).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                setProductSubCategories(response.data);
            } else if (response.status === 400) {
                setProductSubCategories([]);
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setLoaderView(true));
        loadProductCategories();
        fetchProductUnits().then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                setProductUnits(response.data);
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }, []);

    useEffect(() => {
        if (selectedProductCategory) {
            loadProductSubCategories();
        }
    }, [selectedProductCategory]);


    const calculateDiscountedPrice = (value) => {
        setDiscount(value)
        setDiscountedPrice(price - ((value / 100) * price))
    }

    const onPriceChange = (value) => {
        setPrice(value)
        setDiscountedPrice(value - ((discount / 100) * value))
    }

    const calculateDiscount = (value) => {
        setDiscountedPrice(value)
        setDiscount(Math.round(((price - value) / price) * 100))
    }

    const submitAddCategory = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        dispatch(setLoaderView(true));
        addProductCategory(shopId, formData).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                toast.success(response.message);
                loadProductCategories();
                setShowAddCategory(false);
                setSelectedProductCategory(response.data.id);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    const submitAddSubCategory = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        dispatch(setLoaderView(true));
        addProductSubCategory(shopId, formData).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                toast.success(response.message);
                loadProductSubCategories();
                setShowAddSubCategory(false);
                setSelectedProductSubCategory(response.data.id);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    const addVariant = () => {
        setVariantUI(variantUI => [...variantUI, <ProductVariant key={document.getElementById('variants-list')?.childElementCount - 1} productName={productName} index={document.getElementById('variants-list')?.childElementCount - 1} />])
    }

    const addUpdateProduct = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.set('is_variant', hasVariant ? 1 : 0);
        formData.set('price_gst_inclusive', hasGst ? 1 : 0);
        formData.append('variant_count', document.getElementById('variants-list')?.childElementCount);
        dispatch(setLoaderView(true));
        if (productId) {
            if (productType === 'v') {
                updateProductVariant(productId, shopId, formData).then(response => {
                    dispatch(setLoaderView(false));
                    if (response.status === 200) {
                        toast.success(response.message);
                        navigate('/products');
                    }
                }).catch(error => {
                    dispatch(setLoaderView(false));
                    if (error.response.data.status === 400) {
                        toast.error(error.response.data.message);
                    }
                });
            } else {
                updateProduct(productId, shopId, formData).then(response => {
                    dispatch(setLoaderView(false));
                    if (response.status === 200) {
                        toast.success(response.message);
                        navigate('/products');
                    }
                }).catch(error => {
                    dispatch(setLoaderView(false));
                    if (error.response.data.status === 400) {
                        toast.error(error.response.data.message);
                    }
                });
            }
        } else {
            addProduct(shopId, formData).then(response => {
                dispatch(setLoaderView(false));
                if (response.status === 200) {
                    toast.success(response.message);
                    navigate('/products');
                }
            }).catch(error => {
                dispatch(setLoaderView(false));
                if (error.response.data.status === 400) {
                    toast.error(error.response.data.message);
                }
            });
        }
    }

    useEffect(() => {
        if (productId) {
            dispatch(setLoaderView(true));
            if (isProduct) {
                fetchProductWithVariants(productId, shopId).then(response => {
                    setProductDetails(response?.data);
                    setProductName(response?.data?.variant_name || response?.data?.name);
                    setBrand(response?.data?.brand);
                    setSelectedProductCategory(response?.data?.category_id);
                    setHasVariant(isProduct ? response?.data?.is_variant : false);
                    setPrice(response?.data?.variant_price);
                    setHasDiscount(response?.data?.variant_discounted_percentage ? true : false);
                    setDiscount(response.data.variant_discounted_percentage);
                    setDiscountedPrice(response.data.variant_discounted_price);
                    setStock(response.data.variant_stock);
                    setHasSize(response?.data?.variant_size ? true : false);
                    setSize(response.data.variant_size);
                    setHasColor(response.data.variant_color ? true : false);
                    setColor(response.data.variant_color);
                    setHasGst(response.data.price_gst_inclusive);
                    setGst(response.data.gst_percent);
                    setDescription(response.data.variant_description);
                    setVariantList(response?.data?.variants_list)
                    dispatch(setLoaderView(false));
                }).catch((error) => {
                    dispatch(setLoaderView(false));
                    toast.error(error.message)
                });
            } else {
                fetchProduct(productId, shopId).then(response => {
                    setProductDetails(response?.data);
                    setProductName(response?.data?.variant_name || response?.data?.name);
                    setBrand(response?.data?.brand);
                    setSelectedProductCategory(response?.data?.category_id);
                    setHasVariant(isProduct ? response?.data?.is_variant : false);
                    setPrice(response?.data?.variant_price);
                    setHasDiscount(response?.data?.variant_discounted_percentage ? true : false);
                    setDiscount(response.data.variant_discounted_percentage);
                    setDiscountedPrice(response.data.variant_discounted_price);
                    setStock(response.data.variant_stock);
                    setHasSize(response?.data?.variant_size ? true : false);
                    setHasGst(response.data.price_gst_inclusive);
                    setGst(response.data.gst_percent);
                    setSize(response.data.variant_size);
                    setHasColor(response.data.variant_color ? true : false);
                    setColor(response.data.variant_color);
                    setDescription(response.data.variant_description);
                    setVariantList(response?.data?.variants_list)
                    dispatch(setLoaderView(false));
                }).catch((error) => {
                    dispatch(setLoaderView(false));
                    toast.error(error.message)
                });
            }
        }
    }, [productId, shopId])

    useEffect(() => {
        if (productUnits && productDetails) {
            setUnitName(productUnits?.find((obj) => {
                return obj?.id === Number(productDetails.product_unit)
            })?.name);
        }
    }, [productUnits, productDetails])

    const removeFile = (imageData) => {
        deleteProductImage(imageData.id, shopId)
    }

    return (
        <>
            <Header back={true} title={(productId ? "Update" : "Add") + " Product"}></Header>

            <div className="card">
                <div className="card-body">
                    <form onSubmit={addUpdateProduct}>
                        <h4 className="header-title mb-3">Product Details</h4>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <Input label="Product Name" value={productName} required={true} onChange={(event) => setProductName(event.target.value)} name="name" type="text" className="form-control" placeholder="Enter product name" />
                            </div>
                            <div className="col-md-3 mb-3">
                                <SelectWithAddButton
                                    onClick={() => setShowAddCategory(true)}
                                    value={selectedProductCategory}
                                    label="Category"
                                    name="category_id"
                                    required={true}
                                    className="form-select"
                                    options={productCategories}
                                    onChange={(event) => {
                                        setProductSubCategories([]);
                                        setSelectedProductCategory(event.target.value);
                                    }} />
                            </div>
                            <div className="col-md-3 mb-3">
                                <SelectWithAddButton
                                    value={selectedProductSubCategory || productDetails?.sub_category_id}
                                    disabled={!productSubCategories.length}
                                    onClick={() => setShowAddSubCategory(true)}
                                    label="Sub-Category"
                                    name="sub_category_id"
                                    className="form-select"
                                    onChange={(event) => {
                                        setSelectedProductSubCategory(event.target.value);
                                    }}
                                    options={productSubCategories} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-2 mb-3">
                                <Input label="Brand" value={brand} required={false} onChange={(event) => setBrand(event.target.value)} name="brand" type="text" className="form-control" placeholder="Enter brand name" />
                            </div>
                            <div className="col-md-2 mb-3">
                                <Select
                                    label="Product Unit"
                                    name="product_unit"
                                    required={true}
                                    className="form-select"
                                    value={unit || productDetails.product_unit}
                                    onChange={(event) => {
                                        setUnitName(productUnits?.find((obj) => {
                                            return obj?.id === Number(event.target.value)
                                        })?.name);
                                        setUnit(productUnits?.find((obj) => {
                                            return obj?.id === Number(event.target.value)
                                        })?.id);
                                    }}
                                    options={productUnits} />
                            </div>

                            {(unitName || productDetails?.product_unit) && <div className="col-md-2 mb-3">
                                <label>Quantity Per Unit <a className="mdi mdi-square-edit-outline cursor-pointer" onClick={() => setIsUnitEditable(!isUnitEditable)}></a></label>
                                <div className="d-flex align-items-center">
                                    <span className="ml-2">per </span>
                                    {isUnitEditable ?
                                        <input className="form-control mx-1 text-center" name="product_unit_quantity" value={quantity} onChange={(event) => setQuantity(event.target.value)} />
                                        :
                                        <>
                                            <span className="m-1">{quantity}</span>
                                            <input type="hidden" name="product_unit_quantity" value={quantity} />
                                        </>
                                    }
                                    <span>{unitName}</span>
                                </div>
                            </div>}

                            <div className="col-md-2 mb-3">
                                <RadioButton label="MRP Inclusive of GST?" label1="Yes" label2="No" id1="haveGstYes" id2="haveGstNo" isYes={hasGst} setIsYes={setHasGst} />
                            </div>

                            {!hasGst && <div className="col-md-2 mb-3">
                                <Input label="GST %" required={true} value={gst} onChange={(event) => setGst(event.target.value)} name="gst_percent" type="number" className="form-control" placeholder="Enter GST %" />
                            </div>}

                            <div className="col-md-2 mb-3">
                                <RadioButton label="Have variants?" label1="Yes" label2="No" name="is_variant" id1="haveVariantsYes" id2="haveVariantsNo" isYes={hasVariant} setIsYes={setHasVariant} />
                            </div>
                        </div>

                        {!hasVariant && <div className="row">
                            <div className="col-md-2 mb-3">
                                <Input label="MRP" name="variant_price" required={true} value={price} onChange={(event) => onPriceChange(event.target.value)} type="number" className="form-control" placeholder="Enter product price" />
                            </div>
                            <div className="col-md-2 mb-3">
                                <RadioButton label="Add discount?" label1="Yes" label2="No" name="isDiscount" id1="isDiscountYes" id2="isDiscountNo" isYes={hasDiscount} setIsYes={setHasDiscount} />
                            </div>
                            {hasDiscount && <>
                                <div className="col-md-2 mb-3">
                                    <Input label="Discount %" required={true} name="variant_discounted_percentage" max={100} value={discount} type="number" onChange={(event) => calculateDiscountedPrice(event.target.value)} className="form-control" placeholder="Enter Discount %" />
                                </div>
                                <div className="col-md-2 mb-3">
                                    <Input label="Discounted Price" required={true} name="variant_discounted_price" value={discountedPrice} onChange={(event) => calculateDiscount(event.target.value)} type="number" className="form-control" placeholder="Enter Discounted Price" />
                                </div>
                                {price === discountedPrice || Number(discount) === 0 ? null :
                                    <div className="col-md-4 mb-3">
                                        <label className="hidden"></label>
                                        <div className="form-control">
                                            <del className="text-danger">₹</del>
                                            <del className="text-danger">{price}</del>
                                            <span className="text-success ml-2">₹</span>
                                            <span className="text-success">{discountedPrice}</span>
                                            <span className="badge badge-secondary-lighten ml-2">{discount}% OFF</span>
                                        </div>
                                    </div>
                                }
                            </>}
                        </div>}

                        {!hasVariant &&
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <Label label="Description" />
                                    <SunEditor height="200" name="variant_description" setContents={description} onChange={setDescription} />
                                </div>
                            </div>
                        }

                        {!hasVariant && <>
                            <Label required={true} label="Product Images" />
                            <FilePicker name="product_images" />

                            <div className="row mt-3">
                                {productDetails.image_list?.map((obj, index) => {
                                    return <div key={`image-${index}`} className="col-md-2">
                                        <div className="card shadow-none border">
                                            <div className="p-2 txt-right">
                                                <span onClick={() => removeFile(obj)} className="btn btn-link p-0 btn btn-sm text-muted col-auto">
                                                    <i className="dripicons-cross"></i>
                                                </span>
                                                <div className="row align-items-center">
                                                    <img height={100} src={obj.image_url} alt={obj?.name} style={{ objectFit: "contain" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </>}

                        {!hasVariant && <div className="row">
                            <div className="col-md-2 mb-3">
                                <Input label="Inventory Stock" required={true} value={stock} onChange={(event) => setStock(event.target.value)} name="variant_stock" type="number" className="form-control" placeholder="Enter stock" />
                            </div>
                            <div className="col-md-2 mb-3">
                                <RadioButton label="Have Size?" label1="Yes" label2="No" id1="haveSizeYes" id2="haveSizeNo" isYes={hasSize} setIsYes={setHasSize} />
                            </div>
                            {hasSize && <div className="col-md-2 mb-3">
                                <Input label="Size" required={true} value={size} onChange={(event) => setSize(event.target.value)} name="variant_size" type="text" className="form-control" placeholder="Enter size" />
                            </div>}
                            <div className="col-md-2 mb-3">
                                <RadioButton label="Have color?" label1="Yes" label2="No" name="hasColor" id1="haveColorYes" id2="haveColorNo" isYes={hasColor} setIsYes={setHasColor} />
                            </div>
                            {hasColor && <div className="col-md-1 mb-3">
                                <Input labelClassName="hidden" required={true} name="variant_color" value={color} onChange={(event => setColor(event.target.value))} type="color" className="form-control" />
                            </div>}
                        </div>}

                        {hasVariant && !isProduct && <>
                            <div className="d-flex justify-content-between align-items-center">
                                <h4 className="header-title mb-3 mt-4">Product Variants</h4>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-3" id="variants-list">
                                    <ProductVariant productName={productName} index={-1} hideCloseButton={true} />
                                    {variantUI}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <button type="button" onClick={addVariant} className="btn btn-sm btn-outline-primary">Add more Variants</button>
                                </div>
                            </div>
                        </>}

                        {hasVariant && isProduct && <>
                            <div className="d-flex justify-content-between align-items-center">
                                <h4 className="header-title mb-3 mt-4">Product Variants</h4>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-3" id="variants-list">
                                    {variantList?.map((productData, index) => {
                                        return <ProductVariant key={`variant-${index}`} productData={productData} productName={productName} index={index - 1} hideCloseButton={true} />
                                    })}
                                    {variantUI}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <button type="button" onClick={addVariant} className="btn btn-sm btn-outline-primary">Add more Variants</button>
                                </div>
                            </div>
                        </>}

                        <div className="row">
                            <div className="col-md-12 text-center">
                                <button type="submit" className="btn btn-primary">{productId ? "Update" : "Save"} Product</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <AddCategoryPopup show={showAddCategory} onHide={() => setShowAddCategory(false)} submitForm={submitAddCategory} />
            <AddSubCategoryPopup show={showAddSubCategory} selectedCategory={selectedProductCategory} options={productCategories} onHide={() => setShowAddSubCategory(false)} submitForm={submitAddSubCategory} />
        </>
    )
}

export default AddUpdateProduct;