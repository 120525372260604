import apiService from "./api";

//Categories
export const fetchProductCategories = async (shopId) => {
    const response = await apiService.get(`products/get_all_product_categories?shop_id=${shopId}`);
    return response.data;
}

export const addProductCategory = async (shopId, formData) => {
    const response = await apiService.post(`products/add_product_category?shop_id=${shopId}`, formData);
    return response.data;
}


//Sub Categories
export const fetchProductSubCategories = async (shopId, categoryId) => {
    const response = await apiService.get(`products/get_all_product_subcategories?shop_id=${shopId}&category_id=${categoryId}`);
    return response.data;
}

export const addProductSubCategory = async (shopId, formData) => {
    const response = await apiService.post(`products/add_product_subcategory?shop_id=${shopId}`, formData);
    return response.data;
}


//Units
export const fetchProductUnits = async () => {
    const response = await apiService.get(`master/get_productUnit_data`);
    return response.data;
}


//Product
export const fetchProducts = async (shopId, groupBy) => {
    const response = await apiService.get(`products/get_all_products?shop_id=${shopId}&group_by=${groupBy}`);
    return response.data;
}

export const fetchProduct = async (productId, shopId) => {
    const response = await apiService.get(`products/get_single_product_grouped/${productId}?shop_id=${shopId}`);
    return response.data;
}

export const fetchProductWithVariants = async (productId, shopId) => {
    const response = await apiService.get(`products/get_single_product_grouped/${productId}?shop_id=${shopId}`);
    return response.data;
}

export const addProduct = async (shopId, formData) => {
    const response = await apiService.post(`products/add_product?shop_id=${shopId}`, formData);
    return response.data;
}

export const updateProduct = async (productId, shopId, formData) => {
    const response = await apiService.put(`products/update_product/${productId}?shop_id=${shopId}`, formData);
    return response.data;
}

export const updateProductVariant = async (productId, shopId, formData) => {
    const response = await apiService.put(`products/update_product_variant/${productId}?shop_id=${shopId}`, formData);
    return response.data;
}

export const deleteProduct = async (productId, shopId) => {
    const response = await apiService.delete(`products/delete_product/${productId}?shop_id=${shopId}`);
    return response.data;
}

export const deleteProductVariant = async (productId, shopId) => {
    const response = await apiService.delete(`products/delete_product_variant/${productId}?shop_id=${shopId}`);
    return response.data;
}

export const deleteProductImage = async (imageId, shopId) => {
    const response = await apiService.delete(`products/delete_product_image/${imageId}?shop_id=${shopId}`);
    return response.data;
}

export const updateProductVisibility = async (productId, shopId) => {
    const response = await apiService.put(`products/toggle_product_visibility/${productId}?shop_id=${shopId}`);
    return response.data;
}


//Bulk Upload Products
export const getBulkCsvFile = async (formData) => {
    const response = await apiService.post(`products/get_sample_csv`, formData);
    return response.data;
}

export const convertCsvToJson = async (shopId, formData) => {
    const response = await apiService.post(`products/parse_bulk_to_json?shop_id=${shopId}`, formData);
    return response.data;
}

export const saveBulkUploadEdit = async (shopId, formData) => {
    const response = await apiService.post(`products/save_bulk_product_json?shop_id=${shopId}`, formData);
    return response.data;
}

export const uploadBulkProductImages = async (shopId, formData) => {
    const response = await apiService.post(`products/add_variant_images_temp?shop_id=${shopId}`, formData);
    return response.data;
}