import apiService from "./api";

export const fetchCustomers = async (shopId) => {
    const response = await apiService.get(`users/get_customer_list?shop_id=${shopId}`);
    return response.data;
}

export const fetchCustomer = async (customerId) => {
    const response = await apiService.get(`users/get_customer_data/${customerId}`);
    return response?.data;
}