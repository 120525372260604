import { ActionTypes } from "../constants/actionTypes"

export const setDashboardDetails = (dashboardDetails) => {
    return {
        type: ActionTypes.SET_DASHBOARD_DETAILS,
        payload: dashboardDetails
    }
}

export const setGstDates = (gstDates) => {
    return {
        type: ActionTypes.SET_GST_DATES,
        payload: gstDates
    }
}