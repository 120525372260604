import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-hot-toast';
import { updateProductVisibility } from "../../api/productsApi";
import { setLoaderView } from "../../redux/actions/settingActions";
import ToggleSwitch from "../Common/ToggleSwitch";
import VariantCard from "./VariantCard";

const ProductCard = ({ product, index, setShowDeletePopup, setDeleteId, setProductData, setVariantData }) => {

    const shop = useSelector((state) => state?.shop);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showVariants, setShowVariants] = useState(false);

    const changeProductVisibility = (id) => {
        updateProductVisibility(id, shop?.uuid).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                toast.success(response.message)
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    const discountProductPrice = (product) => {
        return product.variant_discounted_price && product.variant_discounted_percentage ?
            <div>
                <strong>
                    <span className="text-success">₹</span>
                    <span className="text-success mr-2">{product.variant_discounted_price}</span>
                    <small><del className="text-danger">₹</del></small>
                    <small><del className="text-danger">{product.variant_price}</del></small>
                    <small><span className="badge badge-secondary-lighten ml-2">{product.variant_discounted_percentage}% OFF</span></small>
                </strong>
            </div>
            :
            <div>
                <strong>
                    <span className="text-dark">₹</span>
                    <span className="text-dark">{product.variant_price}</span>
                </strong>
            </div>
    }

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="card">
                    <div className="row">
                        <div className="d-flex col-12">
                            <Link to={`/product/${product.uuid}`} className="d-flex align-items-center ms-3">
                                <img src={product.file_url} className="product-image me-1" alt="..." />
                            </Link>

                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <Link to={`/product/${product.uuid}`} className="h4 text-secondary card-title">{product.variant_list ? product.variant_name : product.name}</Link>
                                            <div className="d-flex align-items-center">
                                                <div className="dropdown ms-2">
                                                    <span className="dropdown-toggle arrow-none cursor-pointer card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="mdi mdi-dots-vertical big-dots-vertical"></i>
                                                    </span>
                                                    <div className="dropdown-menu dropdown-menu-end" >
                                                        <span className="dropdown-item cursor-pointer" onClick={() => {
                                                            navigate(`/update-product/p/${product.uuid}`);
                                                        }}><i className="uil-file-edit-alt me-1"></i>Edit</span>
                                                        <span className="dropdown-item cursor-pointer" onClick={() => {
                                                            navigate(`/update-product/p/${product.uuid}`);
                                                        }}><i className="uil-file-plus-alt me-1"></i>Add Variant</span>
                                                        <span className="dropdown-item cursor-pointer" onClick={() => {
                                                            navigate(`/product/${product.uuid}`);
                                                        }}><i className="uil-file-plus-alt me-1"></i>View Product</span>
                                                        <span className="dropdown-item cursor-pointer" onClick={() => {
                                                            window.open(`${process.env.REACT_APP_APP_URL}/${shop?.slug}/${product.uuid}`, '_blank')
                                                        }}><i className="uil-file-plus-alt me-1"></i>View Product on Shop</span>
                                                        <span className="dropdown-item cursor-pointer" onClick={() => {
                                                            setProductData(product);
                                                            setDeleteId(product.uuid);
                                                            setShowDeletePopup(true);
                                                        }}><i className="uil uil-trash-alt me-1"></i>Delete</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        {discountProductPrice(product)}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 d-flex">
                                        <h4><span className="badge badge-primary-lighten me-2">{product.category_name}</span></h4>
                                        <h4><span className="badge badge-primary-lighten">{product.sub_category_name}</span></h4>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-2">
                                        <span className="m-0">Stock : <strong className="text-primary">{product.variant_stock}</strong></span>
                                    </div>
                                    <div className="col-md-2">
                                        {product.variant_size && <span className="m-0">Size : <strong className="text-primary">{product.variant_size}</strong></span>}
                                    </div>
                                    <div className="col-md-2">
                                        {product.variant_color && <span className="d-flex m-0">Color : <div className="w-auto p-2 border rounded ms-1" style={{ backgroundColor: product.variant_color }} /></span>}
                                    </div>
                                    <div className="col-md-2">
                                        <span className="d-flex m-0">Available : <ToggleSwitch className="ms-1" defaultChecked={product.is_visible} onChange={() => changeProductVisibility(product.uuid)} /></span>
                                    </div>
                                    <div className="col-md-2" />
                                    <div className="col-md-2">
                                        {product.variant_list && <h4 className="m-0">
                                            <span className="badge badge-primary-lighten pt-1 pb-1 ps-2 pe-2 cursor-pointer m-0"
                                                type="button"
                                                onClick={() => setShowVariants(!showVariants)}
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#collapse_${index}`}
                                                aria-expanded="false">{showVariants ? "Hide" : "View"} Variants</span>
                                        </h4>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {product.variant_list && <div id={`collapse_${index}`} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        {product.variant_list.map((variant, index) => {
                            return <VariantCard key={index} variant={variant.data} image={variant.file_url} setShowDeletePopup={setShowDeletePopup} setDeleteId={setDeleteId} setVariantData={setVariantData} />
                        })}
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default ProductCard;