const RadioButton = ({ label, name, id1, id2, label1, label2, isYes, setIsYes }) => {

    return (
        <>
            <label className="form-label">{label}</label>
            <div className="d-flex">
                <div className="form-check">
                    <input type="radio" id={id1} name={name} className="form-check-input cursor-pointer" checked={isYes} onChange={() => setIsYes(true)} />
                    <label className="form-check-label cursor-pointer" htmlFor={id1}>{label1}</label>
                </div>
                <div className="form-check mx-3">
                    <input type="radio" id={id2} name={name} className="form-check-input cursor-pointer" checked={!isYes} onChange={() => setIsYes(false)} />
                    <label className="form-check-label cursor-pointer" htmlFor={id2}>{label2}</label>
                </div>
            </div>
        </>
    )
}

export default RadioButton;