import { useRef } from "react";
import { Modal } from "react-bootstrap";
import Input from "../../Common/Input";
import Label from "../../Common/Label";
import Select from "../../Common/Select";
import TextArea from "../../Common/TextArea";

const AddUpdateBannerPopup = ({ show, bannerImage, setBannerImage, data, onHide, submitForm, bannerTypes }) => {

    const ref = useRef(null);

    return (
        <Modal show={show} backdrop="static" size="lg" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
            <form onSubmit={submitForm} id="bannerForm">
                <Modal.Header>
                    <Modal.Title className="m-0" id="contained-modal-title-vcenter">{data ? 'Update' : 'Add'} Banner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-8 mb-3">
                            <Input label="Banner Title" defaultValue={data?.title} name="title" required={false} type="text" className="form-control" placeholder="Enter banner title" />
                        </div>
                        <div className="col-md-4 mb-3">
                            <Select label="Banner Type" required={true} defaultValue={data?.banner_type_id} name="banner_type" options={bannerTypes} className="form-select" />
                        </div>
                        <div className="col-md-6 mb-3">
                            <TextArea label="Banner Descriprion" required={false} defaultValue={data?.description} name="description" className="form-control" rows={7} placeholder="Enter banner description" />
                        </div>
                        <div className="col-md-6">
                            <Label label="Banner Image" required={true} />
                            {bannerImage ?
                                <img className="banner-image-upload-view cursor-pointer" onClick={() => ref.current.click()} src={bannerImage ? (bannerImage?.target?.files[0] ? URL?.createObjectURL(bannerImage?.target?.files[0]) : "/assets/images/upload_image.jpg") : "/assets/images/upload_image.jpg"} alt="bannerImage" />
                                :
                                <img className="banner-image-upload-view cursor-pointer" onClick={() => ref.current.click()} src={data?.file_url ? data?.file_url : "/assets/images/upload_image.jpg"} alt="bannerImage" />
                            }
                        </div>
                        <input type="file" ref={ref} name="file_name" onChange={setBannerImage} className="form-control d-none" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={onHide} className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-primary">{data ? 'Update' : 'Add'} Banner</button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default AddUpdateBannerPopup;