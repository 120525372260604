import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import InnerImageZoom from 'react-inner-image-zoom';

const ImageSlider = ({ productImages }) => {

    return (
        <>
            {productImages?.length > 0 ?
                <Swiper
                    navigation={true}
                    pagination={true}
                    modules={[Navigation, Pagination]}
                    className="mySwiper">
                    {productImages?.map((productImage) => {
                        return <SwiperSlide>
                            <InnerImageZoom zoomScale={1.25} hideHint src={productImage?.image_url} />
                        </SwiperSlide>
                    })}
                </Swiper>
                :
                <img src="/assets/images/no_product_image.png" alt="" className="w-100" />
            }
        </>
    )
}

export default ImageSlider;