import { useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-hot-toast';
import { setLoaderView, setShopDetail } from "../../../redux/actions/settingActions";
import Header from "../../Common/Header";
import Button from "../../Common/Button";
import Input from "../../Common/Input";
import { updateShopDetails } from "../../../api/settingsApi";

const ShopLogoColor = () => {

    const dispatch = useDispatch();
    const ref = useRef(null);
    const shop = useSelector((state) => state?.shop);
    const [logo, setLogo] = useState(null);

    const submitShopDetailsHandler = (event) => {
        event.preventDefault();
        dispatch(setLoaderView(true));
        const formData = new FormData(event.target);
        if (!logo?.target?.files[0]) {
            formData.set('logo', null)
        }
        updateShopDetails(shop?.uuid, formData).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                toast.success(response.message);
                dispatch(setShopDetail(response.data));
            } else if (response.status === 400) {
                toast.error(response.message);
            }
        }).catch((error) => {
            toast.error(error.message);
            dispatch(setLoaderView(false));
        });
    }

    return (
        <>
            <Header title="Shop Logo & Color" />

            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 ">
                            <form onSubmit={submitShopDetailsHandler}>
                                <div className="row">
                                    <div className="col-md-6 mb-3 text-center align-self-center">
                                        {logo ?
                                            <img className="logo-image-upload-view cursor-pointer" onClick={() => ref.current.click()} src={logo ? URL?.createObjectURL(logo?.target?.files[0]) : "/assets/images/upload_image.jpg"} alt="logo" />
                                            :
                                            <img className="logo-image-upload-view cursor-pointer" onClick={() => ref.current.click()} src={shop?.logo ? shop?.logo : "/assets/images/upload_image.jpg"} alt="logo" />
                                        }
                                    </div>
                                    <input type="file" ref={ref} name="logo" onChange={(event) => {
                                        if (event.target.value) {
                                            setLogo(event);
                                        }
                                    }} className="form-control d-none" />

                                    <div className="col-6 col-md-3 mb-3">
                                        <Input label="Primary Color" name="primary_color" defaultValue={shop?.primary_color} type="color" className="form-control" />
                                    </div>

                                    <div className="col-6 col-md-3 mb-3">
                                        <Input label="Secondary Color" name="secondary_color" defaultValue={shop?.secondary_color} type="color" className="form-control" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-end">
                                        <Button type="submit" className="btn btn-primary" label="Save" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShopLogoColor;