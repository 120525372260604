import { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-hot-toast';
import { fetchProductCategories } from "../../api/productsApi";
import { setLoaderView } from "../../redux/actions/settingActions";
import Select from "../Common/Select";

const SelectCategoryPopup = ({ show, onHide, submitForm }) => {

    const shopId = useSelector((state) => state?.shop?.uuid);

    const dispatch = useDispatch();
    const [productCategories, setProductCategories] = useState([]);

    useEffect(() => {
        fetchProductCategories(shopId).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                setProductCategories(response.data);
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }, []);

    return (
        <Modal show={show} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
            <form onSubmit={submitForm}>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Select Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <Select label="Category" name="category_id" required={true} className="form-select" options={productCategories} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={onHide} className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-primary">Create Category</button>
                </Modal.Footer>
            </form>
        </Modal >
    )
}

export default SelectCategoryPopup;