import { auth } from '../../Configuration/FirebaseConfig';
import { //signInWithPopup, GoogleAuthProvider, 
    RecaptchaVerifier, signInWithPhoneNumber
} from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import { toast } from 'react-hot-toast';
import PhoneInput from "react-phone-input-2";
import OtpInput from "react18-input-otp";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/actions/userActions";
import { setLoaderView, setMenu, setRoleDetail, setShopDetail } from "../../redux/actions/settingActions";
import { authUser } from '../../api/authenticationApi';
import { fetchUserShopRoleDetail } from '../../api/settingsApi';
import { checkFirebaseAuthErrorCodeMessage } from '../../helper/Firebase/errorMessages';

const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loader = useSelector((state) => state?.loader);

    const [phone, setPhone] = useState(null);
    const [otp, setOtp] = useState(null);
    const [otpSent, setOtpSent] = useState(false);
    const [confirmationObj, setConfirmationObj] = useState(null);
    const [countryCode, setCountryCode] = useState(null);
    const [phoneCountryCode, setPhoneCountryCode] = useState(null);

    const generateReCaptcha = () => {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
                'size': 'invisible',
                'callback': (response) => {
                    console.log('Captcha Solved : ', response);
                }
            }, auth);
        }
    }

    const sendOTP = () => {
        if (phone) {
            dispatch(setLoaderView(true));
            generateReCaptcha();
            signInWithPhoneNumber(auth, phoneCountryCode, window.recaptchaVerifier).then((confirmationResult) => {
                toast.success("OTP sent successfully!");
                setConfirmationObj(confirmationResult);
                setOtpSent(true);
                dispatch(setLoaderView(false));
            }).catch((error) => {
                dispatch(setLoaderView(false));
                window.recaptchaVerifier.recaptcha.reset();
                toast.error(checkFirebaseAuthErrorCodeMessage(error.code));
            });
        } else {
            toast.error("Please enter the phone number!")
        }
    }

    const verifyOTP = () => {
        if (otp?.length === 6) {
            dispatch(setLoaderView(true));
            confirmationObj.confirm(otp)
                .then((result) => {
                    authUser(result?._tokenResponse?.idToken, countryCode, phone)
                        .then(response => {
                            if (response.status === 200) {
                                dispatch(setLoaderView(false));
                                dispatch(setUser(response.data))
                                if (response.data.is_new_user) {
                                    navigate("/auth/shop-signup");
                                } else {
                                    if (response.data.shops_data.length === 0) {
                                        navigate("/auth/shop-signup");
                                    } else if (response.data.shops_data.length > 1) {
                                        navigate("/auth/select-shop");
                                    } else {
                                        fetchUserShopRoleDetail(response.data.shops_data[0].uuid).then((response) => {
                                            if (response.status === 200) {
                                                dispatch(setRoleDetail(response.data.role_data));
                                                dispatch(setShopDetail(response.data.shop_data));
                                                dispatch(setMenu(response.data.left_menu_data.menu_list));
                                                dispatch(setUser(response.data.user_data));
                                                navigate("/");
                                            }
                                        }).catch((error) => {
                                            if (error.response.data.status === 400) {
                                                toast.error(error.response.data.message);
                                            }
                                        });
                                    }
                                }
                            }
                        }).catch((error) => {
                            toast.error(checkFirebaseAuthErrorCodeMessage(error.code));
                            dispatch(setLoaderView(false));
                        });
                }).catch((error) => {
                    toast.error(checkFirebaseAuthErrorCodeMessage(error.code));
                    dispatch(setLoaderView(false));
                });
        } else {
            toast.error("Please enter 6 digit OTP!");
        }
    }

    const changePhoneNo = () => {
        setOtpSent(false);
        setPhone(null);
        setOtp(null);
        setPhoneCountryCode(countryCode);
    }

    // const signInWithGoogle = () => {
    //     const provider = new GoogleAuthProvider();
    //     signInWithPopup(auth, provider)
    //         .then((result) => {
    //             // const credential = GoogleAuthProvider.credentialFromResult(result);
    //             // const token = credential.accessToken;
    //             // const user = result.user;
    //             dispatch(setUser(result))
    //             navigate("/");
    //         }).catch((error) => {
    //             // const errorCode = error.code;
    //             // const errorMessage = error.message;
    //             // const email = error.customData.email;
    //             // const credential = GoogleAuthProvider.credentialFromError(error);
    //             toast.error(error.message);
    //         });
    // }

    return (
        <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xxl-4 col-lg-5">
                        <div className="card">
                            <div className="card-header pt-3 pb-3 text-center">
                                <span><img src="/assets/images/logo.svg" alt="" height="72" /></span>
                            </div>
                            <div className="card-body p-3">
                                <div className="m-auto">
                                    <h3 className="text-dark-50 pb-0 fw-bold mb-4">Log In</h3>
                                </div>
                                <div>
                                    <div className="mb-3">
                                        <PhoneInput country="in" disabled={otpSent} value={phoneCountryCode} onChange={(phoneNumber, countryCodeObj) => {
                                            setPhone(phoneNumber?.substring(countryCodeObj?.dialCode?.length));
                                            setCountryCode(`+${countryCodeObj?.dialCode}`);
                                            setPhoneCountryCode(`+${phoneNumber}`);
                                        }} />
                                        {otpSent && <div className="text-muted float-end cursor-pointer" onClick={changePhoneNo}><small>Change Phone No.?</small></div>}
                                    </div>
                                    {otpSent ?
                                        <>
                                            <div className="my-4 px-4 otp-container">
                                                <OtpInput
                                                    value={otp}
                                                    shouldAutoFocus={true}
                                                    onChange={setOtp}
                                                    isInputNum={true}
                                                    inputStyle="form-control"
                                                    numInputs={6} />
                                            </div>
                                            <div className="text-center">
                                                <button className="btn btn-primary w-100" type="button" disabled={loader} onClick={verifyOTP}>
                                                    {loader ? <><span className="spinner-grow spinner-grow-sm me-1" role="status"></span>Verifying OTP</> :
                                                        <>Verify OTP</>}
                                                </button>
                                            </div>
                                        </>
                                        :
                                        <div className="text-center">
                                            <button className="btn btn-primary w-100" type="button" disabled={loader} onClick={sendOTP}>
                                                {loader ? <><span className="spinner-grow spinner-grow-sm me-1" role="status"></span>Sending OTP</> :
                                                    <>Send OTP</>}
                                            </button>
                                        </div>
                                    }
                                </div>
                                {/* <div className="text-center m-auto">
                                    <hr className="text-muted mb-3" />
                                    <ul className="social-list list-inline mt-3 mb-0">
                                        <li className="list-inline-item">
                                            <span><img src="/assets/images/google.png" alt="" height="72" onClick={signInWithGoogle} className='cursor-pointer' /></span>
                                        </li>
                                    </ul>
                                </div> */}

                                <div id="recaptcha-container" className="text-center mb-3 mb-0" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;