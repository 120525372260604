import Label from './Label';

const Select = ({ label, options, disabled, showSelect = true, id, name, selectId = "id", selectLabel = "name", className, labelClassName, required, value, defaultValue, onChange }) => {
    return (
        <>
            <Label labelClassName={labelClassName} label={label} required={required} />
            <select aria-readonly={true} disabled={disabled} className={`cursor-pointer ${className}`} id={id} name={name} value={value} onChange={onChange} defaultValue={defaultValue} required={required}>
                {showSelect && <option value={0}>--Select--</option>}
                {options?.map((obj, index) => {
                    return <option key={index} label={obj[selectLabel]} value={obj[selectId]}>{obj[selectLabel]}</option>
                })}
            </select>
        </>
    )
}

export default Select;