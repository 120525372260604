import Input from "../../Common/Input";
import { fetchCountryCodes, updateShopDetails } from "../../../api/settingsApi";
import { useSelector, useDispatch } from "react-redux";
import { setLoaderView, setShopDetail } from "../../../redux/actions/settingActions";
import { useEffect, useState } from "react";
import Button from "../../Common/Button";
import { toast } from 'react-hot-toast';
import SearchDropDown from "../../Common/SearchDropDown";
import { formatValueLabelCurrency } from "../../../helper/SelectDropDown/valueLabelCurrencyFormatter";
import PhoneInput from "react-phone-input-2";
import Label from "../../Common/Label";

const ShopBasicDetails = () => {

    const dispatch = useDispatch();
    // const ref = useRef(null);
    const shop = useSelector((state) => state?.shop);
    // const [logo, setLogo] = useState(null);
    const [currencyCodes, setCurrencyCodes] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState(shop?.currency_id || 101);

    const [primaryPhone, setPrimaryPhone] = useState(shop?.primary_phone);
    const [primaryCountryCode, setPrimaryCountryCode] = useState(shop?.primary_country_code);
    const [primaryPhoneCountryCode, setPrimaryPhoneCountryCode] = useState(`${shop?.primary_country_code}${shop?.primary_phone}`);

    const [secondaryPhone, setSecondaryPhone] = useState(shop?.secondary_phone);
    const [secondaryCountryCode, setSecondaryCountryCode] = useState(shop?.primary_country_code);
    const [secondaryPhoneCountryCode, setSecondaryPhoneCountryCode] = useState(`${shop?.primary_country_code}${shop?.secondary_phone}`);

    useEffect(() => {
        fetchCountryCodes().then(response => {
            if (response.status === 200) {
                setCurrencyCodes(formatValueLabelCurrency('id', 'abbreviation', 'symbol', response.data));
            }
        });
    }, []);

    useEffect(() => {
        if (shop?.currency_id_id) {
            setSelectedCurrency(shop?.currency_id_id);
        }
    }, [shop])

    const submitShopDetailsHandler = (event) => {
        event.preventDefault();
        dispatch(setLoaderView(true));
        const formData = new FormData(event.target);
        // if (!logo?.target?.files[0]) {
        //     formData.set('logo', null)
        // }
        updateShopDetails(shop?.uuid, formData).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                toast.success(response.message);
                dispatch(setShopDetail(response.data));
            } else if (response.status === 400) {
                toast.error(response.message);
            }
        }).catch((error) => {
            toast.error(error.message);
            dispatch(setLoaderView(false));
        });
    }

    return (
        <>
            <h4 className="header-title mb-3">Shop Details</h4>
            <div className="row">
                <div className="col-md-12">
                    <form onSubmit={submitShopDetailsHandler}>

                        {/* <div className="row">
                            <div className="col-md-6 mb-3 text-center align-self-center">
                                {logo ?
                                    <img className="logo-image-upload-view cursor-pointer" onClick={() => ref.current.click()} src={logo ? URL.createObjectURL(logo.target.files[0]) : "/assets/images/upload_image.jpg"} alt="logo" />
                                    :
                                    <img className="logo-image-upload-view cursor-pointer" onClick={() => ref.current.click()} src={shop?.logo ? shop?.logo : "/assets/images/upload_image.jpg"} alt="logo" />
                                }
                            </div>
                            <input type="file" ref={ref} name="logo" onChange={setLogo} className="form-control d-none" />

                            <div className="col-6 col-md-3 mb-3">
                                <Input label="Primary Color" name="primary_color" defaultValue={shop?.primary_color} type="color" className="form-control" />
                            </div>

                            <div className="col-6 col-md-3 mb-3">
                                <Input label="Secondary Color" name="secondary_color" defaultValue={shop?.secondary_color} type="color" className="form-control" />
                            </div>
                        </div> */}

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <Input label="Shop Name" name="name" key={shop?.name} defaultValue={shop?.name} required={true} type="text" className="form-control" placeholder="Enter shop name" />
                            </div>
                            <div className="col-md-6 mb-3">
                                <Input label="Shop URL" readOnly={true} value={`${process.env.REACT_APP_APP_URL}/${shop?.slug}`} name="shop_url" required={false} type="text" className="form-control" placeholder="Enter shop URL" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3 mb-3">
                                <Input label="GST No." name="gstin_number" defaultValue={shop?.gstin_number} required={false} type="text" className="form-control" placeholder="Enter GST number" />
                            </div>
                            <div className="col-md-3 mb-3">
                                <SearchDropDown
                                    label="Currency"
                                    value={selectedCurrency}
                                    onChange={(event) => {
                                        setSelectedCurrency(event.value);
                                    }}
                                    options={currencyCodes}
                                    name="currency_id"
                                    required={true}
                                    className="form-control" />
                            </div>
                            <div className="col-md-6 mb-3">
                                <Input label="Email ID" name="email" key={shop?.email} defaultValue={shop?.email} required={true} type="email" className="form-control" placeholder="Enter email id" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                {/* <Input label="Primary Contact No" key={shop?.primary_phone} defaultValue={shop?.primary_phone} name="primary_phone" required={true} type="number" className="form-control" placeholder="Enter primary contact no" /> */}
                                <Label label="Primary Contact No." required={true} />
                                <PhoneInput country="in" value={primaryPhoneCountryCode} onChange={(phoneNumber, countryCodeObj) => {
                                    setPrimaryPhone(phoneNumber?.substring(countryCodeObj?.dialCode?.length));
                                    setPrimaryCountryCode(`+${countryCodeObj?.dialCode}`);
                                    setPrimaryPhoneCountryCode(`+${phoneNumber}`);
                                }} />
                                <input type="hidden" name="primary_phone" value={primaryPhone} />
                                <input type="hidden" name="primary_country_code" value={primaryCountryCode} />
                            </div>
                            <div className="col-md-6 mb-3">
                                {/* <Input label="Secondary Contact No" key={shop?.secondary_phone} defaultValue={shop?.secondary_phone} name="secondary_phone" required={false} type="number" className="form-control" placeholder="Enter secondary contact no" /> */}
                                <Label label="Secondary Contact No."/>
                                <PhoneInput country="in" value={secondaryPhoneCountryCode} onChange={(phoneNumber, countryCodeObj) => {
                                    setSecondaryPhone(phoneNumber?.substring(countryCodeObj?.dialCode?.length));
                                    setSecondaryCountryCode(`+${countryCodeObj?.dialCode}`);
                                    setSecondaryPhoneCountryCode(`+${phoneNumber}`);
                                }} />
                                <input type="hidden" name="secondary_phone" value={secondaryPhone} />
                                <input type="hidden" name="secondary_country_code" value={secondaryCountryCode} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 text-end">
                                <Button type="submit" className="btn btn-primary" label="Save Details" />
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}

export default ShopBasicDetails;