import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { logoutUser, setUser } from "../../redux/actions/userActions";
import { fetchRoles, fetchShopDetail, fetchUserShopRoleDetail, updateShopStatus } from '../../api/settingsApi';
import { toast } from 'react-hot-toast';
import { setLoaderView, setMenu, setRoleDetail, setRoles, setShopDetail, setShowMyProfilePopup } from '../../redux/actions/settingActions';
import { useEffect } from 'react';
import { useState } from 'react';
import PendingOrders from './PendingOrders';
import { fetchPendingOrders } from '../../api/ordersApi';
import { setPendingOrdersList } from '../../redux/actions/orderActions';

const TopBar = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state?.user);
    const role = useSelector((state) => state?.role);
    const shop = useSelector((state) => state?.shop);
    const [isShopOpen, setIsShopOpen] = useState(shop?.is_open);

    useEffect(() => {
        dispatch(setLoaderView(true));

        fetchShopDetail(shop?.uuid).then((response) => {
            if (response.status === 200) {
                dispatch(setShopDetail(response.data));
                setIsShopOpen(response?.data?.is_open);
            }
            dispatch(setLoaderView(false));
        }).catch((error) => {
            dispatch(setLoaderView(false));
            toast.error(error.message);
        });

        fetchRoles(shop?.uuid).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                dispatch(setRoles(response.data));
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });

        fetchPendingOrders(shop?.uuid).then((response) => {
            if (response.status === 200) {
                dispatch(setPendingOrdersList(JSON.parse(response.data)))
            }
            dispatch(setLoaderView(false));
        }).catch((error) => {
            dispatch(setLoaderView(false));
            toast.error(error.message);
        });

        fetchUserShopRoleDetail(shop?.uuid).then((response) => {
            if (response.status === 200) {
                dispatch(setMenu(response.data.left_menu_data.menu_list));
                dispatch(setRoleDetail(response.data.role_data));
                dispatch(setUser(response.data.user_data));
            }
        }).catch((error) => {
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }, []);

    const logOutUser = () => {
        dispatch(logoutUser());
        navigate("/auth/login");
    }

    const updateShopOpenCloseStatus = (event) => {
        updateShopStatus(shop?.uuid).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                toast.success(response.message);
                setIsShopOpen(!event.target.checked);
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    return (
        <>
            <div className="navbar-custom d-flex justify-content-between">
                <div>
                    <button className="button-menu-mobile open-left">
                        <i className="mdi mdi-menu"></i>
                    </button>
                    <h2 className='shop-title'>{shop?.name}</h2>
                </div>

                <div className='d-flex float-right'>
                    <div className="form-check form-switch align-self-center">
                        <input type="checkbox" checked={isShopOpen} onChange={updateShopOpenCloseStatus} id="is_shop_open" data-switch="success" name="is_shop_open" className="form-control" />
                        <label htmlFor="is_shop_open" id="shop_open_close" className="mt-1" data-on-label="Open" data-off-label="Closed"></label>
                    </div>
                    <ul className="list-unstyled topbar-menu float-end mb-0">
                        <li className="dropdown notification-list">
                            <Link className="nav-link dropdown-toggle nav-user arrow-none me-0" data-bs-toggle="dropdown" to="#" role="button" aria-haspopup="false"
                                aria-expanded="false">
                                <span className="account-user-avatar">
                                    <img src={user?.photoURL || "/assets/images/users/user.png"} alt="" className="rounded-circle" />
                                </span>
                                <span>
                                    <span className="account-user-name">{user?.full_name || user?.phone}</span>
                                    <span className="account-position">{role?.name}</span>
                                </span>
                            </Link>
                            <div className="mt-1 dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
                                <div onClick={() => {
                                    dispatch(setShowMyProfilePopup(true));
                                }} className="dropdown-item notify-item cursor-pointer">
                                    <i className="mdi mdi-account-circle me-1"></i>
                                    <span>My Profile</span>
                                </div>
                                <Link to="/shop-details" className="dropdown-item notify-item">
                                    <i className="mdi mdi-account-circle me-1"></i>
                                    <span>Settings</span>
                                </Link>
                                <button onClick={logOutUser} className="dropdown-item notify-item">
                                    <i className="mdi mdi-logout me-1"></i>
                                    <span>Logout</span>
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <PendingOrders />
        </>
    )
}

export default TopBar