import apiService from "./api";

//Categories
export const fetchCategories = async (shopId) => {
    const response = await apiService.get(`products/get_all_product_categories?shop_id=${shopId}`);
    return response.data;
}

export const addCategory = async (shopId, formData) => {
    const response = await apiService.post(`products/add_product_category?shop_id=${shopId}`, formData);
    return response?.data;
}

export const updateCategory = async (categoryId, shopId, formData) => {
    const response = await apiService.put(`products/update_product_category/${categoryId}?shop_id=${shopId}`, formData);
    return response?.data;
}

export const deleteCategory = async (categoryId, shopId) => {
    const response = await apiService.delete(`products/delete_product_category/${categoryId}?shop_id=${shopId}`);
    return response?.data;
}


//SubCategories
export const fetchSubCategories = async (shopId, categoryId) => {
    const response = await apiService.get(`products/get_all_product_subcategories?shop_id=${shopId}&category_id=${categoryId}`);
    return response.data;
}

export const addSubCategory = async (shopId, formData) => {
    const response = await apiService.post(`products/add_product_subcategory?shop_id=${shopId}`, formData);
    return response?.data;
}

export const updateSubCategory = async (subCategoryId, shopId, formData) => {
    const response = await apiService.put(`products/update_product_subcategory/${subCategoryId}?shop_id=${shopId}`, formData);
    return response?.data;
}

export const deleteSubCategory = async (subCategoryId, shopId) => {
    const response = await apiService.delete(`products/delete_product_subcategory/${subCategoryId}?shop_id=${shopId}`);
    return response?.data;
}