import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setLoaderView } from "../../redux/actions/settingActions";
import { fetchProduct } from "../../api/productsApi";
import { toast } from 'react-hot-toast';
import Header from "../Common/Header";
import parse from "html-react-parser";
import ImageSlider from "./ImageSlider";

const ProductDetails = () => {

    const shopId = useSelector((state) => state?.shop?.uuid);
    const { productId } = useParams();
    const dispatch = useDispatch();

    const [productDetails, setProductDetails] = useState([]);

    useEffect(() => {
        if (productId) {
            dispatch(setLoaderView(true));
            fetchProduct(productId, shopId).then(response => {
                setProductDetails(response.data);
                dispatch(setLoaderView(false));
            }).catch((error) => {
                dispatch(setLoaderView(false));
                toast.error(error.message)
            });
        }
    }, [productId, shopId]);

    return (
        <>
            <Header title="Product Details" back={true} />

            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-5">
                            {/* <div className="text-center d-block mb-4">
                                <img src={productDetails?.image_list && productDetails?.image_list[0]?.image_url} className="img-fluid" style={{ maxWidth: '280px' }} alt="Product-img" />
                            </div>
                            <div className="d-lg-flex d-none justify-content-center">
                                {productDetails?.image_list?.map((obj) => {
                                    return <div className="ms-2 cursor-pointer">
                                        <img src={obj?.image_url} className="img-fluid img-thumbnail p-2" style={{ maxWidth: "75px" }} alt="Product-img" />
                                    </div>
                                })}
                            </div> */}

                            <ImageSlider productImages={productDetails?.image_list}/>
                        </div>
                        <div className="col-lg-7">
                            <div className="ps-lg-4">
                                <h3 className="mt-0">{productDetails?.variant_name}</h3>
                                <div className="mt-3">
                                    <h4>
                                        {productDetails?.stock > 0 ?
                                            <span className="badge badge-success-lighten">In Stock</span>
                                            :
                                            <span className="badge badge-danger-lighten">Out of Stock</span>
                                        }
                                    </h4>
                                </div>

                                <div className="mt-4">
                                    <h6 className="font-14">Retail Price:</h6>
                                    <h3> ₹{productDetails.variant_price}</h3>
                                </div>

                                <div className="mt-4">
                                    <h6 className="font-14">Quantity</h6>
                                    <h4> {productDetails.variant_stock}</h4>
                                </div>

                                <div className="mt-4">
                                    <h6 className="font-14">Description:</h6>
                                    {parse(productDetails?.variant_description || "")}
                                </div>

                                <div className="mt-4">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <h6 className="font-14">Available Stock:</h6>
                                            <p className="text-sm lh-150">1784</p>
                                        </div>
                                        <div className="col-md-4">
                                            <h6 className="font-14">Number of Orders:</h6>
                                            <p className="text-sm lh-150">5,458</p>
                                        </div>
                                        <div className="col-md-4">
                                            <h6 className="font-14">Revenue:</h6>
                                            <p className="text-sm lh-150">$8,57,014</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ProductDetails;