import Button from "../../Common/Button";
import AddressPicker from "../../Common/AddressPicker";
import { useSelector } from "react-redux";

const AddressDetails = ({ submitShopDetails }) => {

    const shop = useSelector((state) => state?.shop);

    return (
        <>
            <h4 className="header-title mb-3">Shop Address</h4>
            <div className="row">
                <div className="col-md-12 ">
                    <form onSubmit={submitShopDetails}>
                        <AddressPicker latitude={Number(shop.latitude)} longitude={Number(shop.longitude)} />
                        <div className="row">
                            <div className="col-md-12 text-end">
                                <Button type="submit" className="btn btn-primary" label="Save Details" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddressDetails;