import Input from "../Common/Input";
import { fetchCountryCodes, fetchShopCategories, submitShopDetails } from "../../api/settingsApi";
import { useDispatch, useSelector } from "react-redux";
import { setLoaderView, setShopDetail } from "../../redux/actions/settingActions";
import Button from "../Common/Button";
import { toast } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import AddressPicker from "../Common/AddressPicker";
import { formatValueLabelCurrency } from "../../helper/SelectDropDown/valueLabelCurrencyFormatter";
import { formatValueLabel } from "../../helper/SelectDropDown/valueLabelFormatter";
import SearchDropDown from "../Common/SearchDropDown";
import { useEffect } from "react";
import { useState } from "react";
import Geocode from "react-geocode";

const ShopSignUp = () => {

    const shop = useSelector((state) => state?.shop);
    const user = useSelector((state) => state?.user);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currencyCodes, setCurrencyCodes] = useState([]);
    const [shopCategories, setShopCategories] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState(shop?.currency_id_id || 101);
    const [selectedShopCategories, setSelectedShopCategories] = useState(null);

    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);

    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);

    useEffect(() => {

        const getLocation = () => {
            if (navigator?.geolocation) {
                navigator?.geolocation?.getCurrentPosition((position) => {
                    setLatitude(Number(position.coords.latitude));
                    setLongitude(Number(position.coords.longitude));
                    console.log("Location fetched => ", position.coords.latitude, position.coords.longitude)
                });
            } else {
                toast.error("Geolocation is not supported by this browser.");
            }
        }

        getLocation();

        fetchCountryCodes().then(response => {
            if (response.status === 200) {
                setCurrencyCodes(formatValueLabelCurrency('id', 'abbreviation', 'symbol', response.data));
            }
        });
        fetchShopCategories().then(response => {
            if (response.status === 200) {
                setShopCategories(formatValueLabel('id', 'name', response.data));
            }
        })

    }, [dispatch]);


    const submitShopSignUpHandler = (event) => {
        event.preventDefault();
        dispatch(setLoaderView(true));
        const formData = new FormData(event.target);
        submitShopDetails(formData).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                dispatch(setShopDetail(response.data));
                toast.success(response.message);
                navigate("/");
            }
        }).catch((error) => {
            toast.error(error.message);
            dispatch(setLoaderView(false));
        });
    }

    return (
        <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="card">
                            <div className="card-header pt-3 pb-3 text-center">
                                <span><img src="/assets/images/logo.svg" alt="" height="72" /></span>
                            </div>
                            <div className="card-body p-4">
                                <div>
                                    <form onSubmit={submitShopSignUpHandler}>
                                        <Input type="hidden" name="phone" value={user?.phone} />
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <Input label="Shop Name" defaultValue={shop?.name} name="name" required={true} type="text" className="form-control" placeholder="Enter shop name" />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <SearchDropDown
                                                    label="Shop Category"
                                                    value={selectedShopCategories}
                                                    onChange={(event) => {
                                                        setSelectedShopCategories(event.value);
                                                    }}
                                                    options={shopCategories}
                                                    name="category_id"
                                                    required={true}
                                                    className="form-control" />
                                            </div>

                                            <div className="col-6 col-md-3 mb-3">
                                                <Input label="Opening Hours" key={shop?.opening_hours} defaultValue={shop?.opening_hours ? shop?.opening_hours : "09:00"} name="opening_hours" required={true} type="time" className="form-control" placeholder="Enter shop opening hours" />
                                            </div>
                                            <div className="col-6 col-md-3 mb-3">
                                                <Input label="Closing Hours" key={shop?.closing_hours} defaultValue={shop?.closing_hours ? shop?.closing_hours : "21:00"} name="closing_hours" required={true} type="time" className="form-control" placeholder="Enter shop closing hours" />
                                            </div>

                                            <div className="col-6 col-md-3 mb-3">
                                                <SearchDropDown
                                                    label="Currency"
                                                    value={selectedCurrency}
                                                    onChange={(event) => {
                                                        setSelectedCurrency(event.value);
                                                    }}
                                                    options={currencyCodes}
                                                    name="currency_id"
                                                    required={true}
                                                    className="form-control" />
                                            </div>
                                        </div>

                                        <AddressPicker latitude={latitude} longitude={longitude} />

                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <Button type="submit" className="btn btn-primary" label="Continue" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShopSignUp;