import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-hot-toast';
import { acceptOrder, fetchOrder, fetchOrders, fetchPendingOrders, rejectOrder } from "../../api/ordersApi";
import { setLoaderView, setShowOrderDetailsPopup, setShowOrderDetailsPopupButtons } from "../../redux/actions/settingActions";
import CurrencyView from "../Common/CurrencyView";
import Header from "../Common/Header";
import { dateFormat, timeFormat } from '../../helper/DateTime/dateTimeFormatter';
import { setOrder, setPendingOrdersList } from "../../redux/actions/orderActions";
import { Link } from "react-router-dom";
import GridView from "../Common/GridView";
import { useState } from "react";

const Orders = () => {

    const pendingOrdersList = useSelector((state) => state?.pendingOrdersList);

    const shop = useSelector((state) => state?.shop);

    const [orders, setOrders] = useState([]);
    const [totalSize, setTotalSize] = useState(0);

    const dispatch = useDispatch();

    const loadOrders = (pageSize, pageCount) => {
        dispatch(setLoaderView(true));
        fetchOrders(shop?.uuid, pageSize, pageCount).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                setOrders(response.data);
                setTotalSize(response.total_count);
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    useEffect(() => {
        loadOrders(25, 1);
    }, [pendingOrdersList]);

    const showPendingOrderDetails = (pendingOrderId) => {
        fetchOrder(pendingOrderId).then(response => {
            if (response.status === 200) {
                dispatch(setShowOrderDetailsPopupButtons((response.data.order_accept_status === false && response.data.order_reject_status === false) ? true : false));
                dispatch(setShowOrderDetailsPopup(true));
                dispatch(setOrder(response.data));
            }
        }).catch((error) => {
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    const fetchPendingOrdersList = () => {
        fetchPendingOrders(shop?.uuid).then((response) => {
            if (response.status === 200) {
                dispatch(setPendingOrdersList(response.data));
            }
            dispatch(setLoaderView(false));
        }).catch((error) => {
            dispatch(setLoaderView(false));
            toast.error(error.message);
        });
    }

    const acceptRejectOrder = (orderStatus, orderDetail) => {
        dispatch(setLoaderView(true));
        if (orderStatus) {
            acceptOrder(orderDetail?.uuid, shop?.uuid).then((response) => {
                if (response.status === 200) {
                    dispatch(setShowOrderDetailsPopup(false));
                    dispatch(setShowOrderDetailsPopupButtons(false));
                    toast.success(response.message);
                    fetchPendingOrdersList();
                    dispatch(setOrder(null));
                }
                dispatch(setLoaderView(false));
            }).catch((error) => {
                dispatch(setLoaderView(false));
                toast.error(error.message);
            });
        } else {
            rejectOrder(orderDetail?.uuid, shop?.uuid).then((response) => {
                if (response.status === 200) {
                    dispatch(setShowOrderDetailsPopup(false));
                    dispatch(setShowOrderDetailsPopupButtons(false));
                    toast.success(response.message);
                    fetchPendingOrdersList();
                    dispatch(setOrder(null));
                }
                dispatch(setLoaderView(false));
            }).catch((error) => {
                dispatch(setLoaderView(false));
                toast.error(error.message);
            });
        }
    }

    const orderId = (cell, row) => {
        // return <span className="cursor-pointer text-primary" onClick={() => showPendingOrderDetails(row.uuid)}>{cell}</span>
        return <Link to={`/order/${row.uuid}`}>{cell}</Link>
    }

    const action = (id, row) => {
        return (
            <div className="dropdown card-widgets">
                <span className="dropdown-toggle arrow-none cursor-pointer card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="mdi mdi-dots-vertical"></i>
                </span>
                <div className="dropdown-menu dropdown-menu-end" >
                    <span className="dropdown-item cursor-pointer" onClick={() => showPendingOrderDetails(id)}><i className="mdi mdi-eye-outline me-1"></i>View</span>
                    {(row.order_accept_status === false && row.order_reject_status === false) && <>
                        <span className="dropdown-item cursor-pointer" onClick={() => acceptRejectOrder(true, row)}><i className="mdi mdi-check me-1"></i>Accept</span>
                        <span className="dropdown-item cursor-pointer" onClick={() => acceptRejectOrder(false, row)}><i className="mdi mdi-close me-1"></i>Reject</span>
                    </>}
                </div>
            </div>
        )
    }

    const orderItems = (cell) => {
        return cell?.length
    }

    const address = (cell, row) => {
        return <span title={`${row.building_number} ${row.locality} ${row.city_name} ${row.state_name} ${row.country_name} ${row.pincode}`}>{`${row.building_number} ${row.locality} - ${row.pincode}`}</span>
    }

    const totalAmount = (cell, row) => {
        return <b className="float-end"><CurrencyView value={cell} /></b>
    }

    const orderStatusIcon = (cell, row) => {
        let status = row.order_status;
        if (status === 'Accepted') {
            return <i title={status} className="uil-check-circle text-success font-20" />
        } else if (status === 'Rejected') {
            return <i title={status} className="uil-times-circle text-danger font-20" />
        } else if (status === 'Pending') {
            return <i title={status} className=" uil-info-circle text-warning font-20 animate__animated animate__flash animate__infinite infinite" />
        }
    }

    const ordersColumns = [
        {
            dataField: 'id',
            text: '',
            formatter: orderStatusIcon
        }, {
            dataField: 'order_id',
            text: 'Order Id',
            formatter: orderId
        }, {
            dataField: 'order_status',
            headerAlign: 'center',
            classes: 'text-center',
            text: 'Status'
        }, {
            dataField: 'item_list',
            text: 'Products',
            headerAlign: 'center',
            classes: 'text-center',
            formatter: orderItems
        }, {
            dataField: 'address_id_id',
            text: 'Address',
            formatter: address
        }, {
            dataField: 'created_on',
            text: 'Order Date & Time',
            formatter: (cell) => `${dateFormat(cell)} - ${timeFormat(cell)}`
        }, {
            dataField: 'total_amount',
            text: 'Amount',
            headerAlign: 'right',
            formatter: totalAmount
        }, {
            dataField: 'uuid',
            text: '',
            formatter: action
        }
    ];

    return (
        <>
            <Header title="Orders" />

            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 ">
                            <GridView
                                keyField="id"
                                data={orders}
                                fetchData={loadOrders}
                                sizePerPage={25}
                                totalSize={totalSize}
                                hideSizePerPage={false}
                                columns={ordersColumns} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Orders;