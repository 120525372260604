import { fetchCustomFormFields } from "../../../api/settingsApi";
import { useSelector } from "react-redux";
import Button from "../../Common/Button";
import RadioButton from "../../Common/RadioButton";
import { useState } from "react";
import Input from "../../Common/Input";
import { useEffect } from "react";

const OrderDetails = ({ submitShopDetails }) => {

    const shop = useSelector((state) => state?.shop);

    const [autoAcceptEnabled, setAutoAcceptEnabled] = useState(shop?.auto_accept_orders, false);
    const [newOrderAlarmEnabled, setNewOrderAlarmEnabled] = useState(shop?.new_orders_alarm, false);
    const [guestCheckoutEnabled, setGuestCheckoutEnabled] = useState(shop?.guest_checkout, false);
    const [customFormFields, setCustomFormFields] = useState([]);

    useEffect(() => {
        fetchCustomFormFields(shop?.uuid).then(response => {
            if (response.status === 200) {
                setCustomFormFields(response.data);
            }
        });
    }, [shop]);

    return (
        <>
            <h4 className="header-title mb-3">Order</h4>
            <div className="row">
                <div className="col-md-12 ">
                    <form onSubmit={submitShopDetails}>

                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <RadioButton label="Auto Accept Orders?" label1="Yes" label2="No" id1="autoAcceptYes" id2="autoAcceptNo" isYes={autoAcceptEnabled} setIsYes={setAutoAcceptEnabled} />
                                <input type="hidden" name="auto_accept_orders" value={autoAcceptEnabled} />
                            </div>
                            <div className="col-md-4 mb-3">
                                <RadioButton label="New Order Alarm?" label1="Yes" label2="No" id1="orderAlarmYes" id2="orderAlarmNo" isYes={newOrderAlarmEnabled} setIsYes={setNewOrderAlarmEnabled} />
                                <input type="hidden" name="new_orders_alarm" value={newOrderAlarmEnabled} />
                            </div>
                            <div className="col-md-4 mb-3">
                                <RadioButton label="Guest Checkout?" label1="Yes" label2="No" id1="guestCheckoutYes" id2="guestCheckoutNo" isYes={guestCheckoutEnabled} setIsYes={setGuestCheckoutEnabled} />
                                <input type="hidden" name="guest_checkout" value={guestCheckoutEnabled} />
                            </div>
                        </div>

                        {guestCheckoutEnabled && <div className="border rounded p-3">
                            <h4 className="header-title">Guest Checkout Form</h4>
                            <div className="row">
                                {customFormFields?.map((formField, index) => {
                                    return <div key={index} className="col-md-6 mt-3">
                                        <Input name={formField?.field_name} readOnly={true} label={formField?.field_name} type={formField?.field_type} className="form-control" placeholder={formField?.placeholder} />
                                    </div>
                                })}
                            </div>
                        </div>}

                        <div className="row">
                            <div className="col-md-12 text-end">
                                <Button type="submit" className="btn btn-primary" label="Save Details" />
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}

export default OrderDetails;