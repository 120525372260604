import { useState } from "react";
import { useEffect } from "react"

const OrderTrackingBar = ({ orderStatus = {} }) => {

    const [allStatus, setAllStatus] = useState([]);
    const [completionPercentage, setCompletionPercentage] = useState(0);

    useEffect(() => {
        if (orderStatus) {
            setCompletionPercentage(orderStatus?.order_completion_percent);
            delete orderStatus?.order_completion_percent;
            let keys = Object?.keys(orderStatus);
            let arr = [];
            keys.forEach((key) => {
                arr.push({ ...orderStatus[key], 'name': key })
            })
            setAllStatus(arr);
        }
    }, [orderStatus]);

    return (
        <div className="row justify-content-center">
            <div className="col-lg-7 col-md-10 col-sm-11">
                <div className="horizontal-steps mb-4">
                    <div className="horizontal-steps-content">
                        {allStatus?.map((status, index) => {
                            return <div key={index} className={`step-item ${status?.is_complete ? 'bg-success' : 'bg-warning'}`}>
                                <span>{status.name}</span>
                            </div>
                        })}
                    </div>
                    <div className="process-line bg-success" style={{ width: `${completionPercentage}%` }}></div>
                </div>
            </div>
        </div>
    )
}

export default OrderTrackingBar;