import { useSelector } from "react-redux";

export const CheckPermission = (permissionName) => {

    const permissions = useSelector((state) => state?.role?.permissions_list);

    const permission = permissions?.find((permission) => {
        return permission?.permission_name === permissionName
    })

    return permission?.permission_granted && permission?.plan_status;
}