import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-hot-toast';
import { fetchOrders } from "../../api/ordersApi";
import { setLoaderView } from "../../redux/actions/settingActions";
import Header from "../Common/Header";
import ToolCard from "./ToolCard";
import { ToolsConfig } from '../../Configuration/ToolsConfig';

const Tools = () => {

    const shop = useSelector((state) => state?.shop);
    const dispatch = useDispatch();

    const [tools, setTools] = useState([]);

    useEffect(() => {
        fetchOrders(shop?.uuid).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                setTools(response.data);
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }, [shop]);

    return (
        <>
            <Header title="Tools" />

            <div className="card">
                <div className="card-body">
                    <div className="row">
                        {ToolsConfig?.map((tool, index) => {
                            return <ToolCard key={index} data={tool} />
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Tools;