import { Modal } from "react-bootstrap";
import Input from "../Common/Input";
import Select from "../Common/Select";

const AddSubCategoryPopup = ({ show, onHide, data, disabled, submitForm, options, selectedCategory }) => {

    return (
        <Modal show={show} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
            <form onSubmit={submitForm}>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">{data ? 'Update' : 'Add'} Sub-Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <Select label="Category" disabled={disabled} defaultValue={selectedCategory || data?.category_id_id} name="category_id" required={true} className="form-select" options={options} />
                            {disabled && <input type="hidden" name="category_id" value={selectedCategory || data?.category_id_id} />}
                        </div>
                        <div className="col-md-12 mb-2">
                            <Input label='Sub-Category Name' defaultValue={data?.name} name="name" required={true} type="text" className="form-control" placeholder='Enter sub-category name' />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={onHide} className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-primary">{data ? 'Update' : 'Add'} Sub-Category</button>
                </Modal.Footer>
            </form>
        </Modal >
    )
}

export default AddSubCategoryPopup;