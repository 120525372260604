const Button = ({ label, type, icon, onClick, id, className }) => {
    return <button
        onClick={onClick}
        className={className}
        id={id}
        type={type}>
        {icon && <i className={icon}></i>}
        {label}
    </button>
}

export default Button;