import SideMenu from "../../Components/Common/SideMenu";
import TopBar from "../../Components/Common/TopBar";
import FullScreenLoader from "../../Components/Common/FullScreenLoader";
import { Navigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import UserProfile from "./UserProfile";
import AcceptRejectOrder from "./AcceptRejectOrder";
import { setPendingOrdersList } from "../../redux/actions/orderActions";
import alert from "../../assets/sounds/alert.mp3";
import { useRef } from "react";

const BaseLayout = () => {

    const token = useSelector((state) => state?.user?.token);
    const shop = useSelector((state) => state?.shop);

    const audioPlayer = useRef(null);

    const dispatch = useDispatch();

    const orderSocket = new WebSocket(
        'wss://'
        + 'api.shoppershunt.com'
        + '/ws/fetch_new_orders/'
        + shop?.slug
    );

    orderSocket.onmessage = function (response) {
        console.log("Order socket response received - ", response);
        dispatch(setPendingOrdersList(JSON.parse(JSON.parse(response?.data)?.message?.replace(/'/g, '"'))))
        audioPlayer.current.play();
    }

    orderSocket.onclose = function (error) {
        console.log('Order socket closed unexpectedly!');
        console.log(error)
    };


    const dashboardSocket = new WebSocket(
        'wss://'
        + 'api.shoppershunt.com'
        + '/ws/update_seller_dashboard/'
        + shop?.uuid
        + '_update_dashboard'
    );

    dashboardSocket.onmessage = function (response) {
        console.log("Dashboard socket response received - ", response);
        // dispatch(setPendingOrdersList(JSON.parse(JSON.parse(response?.data)?.message?.replace(/'/g, '"'))))
        audioPlayer.current.play();
    }

    dashboardSocket.onclose = function (error) {
        console.log('Dashboard socket closed unexpectedly!');
        console.log(error)
    };


    return (
        <>
            {token ? <div className="wrapper">
                <SideMenu />
                <FullScreenLoader />
                <UserProfile />
                <AcceptRejectOrder />
                <audio ref={audioPlayer} src={alert} />
                <div className="content-page">
                    <div className="content">
                        <TopBar />
                        <div className="container-fluid">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div> : <Navigate to="/auth/login" />}
        </>
    );
}

export default BaseLayout;