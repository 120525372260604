import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-hot-toast';
import { acceptOrder, fetchOrder, rejectOrder } from "../../api/ordersApi";
import { setOrder, setPendingOrdersList } from "../../redux/actions/orderActions";
import { setLoaderView, setShowOrderDetailsPopup, setShowOrderDetailsPopupButtons } from "../../redux/actions/settingActions";
import CurrencyView from "./CurrencyView";

const PendingOrders = () => {

    const pendingOrdersList = useSelector((state) => state?.pendingOrdersList);
    const shop = useSelector((state) => state?.shop);

    const dispatch = useDispatch();

    useEffect(() => {
        if (pendingOrdersList?.length) {
            document.getElementsByClassName('content-page')[0].style.padding = '156px 0px 0px';
            // if (document.body.classList.contains('sidebar-enable')) {
            //     document.getElementsByClassName('leftside-menu')[0].style.paddingTop = '156px';
            // }
        } else {
            document.getElementsByClassName('content-page')[0].style.padding = '70px 0px 0px';
        }
    }, [pendingOrdersList]);

    const showPendingOrderDetails = (pendingOrderId) => {
        fetchOrder(pendingOrderId).then(response => {
            if (response.status === 200) {
                dispatch(setShowOrderDetailsPopupButtons((response?.data?.order_accept_status === true || response.data.order_reject_status === true) ? false : true));
                dispatch(setShowOrderDetailsPopup(true));
                dispatch(setOrder(response.data));
            }
        }).catch((error) => {
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    const acceptRejectOrder = (orderStatus, orderId) => {
        dispatch(setLoaderView(true));
        if (orderStatus) {
            acceptOrder(orderId, shop?.uuid).then((response) => {
                if (response.status === 200) {
                    dispatch(setShowOrderDetailsPopup(false));
                    dispatch(setShowOrderDetailsPopupButtons(false));
                    toast.success(response.message);
                    dispatch(setPendingOrdersList(JSON.parse(response.data)))
                    dispatch(setOrder(null));
                }
                dispatch(setLoaderView(false));
            }).catch((error) => {
                dispatch(setLoaderView(false));
                toast.error(error.message);
            });
        } else {
            rejectOrder(orderId, shop?.uuid).then((response) => {
                if (response.status === 200) {
                    dispatch(setShowOrderDetailsPopup(false));
                    dispatch(setShowOrderDetailsPopupButtons(false));
                    toast.success(response.message);
                    dispatch(setPendingOrdersList(JSON.parse(response.data)))
                    dispatch(setOrder(null));
                }
                dispatch(setLoaderView(false));
            }).catch((error) => {
                dispatch(setLoaderView(false));
                toast.error(error.message);
            });
        }
    }

    return (
        <>
            {pendingOrdersList?.length > 0 ? <div className="navbar-custom p-0 d-flex pending-order-container bg-white">
                <div className="px-2 d-flex w-100 overflow-auto">
                    {pendingOrdersList?.map((pendingOrder) => {
                        return <div key={pendingOrder?.uuid} className="rounded pending-order-card cursor-pointer border border-primary animate__animated animate__flash animate__infinite infinite">
                            <div className="card-body p-1">
                                <div className="d-flex align-items-center">

                                    <div className="flex-shrink-0 me-2" onClick={() => showPendingOrderDetails(pendingOrder?.uuid)} >
                                        <div className="avatar-sm">
                                            <span className="avatar-title bg-primary-lighten text-primary rounded d-flex">
                                                <i className="mdi mdi-cart font-24"></i>
                                                <span className="float-end text-end pending-order-count"><span className="badge bg-primary">{pendingOrder?.total_product}</span></span>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="flex-grow-1" onClick={() => showPendingOrderDetails(pendingOrder?.uuid)} >
                                        <h5 className="m-0 text-primary">{pendingOrder?.order_id}</h5>
                                        <h4 className="m-0 text-primary"><CurrencyView value={pendingOrder?.amount} /></h4>
                                    </div>

                                    {!shop?.auto_accept_orders && <div className="col-auto pending-order-action-container">
                                        <span title="Accept" onClick={() => acceptRejectOrder(true, pendingOrder?.uuid)} className="btn text-success p-0 px-1 bg-success-lighten mb-1 font-12">
                                            <i className='mdi mdi-check'></i>
                                        </span>
                                        <span title="Reject" onClick={() => acceptRejectOrder(false, pendingOrder?.uuid)} className="btn text-danger p-0 px-1 bg-danger-lighten font-12">
                                            <i className='mdi mdi-close'></i>
                                        </span>
                                    </div>}

                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div> : null}
        </>
    )
}

export default PendingOrders;