import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import SunEditor from "suneditor-react";
import Label from "../Common/Label";
import Select from "../Common/Select";
import parse from "html-react-parser";

const AddUpdatePolicyPopup = ({ show, data, onHide, submitForm, policyTypes }) => {

    const [selectedPolicyType, setSelectedPolicyType] = useState(0);
    const [selectedPolicyTypeName, setSelectedPolicyTypeName] = useState("");
    const [description, setDescription] = useState("");

    useEffect(() => {
        if (data) {
            setSelectedPolicyType(data?.policy_type_id);
            setSelectedPolicyTypeName(policyTypes?.find((obj) => {
                return obj?.id === data?.policy_type_id
            })?.name || "");
            setDescription(data?.text);
        }
    }, [policyTypes, data]);

    useEffect(() => {
        if (!show) {
            setDescription("");
        } else {
            if (!data) {
                setSelectedPolicyType(0);
            }
        }
    }, [show]);

    useEffect(() => {
        if (selectedPolicyType === 0) {
            setDescription("");
        }
    }, [selectedPolicyType])

    return (
        <Modal show={show} backdrop="static" size="xl" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
            <form onSubmit={submitForm}>
                <Modal.Header>
                    <Modal.Title className="m-0" id="contained-modal-title-vcenter">{data ? 'Update' : 'Add'} Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <Select
                                label="Policy Type"
                                name="policy_type"
                                required={true}
                                className="form-select"
                                value={selectedPolicyType}
                                onChange={(event) => {
                                    setSelectedPolicyTypeName(policyTypes?.find((obj) => {
                                        return obj?.id === Number(event.target.value)
                                    })?.name);
                                    setSelectedPolicyType(policyTypes?.find((obj) => {
                                        return obj?.id === Number(event.target.value)
                                    })?.id);
                                }}
                                options={policyTypes} />
                        </div>
                    </div>

                    {selectedPolicyType > 0 && <div className="row">
                        <div className="col-md-6 mb-3">
                            <Label label={selectedPolicyTypeName} />
                            <SunEditor height="200" name="text" setContents={description} onChange={setDescription} />
                        </div>

                        <div className="col-md-6 mb-3">
                            <Label label={selectedPolicyTypeName} />
                            <div className="form-control">
                                {parse(description || "")}
                            </div>
                        </div>
                    </div>}

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={onHide} className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-primary">{data ? 'Update' : 'Add'} Policy</button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default AddUpdatePolicyPopup;