export const discountProductPrice = (product) => {
    return product.variant_discounted_price && product.variant_discounted_percentage ?
        <div>
            <strong>
                <span className="text-success">₹</span>
                <span className="text-success mr-2">{product.variant_discounted_price}</span>
                <small><del className="text-danger">₹</del></small>
                <small><del className="text-danger">{product.variant_price}</del></small>
                <small><span className="badge badge-secondary-lighten ml-2">{product.variant_discounted_percentage}% OFF</span></small>
            </strong>
        </div>
        :
        <div>
            <strong>
                <span className="text-success">₹</span>
                <span className="text-success">{product.variant_price}</span>
            </strong>
        </div>
}