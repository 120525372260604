import { Modal } from "react-bootstrap";
import Input from "../../Common/Input";
import Select from "../../Common/Select";
import { useState } from "react";
import RequiredInput from "../../Common/RequiredInput";
import PhoneInput from "react-phone-input-2";
import { GenderConfig } from "../../../Configuration/GenderConfig";
import { useEffect } from "react";

const AddUpdateUserPopup = ({ show, data, onHide, submitForm, roles }) => {

    const [phone, setPhone] = useState(data?.phone);
    const [countryCode, setCountryCode] = useState(data?.country_code);
    const [phoneCountryCode, setPhoneCountryCode] = useState(`${data?.country_code}${data?.phone}`);
    const [gender] = useState(GenderConfig);

    useEffect(() => {
        if (show) {
            setPhone(data?.phone);
            setCountryCode(data?.country_code);
            setPhoneCountryCode(`${data?.country_code}${data?.phone}`);
        }
    }, [show, data]);

    return (
        <Modal show={show} backdrop="static" size="lg" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
            <form onSubmit={submitForm}>
                <Modal.Header>
                    <Modal.Title className="m-0" id="contained-modal-title-vcenter">{data ? 'Update' : 'Add'} User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <Input label="Name" defaultValue={data?.full_name} name="full_name" required={true} type="text" className="form-control" placeholder="Enter user full name" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5 mb-3">
                            <label>Phone <RequiredInput /></label>
                            <PhoneInput disabled={data ? true : false} onChange={(phoneNumber, countryCodeObj) => {
                                setPhone(phoneNumber?.substring(countryCodeObj?.dialCode?.length));
                                setCountryCode(`+${countryCodeObj?.dialCode}`);
                                setPhoneCountryCode(`+${phoneNumber}`);
                            }} country="in" onlyCountries={['in']} required={true} value={phoneCountryCode} className="phone-input" />
                            <input type="hidden" name="phone" key={phone} defaultValue={phone} value={phone} />
                            <input type="hidden" name="country_code" key={countryCode} defaultValue={countryCode} value={countryCode} />
                        </div>
                        <div className="col-md-7 mb-3">
                            <Input label="Email" defaultValue={data?.email} name="email" required={false} type="email" className="form-control" placeholder="Enter email id" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mb-3">
                            <Input label="Date of Birth" defaultValue={data?.dob} name="dob" required={false} type="date" className="form-control" placeholder="Enter date of birth" />
                        </div>
                        <div className="col-md-4 mb-3">
                            <Select options={gender} selectLabel="label" selectId="value" label="Gender" defaultValue={data?.gender} name="gender" required={false} className="form-select" />
                        </div>
                        <div className="col-md-4 mb-3">
                            <Select label="Role" required={true} defaultValue={data?.role_id} name="role_id" options={roles} className="form-select" />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={onHide} className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-primary">{data ? 'Update' : 'Add'} User</button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default AddUpdateUserPopup;