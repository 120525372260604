const Checkbox = ({ label, id, name, className, onChange, labelClassName, checked }) => {

    return (
        <div className="form-check">
            <input type="checkbox" onChange={onChange} checked={checked} className={"form-check-input " + className} id={id} />
            <input type="hidden" value={checked} name={name} />
            <p className={'mb-0 ' + labelClassName} htmlFor={id}>{label}</p>
        </div>
    )
}

export default Checkbox;