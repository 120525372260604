import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const GridView = ({ data, totalSize, disablePagination = false, columns, className, fetchData, sizePerPage = 10, hideSizePerPage = false, keyField, bordered = false, striped = false, hover = true, condensed = true }) => {

    const sizePerPageList = [{
        text: '10', value: 10
    }, {
        text: '25', value: 25
    }, {
        text: '50', value: 50
    }, {
        text: '100', value: 100
    }, {
        text: `All ${totalSize}`, value: totalSize
    }];

    const options = {
        sizePerPage: sizePerPage,
        hideSizePerPage: hideSizePerPage,
        hidePageListOnlyOnePage: true,
        sizePerPageList: sizePerPageList,
        showTotal: false,

        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',

        onSizePerPageChange: (sizePerPage, page) => {
            fetchData(sizePerPage, page);
        },
        onPageChange: (page, sizePerPage) => {
            fetchData(sizePerPage, page);
        }
    };

    return <BootstrapTable
        data={data}
        columns={columns}
        classes={className}
        striped={striped}
        hover={hover}
        condensed={condensed}
        bordered={bordered}
        keyField={keyField}
        pagination={disablePagination ? null : paginationFactory(options)} />
}

export default GridView;