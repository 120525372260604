import apiService from "./api";

//Dashboard
export const fetchDashboardData = async (shopId) => {
    const response = await apiService.get(`user_analytics/get_dashboard_data?shop_id=${shopId}`);
    return response.data;
}

export const fetchGstFilingDates = async () => {
    const response = await apiService.get(`tools/gst_return_dates`);
    return response.data;
}