import SocialMediaInput from "../../Common/SocialMediaInput";
import { useSelector } from "react-redux";
import Button from "../../Common/Button";

const SocialMedia = ({ submitShopDetails }) => {

    const shop = useSelector((state) => state?.shop);

    return (
        <>
            <h4 className="header-title mb-3">Social Media</h4>
            <div className="row">
                <div className="col-md-12 ">
                    <form onSubmit={submitShopDetails}>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <SocialMediaInput name="instagram_url" key={shop?.instagram_url} defaultValue={shop?.instagram_url} label="Instagram" className="form-control" placeholder="Enter instagram url" icon="mdi mdi-instagram" />
                            </div>
                            <div className="col-md-6 mb-3">
                                <SocialMediaInput name="facebook_url" key={shop?.facebook_url} defaultValue={shop?.facebook_url} label="Facebook" className="form-control" placeholder="Enter facebook url" icon="mdi mdi-facebook" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <SocialMediaInput name="twitter_url" key={shop?.twitter_url} defaultValue={shop?.twitter_url} label="Twitter" className="form-control" placeholder="Enter twitter url" icon="mdi mdi-twitter" />
                            </div>
                            <div className="col-md-6 mb-3">
                                <SocialMediaInput name="linkedin_url" key={shop?.linkedin_url} defaultValue={shop?.linkedin_url} label="Linkedin" className="form-control" placeholder="Enter linkedin url" icon="mdi mdi-linkedin" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <SocialMediaInput name="youtube_url" key={shop?.youtube_url} defaultValue={shop?.youtube_url} label="Youtube" className="form-control" placeholder="Enter youtube url" icon="mdi mdi-youtube" />
                            </div>
                            <div className="col-md-6 mb-3">
                                <SocialMediaInput name="pinterest_url" key={shop?.pinterest_url} defaultValue={shop?.pinterest_url} label="Pinterest" className="form-control" placeholder="Enter pinterest url" icon="mdi mdi-pinterest" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 text-end">
                                <Button type="submit" className="btn btn-primary" label="Save Details" />
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}

export default SocialMedia;