export const SideMenuConfig = [
    {
        path: '/',
        label: 'Home',
        id: 'home',
        icon: 'uil-store'
    },
    {
        path: 'orders',
        id: 'orders',
        label: 'Orders',
        icon: 'uil-shopping-cart-alt',
        // subCategory: [
        //     {
        //         path: 'all-orders',
        //         label: 'All Orders',
        //         icon: 'uil-store'
        //     },
        //     {
        //         path: 'abandoned-carts',
        //         label: 'Abandoned Carts',
        //         icon: 'uil-store'
        //     }
        // ]
    },

    {
        path: 'customers',
        label: 'Customers',
        id: 'customers',
        icon: 'uil-user'
    },
    {
        path: 'products',
        label: 'Products',
        id: 'products',
        icon: 'uil-box'
    },

    {
        path: 'categories',
        label: 'Categories',
        id: 'categories',
        icon: 'uil-sitemap'
    },

    {
        path: 'tools',
        label: 'Tools',
        id: 'tools',
        icon: 'uil-wrench'
    },

    // {
    //     path: 'delivery',
    //     label: 'Delivery',
    //     icon: 'uil-truck'
    // },

    {
        path: 'appearance',
        label: 'Appearance',
        id: 'appearance',
        icon: 'uil-palette',
        subCategory: [
            {
                path: 'banner',
                label: 'Banner',
                id: 'banner',
                icon: 'uil-store'
            }
        ]
    },

    {
        path: 'settings',
        label: 'Settings',
        id: 'settings',
        icon: 'uil-cog',
        subCategory: [
            {
                path: 'shop-details',
                label: 'Shop Details',
                id: 'shop_details',
                icon: 'uil-store'
            },
            {
                path: 'user-management',
                label: 'User Management',
                id: 'user_management',
                icon: 'uil-store'
            },
            {
                path: 'qr-builder',
                label: 'QR Builder',
                id: 'qr_builder',
                icon: 'uil-store'
            }
        ]
    }
]