import RequiredInput from "./RequiredInput";

const ToggleSwitch = ({ label, className, checked, readOnly, disabled, name, defaultChecked, id, required, onChange }) => {

    return (
        <div className={`form-check form-switch ${className}`}>
            <input type="hidden" value={false} name={name} />
            <input type="checkbox" value={true} className="form-check-input cursor-pointer" name={name} checked={checked} defaultChecked={defaultChecked} onChange={onChange} disabled={disabled} readOnly={readOnly} id={id} />
            <label className="form-check-label cursor-pointer" htmlFor={id}>{label}{required ? <RequiredInput /> : null}</label>
        </div>
    );
}

export default ToggleSwitch;