import { Modal } from "react-bootstrap";
import Input from "../Common/Input";

const AddCategoryPopup = ({ show, onHide, data, submitForm }) => {

    return (
        <Modal show={show} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
            <form onSubmit={submitForm}>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">{data ? 'Update' : 'Add'} Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <Input label='Category Name' defaultValue={data?.name} name="name" required={true} type="text" className="form-control" placeholder='Enter category name' />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={onHide} className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-primary">{data ? 'Update' : 'Add'} Category</button>
                </Modal.Footer>
            </form>
        </Modal >
    )
}

export default AddCategoryPopup;