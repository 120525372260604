export const ToolsConfig = [
    {
        path: 'logo-maker',
        title: 'Logo Maker',
        description: 'Home',
        icon: 'uil-store'
    },
    {
        path: 'qr-builder',
        title: 'QR Builder',
        description: 'Orders',
        icon: 'uil-shopping-cart-alt',
    },
    {
        path: 'banner-maker',
        title: 'Banner Maker',
        description: 'Products',
        icon: 'uil-archive'
    },
    {
        path: 'gst-tracker',
        title: 'GST Tracker',
        description: 'Tools',
        icon: 'uil-cog'
    },
    {
        path: 'hsn-code-finder',
        title: 'HSN Code Finder',
        description: 'Tools',
        icon: 'uil-cog'
    },
    {
        path: 'background-remover',
        title: 'Background Remover',
        description: 'Tools',
        icon: 'uil-cog'
    },
    {
        path: 'invoice-gererator',
        title: 'Invoice Gererator',
        description: 'Tools',
        icon: 'uil-cog'
    },
    {
        path: 'description-generator',
        title: 'Description Gererator',
        description: 'Tools',
        icon: 'uil-cog'
    }
]